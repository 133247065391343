import cn from "classnames";
import styles from "./styles.module.scss";

interface ILoadingTextProps {
    text: string;
    containerStyles?: string;
}

const LoadingText: React.FC<ILoadingTextProps> = ({ text, containerStyles }) => {
  const arrayOfLetters = text.split("").map((letter, index) => (
    <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
      {letter === " " ? "\u00A0" : letter}
    </span>
  ));

  return (
    <span className={cn(styles.loading, containerStyles)}>
      {arrayOfLetters}
    </span>
  );
};

export default LoadingText;
