import { PropTypes } from "prop-types";
import close from "../../../assets/icons/svg/cross-rounded.svg";
import questionMark from "../../../assets/icons/svg/question-mark.svg";
import styles from "./styles.module.scss";

const ContentMarkDownInfo = (props) => {
  return (
    <>
      <div className={styles.modal__container} ref={props.modalRef}>
        <div className={styles["modal__container_logo-wrapper"]}>
          <img src={questionMark} className={styles["modal__container_logo-wrapper-svg"]} />
          <button className={styles["modal__container_logo-wrapper_btn"]} onClick={() => props.onClose()}>
            <img src={close} className={styles["modal__container_logo-wrapper_btn-close"]} />
          </button>
        </div>
        <div className={styles["modal__container_text-wrapper"]}>
          <p>
            Уценка – тестер (демонстрационный образец), обычно, в упаковке с упрощённым оформлением, который может поставляться без крышки или в так называемой «технической упаковке». Продукт ничем не отличается по качеству, составу, стойкости и другим параметрам, является оригинальным и полностью соответствует аналогу в полноценном оформлении, либо товар с дефектами упаковки (помятые уголки коробки, отсутствие слюды и прочие дефекты коробки/упаковки) или отсутствием таковой.
          </p>
        </div>
      </div>
    </>
  );
};

const propTypes =
{
  onClose: PropTypes.func.isRequired,
  modalRef: PropTypes.object,
};

ContentMarkDownInfo.propTypes = propTypes;

export default ContentMarkDownInfo;