export const FILTERS_VALUES = {
  "Раздел": [],
  "Наши рекомендации": [],
  "Пол": [],
  "Бренд": [],
  "Тип продукта": [],
  "Парфюмер": [],
  "Группа ароматов": [],
  "Премьера аромата": [],
  "Проблема": [],
  "Тип Кожи": [],
  "Результат": [],
  "Классификация": [],
  "Назначение": [],
  "Тип Волос": [],
  "Степень защиты": [],
  "Категория": [],
  "Вид товара": [],
  "Cпособ нанесения": [],
};

export const FilterTypesEnum = {
  badges: "Наши рекомендации",
  brands: "Бренд",
  "product_types": "Тип продукта",
  categories: "Раздел",
  "goods_types": "Вид товара",
  "application_methods": "Cпособ нанесения"
};

export const FilterOrder = {
  "categories": -1,
  "badges": 0,
  "pol": 1,
  "brands": 2,
  "product_types": 3,
  "parfumer": 4,
  "gruppa-aromatov": 5,
  "prem-era-aromata": 6,
  "problema": 7,
  "tip-kozi": 8,
  "rezul-tat": 9,
  "klassifikacia": 10,
  "naznacenie": 11,
  "tip-volos": 12,
  "stepen-zasity": 13,
  "kategoria": 14,
};

export const AdditionalSearchOptionsEnum = {
  limit: "limit",
  offset: "offset",
  "order_by": "order_by",
  "sort_by": "sort_by",
  "min_price": "min_price",
  "max_price": "max_price",
};

export const parfumeriaFilterOrder = [
  "badges",
  // "categories",
  "kategoria",
  "brands",
  "pol",
  "goods_types",
  "product_types",
  "kollekcia",
  "seria",
  "parfumer",
  "gruppa-aromatov",
  "verhnaa-nota",
  "bazovaa-nota",
  "nota-serdca",
];

export const kosmetikaFilterOrder = [
  "badges",
  // "categories",
  "kategoria",
  "brands",
  "pol",
  "goods_types",
  "product_types",
  "klassifikacia",
  "problema",
  "rezul-tat",
  "naznacenie",
  "tip-kozi",
  "tip-volos",
  "stepen-zasity",
];

export const defaultFilterOrder = [
  "badges",
  "categories",
  "kategoria",
  "brands",
  "pol",
  "goods_types",
  "product_types",
  "kollekcia",
  "seria",
  "klassifikacia",
  "problema",
  "rezul-tat",
  "naznacenie",
];