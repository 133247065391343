import OrderItemsData from "../OrderItemsData/OrderItemsData";
import OrderItem from "../OrderItem/OrderItem";
import OrderItemsSlider from "../OrderItemsSlider/OrderItemsSlider";

import styles from "./styles.module.scss";
import { OrderOffer } from "types/IGetUserOrderData";
import { useAppSelector } from "store/reduxHooks";

interface IOrderItemsProps {
  offers: OrderOffer[];
  deliveryPrice?: number;
  paymentStatus?: string;
  paymentMethod?: string;
  orderStatusId?: number;
}

const OrderItems: React.FC<IOrderItemsProps> = (props) => {
  const { deviceType } = useAppSelector((state) => state.user);

  return (
    <div className={styles.root}>
      {deviceType.isMobile ? (
        !!props.offers?.length && <OrderItemsSlider offers={props.offers} />
      ) : (
        <div className={styles.items}>
          {props.offers?.map((item, idx) => {
            return (
              <OrderItem
                key={`${item.id}-${idx}`}
                item={item}
                amount={item.count}
              />
            );
          })}
        </div>
      )}
      <OrderItemsData {...props} />
    </div>
  );
};

export default OrderItems;
