import { memo, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";

import { IFilterCommon, IFilterUncommon } from "types/IFiltersV3";
import chechMarkIcon from "../../../../../../../assets/icons/png/chech_mark-icon.png";

import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import {
  IFilterItemToSelect,
  IFilterItemToSelectMobile,
  ISelectedFilterItem,
  setSelectedFilterItem,
  setSingleFilterSelect,
  setSingleMobileSearchParam,
  toggleSearchTrigger
} from "store/catalogV3";

import styles from "./styles.module.scss";

interface FilterButtonItemV3Props {
  item: IFilterCommon | IFilterUncommon;
  isDisabled?: boolean;
  mainFilterRewriteName: string;
  mainFilterLocalizedName: string;
  isFilterProductProperties?: boolean;
  isMobileDevice?: boolean;
}

const FilterButtonItemV3: React.FC<FilterButtonItemV3Props> = memo(
  ({
    item,
    isDisabled = false,
    mainFilterRewriteName,
    mainFilterLocalizedName,
    isFilterProductProperties,
    isMobileDevice
  }) => {
    const { mobileSearchParams } = useAppSelector((state) => state.catalogV3);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    // Для свойств товара фильтр должен иметь вид properties[название фильтра]= название под фильтра_1, название подфильтра_2
    const mainFilterKey = isFilterProductProperties
      ? `properties[${mainFilterRewriteName}]`
      : mainFilterRewriteName;

    const isSelected: boolean = useMemo(() => {
      let shouldBeSelected = false;
      if (!isMobileDevice) {
        const paramValue = searchParams.get(mainFilterKey);
        shouldBeSelected = false;
        if (!paramValue) return shouldBeSelected;

        const values = paramValue.split(",");
        const itemName =
          "rewrite_name" in item ? item.rewrite_name : item.rewrite_value;

        shouldBeSelected = values.includes(itemName);
        return shouldBeSelected;
      }

      const mobileParamValue = mobileSearchParams[mainFilterKey];

      if (!mobileParamValue) {
        shouldBeSelected = false;
        return shouldBeSelected;
      }

      const values = mobileParamValue.split(",");
      const itemName =
        "rewrite_name" in item ? item.rewrite_name : item.rewrite_value;

      shouldBeSelected = values.includes(itemName);
      return shouldBeSelected;
    }, [isMobileDevice, mobileSearchParams, mainFilterKey, item, searchParams]);

    const handleFilterClick = (item: IFilterCommon | IFilterUncommon) => {
      const searchParamsObject: Record<string, string> = {};
      searchParams.forEach((value, key) => {
        searchParamsObject[key] = value;
      });

      // если фильтр из product_properties данные поступаемые в редакс должны быть формата properties[ключ] - mainFilterRewriteName
      const filterItemToSelect: IFilterItemToSelect = {
        filter: item,
        mainFilterRewriteName: mainFilterKey,
        searchParamsObject
      };

      // если фильтр из product_properties данные поступаемые в редакс должны быть формата properties[ключ] - mainFilterRewriteName
      const selectedFilterItem: ISelectedFilterItem = {
        filter: item,
        mainFilterRewriteName: mainFilterKey,
        mainFilterLocalizedName,
        isFilterProductProperties
      };

      if (!isMobileDevice) {
        dispatch(setSingleFilterSelect(filterItemToSelect));
        // Перезаписываем URL из тех данных что обработались в dispatch setSingleFilterSelect
        // Для уточнения - мы мутируем объект searchParamsObj внутки setSingleFilterSelect
        setSearchParams(new URLSearchParams(searchParamsObject));
      } else {
        const singleMobileFitlerItem: IFilterItemToSelectMobile = {
          mainFilterRewriteName: filterItemToSelect.mainFilterRewriteName,
          filter: filterItemToSelect.filter
        };
        dispatch(setSingleMobileSearchParam(singleMobileFitlerItem));
        dispatch(toggleSearchTrigger());
      }

      dispatch(setSelectedFilterItem(selectedFilterItem));
    };

    return (
      <li className={styles.root}>
        <button
          className={cn(styles.button, { [styles.disabled]: isDisabled })}
          onClick={() => handleFilterClick(item)}
          disabled={isDisabled}
        >
          <div className={styles.checkbox}>
            {isSelected && <img src={chechMarkIcon} alt="chech_mark-icon" />}
          </div>
          <span className={styles.title}>
            {"name" in item ? item.name : item.value}
          </span>
        </button>
      </li>
    );
  }
);

FilterButtonItemV3.displayName = "FilterButtonItemV3";

export default FilterButtonItemV3;
