import { useEffect } from "react";
import PropTypes from "prop-types";
import "keen-slider/keen-slider.min.css";

import useSlider from "../../utils/hooks/useSlider";
import useIsFirstRender from "../../utils/hooks/useIsFirstRender";

import CustomArrowButton from "../CustomArrowButton/CustomArrowButton";

import cn from "classnames";
import styles from "./styles.module.scss";
import SliderTab from "./components/SliderTab/SliderTab";



const MainSlider = (props) => {
  const {
    currentSlide,
    isLoaded,
    availableDots,
    sliderRef,
    instanceRef,
    onClickPrev,
    onClickNext,
  } = useSlider({
    initialSlide: props.initialSlide,
    shouldBeLooped: props.shouldBeLooped,
    slidesPerView: props.slidesPerView,
    slidesSpacing: props.slidesSpacing,
    shouldAutoSwitched: props.shouldAutoSwitched,
  });

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    instanceRef.current?.moveToIdx(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTabId]);

  useEffect(() => {
    if (!props.shouldControlAutoSwitched || isFirstRender) {
      return;
    }

    if (props.shouldAutoSwitched) {
      instanceRef.current?.emit("resumed");
      return;
    }
    instanceRef.current?.emit("stopped");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldControlAutoSwitched, props.shouldAutoSwitched]);

  const handleResumed = () => {
    instanceRef.current?.emit("resumed");
  };
  const handleStopped = () => {
    instanceRef.current?.emit("stopped");
  };
  return (
    <>
      {props.activeTabId &&
    <SliderTab onClickSelectTab={props.onClickSelectTab}
      activeTabId={props.activeTabId}
      handleResumed={handleResumed}
      handleStopped={handleStopped} />
      }
      <div className={cn(styles.root, props.sliderContainerStyles)}>
        <div ref={sliderRef} className={cn("keen-slider", props.contentStyles)}>
          {props.children}
        </div>
        {!props.hidePagination && isLoaded && instanceRef.current && (
          <div className={cn(styles.pagination, props.paginationStyles)}>
            {!props.hideArrowButtons && (
              <CustomArrowButton duration="left" onClick={onClickPrev} />
            )}
            {!props.hideDots && (

              <div className={cn(styles.dots, props.dotsStyles)}>
                {availableDots.map((idx) => {

                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                        instanceRef.current?.emit("stopped");
                      }}
                      className={cn(styles.dot, {
                        [styles["dot--active"]]: currentSlide === idx,
                      })}
                      onMouseUp={() => instanceRef.current?.emit("resumed")}
                      onMouseLeave={() => instanceRef.current?.emit("resumed")}
                    ></button>
                  );
                })}
              </div>
            )}
            {!props.hideArrowButtons && (
              <CustomArrowButton duration="right" onClick={onClickNext} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

const propTypes = {
  onClickSelectTab: PropTypes.func,
  activeTabId: PropTypes.string,
  children: PropTypes.node.isRequired,
  sliderContainerStyles: PropTypes.string,
  contentStyles: PropTypes.string,
  paginationStyles: PropTypes.string,
  dotsStyles: PropTypes.string,
  initialSlide: PropTypes.number,
  shouldBeLooped: PropTypes.bool,
  shouldAutoSwitched: PropTypes.bool,
  hidePagination: PropTypes.bool,
  hideDots: PropTypes.bool,
  hideArrowButtons: PropTypes.bool,
  slidesPerView: PropTypes.number,
  slidesSpacing: PropTypes.number,
  shouldControlAutoSwitched: PropTypes.bool,
};

MainSlider.propTypes = propTypes;

export default MainSlider;
