import { useEffect, useMemo, useRef, useState, useTransition } from "react";
import { PROVIDERS } from "../../../../../../../utils/constants";
import CustomScrollbar from "../../../../../../../components/CustomScrollbar/CustomScrollbar";
import cn from "classnames";
import styles from "./styles.module.scss";
import {
  SelectedProvider,
  SelectedProviderData,
  ToDoorDetail,
  ToPointDetail
} from "types/IProviderInfo";
import { isToPointDetail } from "utils/helpers/isToPointDetail";

interface IProvidersFullListProps {
  currentAddresses: ToDoorDetail[] | ToPointDetail[];
  searchValue: string;
  onToggleShowProviderInfo: (value: boolean) => void;
  changeSelectedProviderData: (value: SelectedProviderData | null) => void;
  selectedProvider: SelectedProvider;
}

const ProvidersFullList: React.FC<IProvidersFullListProps> = (props) => {
  const [isShowScrollbar, setIsShowScrollbar] = useState(false);
  const [adress, setAdress] = useState<SelectedProviderData[][]>([]);
  const [isPanding, startTransition] = useTransition(); // eslint-disable-line
  const [showShadow, setShowShadow] = useState({
    top: false,
    bottom: false
  });

  const scrollbarRef = useRef(null);

  useEffect(() => {
    if (!scrollbarRef.current) {
      return;
    }
    const scrollHeight = scrollbarRef.current.children[0].scrollHeight;
    const offsetHeight = scrollbarRef.current.offsetHeight;
    setIsShowScrollbar(scrollHeight > offsetHeight);
    setShowShadow((prev) => {
      return {
        ...prev,
        bottom: scrollHeight > offsetHeight
      };
    });
  }, [props.searchValue]);

  const onSelectAddress = (value: SelectedProviderData) => {
    props.onToggleShowProviderInfo(true);
    props.changeSelectedProviderData({
      ...value,
      deliveryProvider: PROVIDERS[value.deliveryProvider].title,
      deliveryProviderType: PROVIDERS[value.deliveryProvider].type
    });
  };

  const onShowScrollBarShadows = ({
    showTopShadow,
    showBottomShadow
  }: {
    showTopShadow: boolean;
    showBottomShadow: boolean;
  }) => {
    setShowShadow({
      top: showTopShadow,
      bottom: showBottomShadow
    });
  };

  useEffect(() => {
    startTransition(() => {
      const filteredAddresses = props.currentAddresses?.map((item) => {
        if (!props.selectedProvider[item?.deliveryProvider]) return null;
        if (isToPointDetail(item) && !item?.addresses?.length) return null;

        return isToPointDetail(item) && item.addresses.map((elem) => {
          return elem;
        });
      });
      setAdress(filteredAddresses);
    });
  }, [props.currentAddresses, props.selectedProvider]);

  const newAddresses = useMemo(() => {
    const findParfumart = adress
      ?.flat()
      .find((e) => e?.deliveryProvider === "parfumart");

    if (findParfumart) {
      const arrayWithoutParfumart = adress
        ?.flat()
        .filter((e) => e?.deliveryProvider !== findParfumart.deliveryProvider);
      return [findParfumart, ...arrayWithoutParfumart];
    }

    return adress?.flat();
  }, [adress]);

  return (
    <div
      className={cn(
        styles.root,
        { [styles["root--top-shadow"]]: showShadow.top },
        { [styles["root--bottom-shadow"]]: showShadow.bottom }
      )}
    >
      <CustomScrollbar
        scrollbarClassname={styles.scrollbar}
        childrenClassName={styles["providers-addresses"]}
        isShowScrollbar={isShowScrollbar}
        rerenderTrigger={props.searchValue}
        scrollbarRef={scrollbarRef}
        onShowScrollBarShadows={onShowScrollBarShadows}
      >
        <div className={styles.list}>
          {newAddresses?.slice(0, 100).map((elem, idx) => {
            if (!elem?.deliveryProvider) return;
            return (
              <button
                key={`${elem.address}-${idx}`}
                className={styles.list__button}
                onClick={() => onSelectAddress(elem)}
              >
                <h1 className={styles["list__button-provider"]}>
                  {elem.deliveryProvider}
                </h1>
                <span className={styles["list__button-title"]}>
                  {elem.address}
                </span>
                <span className={styles["list__button-number"]}>
                  {elem.phone}
                </span>
              </button>
            );
          })}
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default ProvidersFullList;
