import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { removeUser } from "../../../../../../store/user/user";
import { removeTokens } from "../../../../../../utils/localStorage";

import personalAriaIcon from "../../../../../../assets/header_icons/personal_aria-icon.svg";
import ExitFromPersonalAreaContent from "../../../../../../components/ExitFromPersonalAreaContent";
import PersonalAreaNavigationContent from "../../../../../../components/PersonalAreaNavigationContent";
import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";

import cn from "classnames";
import { RedirectToOldContent } from "../../../../../../components/RedirectToOldContent/RedirectToOldContent";
import styles from "./styles.module.scss";

const PersonalAreaTab = (props) => {
  const { user } = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowExitBS, setIsShowExitBS] = useState(false);
  const [isOpenRedirectModal, setIsOpenRedirectModal] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onCloseNavigationBottomsheet = () => {
    if (isShowExitBS) {
      return;
    }

    setIsOpen(false);
  };

  const onClickHandler = () => {
    if (process.env.REACT_APP_REDIRECT_PROFILE_ICON === "true") {
      return setIsOpenRedirectModal(true);
    }
    if (!user?.phone) {
      const pathname = location.pathname;
      if (pathname === "/sign-up") {
        navigate("/sign-in", { replace: true });
        return;
      }
      navigate("/sign-up", { replace: true });
      return;
    }
    setIsOpen(true);
    props.cb?.();
  };

  const onCloseExitBottomsheet = ({ isExit }) => {
    if (isExit) {
      removeTokens();
      dispatch(removeUser());
      navigate("/", { replace: true });
      setIsOpen(false);
      setIsShowExitBS(false);
      return;
    }
    setIsShowExitBS(false);
  };

  const onClickCloseRedirectModal = () => {
    setIsOpenRedirectModal(false);
  };

  return (
    <li className={styles.root}>
      <button onClick={onClickHandler} className={cn(styles.tab__button, props.buttonStyles)}>
        <img
          src={personalAriaIcon}
          alt="personal_aria-icon"
          className={cn(styles["personal-aria-icon"], props.iconStyles)}
        />
        {props.children}
      </button>
      <BottomSheet
        isOpen={isOpen}
        onClose={onCloseNavigationBottomsheet}
        disableClickOutside={isShowExitBS}
      >
        <PersonalAreaNavigationContent
          onClose={onCloseNavigationBottomsheet}
          onContinueExit={() => setIsShowExitBS(true)}
        />
      </BottomSheet>
      <BottomSheet isOpen={isShowExitBS} onClose={() => setIsShowExitBS(false)} hideBackground>
        <ExitFromPersonalAreaContent onClose={onCloseExitBottomsheet} />
      </BottomSheet>
      <BottomSheet isOpen={isOpenRedirectModal} onClose={onClickCloseRedirectModal}>
        <RedirectToOldContent
          url={`${process.env.REACT_APP_REDIRECT_PARFUMART_NEW_URL}/auth/login`}
          onClose={onClickCloseRedirectModal}
          textForRedirect={"регистрации"}
        />
      </BottomSheet>
    </li>
  );
};

const propTypes = {
  buttonStyles: PropTypes.string,
  iconStyles: PropTypes.string,
  cb: PropTypes.func,
  children: PropTypes.node,
};

PersonalAreaTab.propTypes = propTypes;

export default PersonalAreaTab;
