import { useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { getMinProductPrice, getMaxProductPrice } from "../../../../../utils/getProductPrices";

import noImgProductLogo from "../../../../../assets/images/no-photo.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const ProfitProductCard = (props) => {
  const productMinDescription = useMemo(() => {
    const productItemWithVolume = props.offers.find((item) => {
      if (!item.offerVolumes) return "";
      return item.offerVolumes.find((elem) => {
        return elem.value_type;
      });
    });

    if (!productItemWithVolume) return "";

    const productVolume = productItemWithVolume.offerVolumes[0].value_type;

    const description = [...props.offers].sort((a, b) =>
      a.name.length - b.name.length)[0].name.split(`${productVolume} `)[1];

    if (!description) return "";

    return description[0].toUpperCase() + description.slice(1);
  }, [props.offers]);

  const productPrice = useMemo(() => {
    if (props.offers.length === 1) {
      return {
        min: getMinProductPrice(props.offers),
        max: "",
      };
    }

    return {
      min: getMinProductPrice(props.offers),
      max: getMaxProductPrice(props.offers),
    };
  }, [props.offers]);

  return (
    <div className={styles.card}>
      <Link
        to={`/product/${props.productId}-${props.productRewriteName}`}
        className={styles["card_img-wrapper"]}
      >
        <img
          src={props.reducedImage?.link ?? noImgProductLogo}
          alt={props.productRewriteName}
          className={styles.card_img}
        />
      </Link>
      <Link to={`/product/${props.productId}-${props.productRewriteName}`}
        className={styles.card_title}
      >
        {props.name}
      </Link>
      <div className={styles.card_description}>
        {
          !productMinDescription.length
            ? ""
            : productMinDescription
        }
      </div>
      <div className={cn(styles.card__price, {[styles["card__price-min-content"]]: !productPrice.max})}>
        {
          !productPrice.max
            ? <span>{`${productPrice.min} ₽`}</span>
            : <>
              <span>{`От ${productPrice.min} ₽`}</span>
              <span>{`До ${productPrice.max} ₽`}</span>
            </>
        }
      </div>
    </div>
  );
};

const propTypes = {
  productId: PropTypes.number.isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      "catalog_name": PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      akcia: PropTypes.number,
      percent: PropTypes.number,
      available: PropTypes.bool,
      offerVolumes: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          "value_type": PropTypes.string,
          value: PropTypes.number
        })
      ),
      "price_sale": PropTypes.number,
      bestseller: PropTypes.bool,
      "new_label": PropTypes.bool
    })
  ),
  name: PropTypes.string.isRequired,
  productRewriteName: PropTypes.string.isRequired,
  reducedImage: PropTypes.shape({
    size: PropTypes.number,
    mime: PropTypes.string,
    link: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    "new_file_name": PropTypes.string
  }),
};

ProfitProductCard.propTypes = propTypes;

export default ProfitProductCard;