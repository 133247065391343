import PropTypes from "prop-types";

import chechMarkIcon from "../../../assets/icons/png/chech_mark-icon.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const Checkbox = (props) => {
  const handleClick = () => {
    if (!props.isDisabled) {
      props.setIsSelected(!props.isSelected);
    }
  };

  return (
    <div
      className={cn(styles.root, props.containerClassname, { [styles.disabled]: props.isDisabled })}
      onClick={handleClick}
    >
      {
        props.isSelected && !props.isDisabled &&
          <img
            src={chechMarkIcon}
            alt="check_mark-icon"
          />
      }
    </div>
  );
};

const propTypes = {
  containerClassname: PropTypes.string,
  isSelected: PropTypes.bool,
  setIsSelected: PropTypes.func,
  isDisabled: PropTypes.bool,
};

Checkbox.propTypes = propTypes;

Checkbox.defaultProps = {
  isDisabled: false,
};

export default Checkbox;