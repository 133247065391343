import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";

export const SkeletonImg = ({ style }) => {
  return (
    <>
      <div className={styles.avatar} style={style} />
    </>
  );
};

const propTypes = {
  style: PropTypes.object,
};

SkeletonImg.propTypes = propTypes;
