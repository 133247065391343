/* eslint-disable prefer-const */
import { meta } from "pages/Catalog/metaDataCatalog";

function containsVariableTemplate(str: string): boolean {
  const regex = /\{(\w+)\}/g;
  return regex.test(str);
}

export function searchByCanonical(search: string): { title: string; description: string; canonical: string } {

  for (let key in meta) {
    const value = meta[key];
    for (let innerKey in value) {
      const innerValue = value[innerKey];
      const original = innerValue.canonical;

      if (containsVariableTemplate(original)) {
        const originalParts = original.split("=");
        const searchParts = search.split("=");

        if (originalParts[0] === searchParts[0]) {
          return innerValue;
        }
      } else if (original === search) {
        return innerValue;
      }
    }
  }

  // return null;
  return meta["/catalog"].root;
}