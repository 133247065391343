import { useCallback, useMemo, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import cn from "classnames";
import BottomSheet from "components/UI/Bottomsheet/Bottomsheet";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import {
  ISelectedFilter,
  resetMobileSearchFilters,
  setFiltersData,
  setMobileSearchParams,
  setSelectedFilters,
  toggleSearchTrigger
} from "store/catalogV3";
import { IFiltersV3Core } from "types/IFiltersV3";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/svg/filter-icon.svg";
import useShowShadow from "utils/hooks/useShowShadow";

import CustomButton from "components/CustomButton/CustomButton";

import SelectedFiltersV3 from "pages/Catalog/components/CatalogFiltersV3/SelectedFiltersV3/SelectedFiltersV3";
import MainFiltersV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/MainFiltersV3";
import MoreFiltersBSV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/MoreFiltersBSV3/MoreFiltersBSV3";

import styles from "./styles.module.scss";

export interface ICatalogPrevState {
  prevFiltersV3: IFiltersV3Core;
  prevSearchParams: URLSearchParams;
  prevSelectedFilters: ISelectedFilter[];
}

const MobileFiltersButtonV3: React.FC = () => {
  const { filtersV3, selectedFilters, mobileSearchParams } = useAppSelector(
    (state) => state.catalogV3
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isSheetMount, setIsSheetMount] = useState(false);
  const [isMoreSheetMount, setIsMoreSheetMount] = useState(false);
  const [isMoreFiltersBSOpen, setIsMoreFiltersBSOpen] = useState(false);

  const catalogV3PrevState = useRef<ICatalogPrevState>({
    prevSearchParams: new URLSearchParams({}),
    prevSelectedFilters: [],
    prevFiltersV3: {}
  });
  const shouldReturnPrevCatalogState = useRef(false);

  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();

  const currentCatalogSection = useMemo(() => {
    const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);
    if (!currentMatch) {
      return "";
    }
    return currentMatch[0];
  }, [location.pathname]);

  const isCatalogSection = !!currentCatalogSection.length;

  const handleResetAllFilters = () => {
    dispatch(resetMobileSearchFilters({ isSection: isCatalogSection }));
  };

  const handleEnteredBS = () => {
    // пустая функция
  };

  const onExitedHandler = useCallback(() => {
    changeShowShadow({
      top: false,
      bottom: true
    });
    if (shouldReturnPrevCatalogState.current) {
      dispatch(
        setMobileSearchParams(
          Object.fromEntries(catalogV3PrevState.current.prevSearchParams)
        )
      );
      dispatch(
        setSelectedFilters(catalogV3PrevState.current.prevSelectedFilters)
      );
      dispatch(setFiltersData(catalogV3PrevState.current.prevFiltersV3));
    }
    setTimeout(() => setIsSheetMount(false), 300);
  }, [changeShowShadow, dispatch]);

  const handleCloseMainBS = useCallback(() => {
    setIsOpen(false);
    shouldReturnPrevCatalogState.current = true;
  }, []);

  const handleOpenMainBS = useCallback(() => {
    catalogV3PrevState.current = {
      prevSearchParams: new URLSearchParams(mobileSearchParams),
      prevSelectedFilters: selectedFilters,
      prevFiltersV3: filtersV3
    };

    setTimeout(() => setIsOpen(true), 50);
    setIsSheetMount(true);
  }, [filtersV3, mobileSearchParams, selectedFilters]);

  const handleSubmit = useCallback(() => {
    // убираем ключ filters так как он нам в параметрах поиска не нужен. Всё устроено в хуке useFilters
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filters, ...rest } = mobileSearchParams;
    const updatedSearchParams = new URLSearchParams(rest);
    setSearchParams(updatedSearchParams);
    dispatch(toggleSearchTrigger());
    shouldReturnPrevCatalogState.current = false;
    setTimeout(() => setIsOpen(false), 100);
  }, [dispatch, mobileSearchParams, setSearchParams]);

  const handleShowMoreFiltersBS = useCallback(() => {
    setTimeout(() => setIsMoreFiltersBSOpen(true), 100);
    setIsMoreSheetMount(true);
  }, []);

  const handleCloseMoreFiltersBS = useCallback(() => {
    setIsMoreFiltersBSOpen(false);
    setTimeout(() => setIsMoreSheetMount(false), 300);
  }, []);

  return (
    <>
      <button className={styles.root} onClick={handleOpenMainBS}>
        <FilterIcon />
        <span className={styles.title}>
          Фильтры ({`${selectedFilters.length}`})
        </span>
      </button>
      {isSheetMount ? (
        <BottomSheet
          isOpen={isOpen}
          onClose={handleCloseMainBS}
          onEntered={handleEnteredBS}
          onExited={onExitedHandler}
          disableClickOutside={isMoreFiltersBSOpen}
          headerLineStyles={cn(styles["bottomsheet__header-line"], {
            [styles["bottomsheet__header-line-shadow"]]: showShadow.top
          })}
          headerTitle="Фильтры"
          shouldBeOnFullScreen
        >
          <>
            <div className={styles.content} onScroll={onScrollHandler}>
              <SelectedFiltersV3 isMobileDevice={true} />
              <MainFiltersV3
                filters={filtersV3}
                containerStyles={styles.content__filters}
                showMoreFiltersBS={handleShowMoreFiltersBS}
                isMobileDevice={true}
              />
            </div>
            <div
              className={cn(styles["buttons-wrapper"], {
                [styles["buttons-wrapper--bottom-shadow"]]: showShadow.bottom
              })}
            >
              <CustomButton
                title="Применить"
                className={styles["accept-filters"]}
                onClick={handleSubmit}
              />
              <button
                className={styles["reset-button"]}
                onClick={handleResetAllFilters}
              >
                Сбросить все фильтры
              </button>
            </div>
          </>
        </BottomSheet>
      ) : null}
      {isMoreSheetMount && (
        <MoreFiltersBSV3
          isOpen={isMoreFiltersBSOpen}
          onMoreFilterBSClose={handleCloseMoreFiltersBS}
        />
      )}
    </>
  );
};

export default MobileFiltersButtonV3;
