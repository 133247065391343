import { getCanonicalUrlForInfoPage } from "utils/helpers/getCanonicalUrl";
import InfoPage from "../InfoPage";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";

const PresentCertificate = () => {

  const presentCertificateData = pageInfo.giftCertificates;
  const canonicalUrl = getCanonicalUrlForInfoPage(window.location.href);

  return (
    <>
      <h1 className={styles.seo}>Инфо страница Подарочные сертификаты</h1>
      <InfoPage metaData={presentCertificateData} canonicalUrl={canonicalUrl} title='Подарочные сертификаты'>
        <div className={styles.root}>
          <p className={styles.root__text}>
            С помощью нашего подарочного сертификата клиент (держатель подарочного
            сертификата) может оплатить любой товар или несколько товаров в
            пределах лимита подарочного сертификата в интерент-магазине
            <a
              className={styles.root__link}
              href='https://www.parfumart.ru'
              target='_blank'
              rel='noopener noreferrer'
            >
              {" "}
              www.parfumart.ru
            </a>
            . Подарочный сертификат обмену и возврату не подлежит. Денежный
            эквивалент сертификата не выплачивается.
          </p>
          <p>
            <span className={styles.root__text}>
              Приобретением настоящего сертификата и совершением действий по
              активации/использованию подарочных сертификатов приобретатель
              подтверждает, что ознакомлен и согласен с Правилами обращения и
              использования подарочных сертификатов интернет-магазина
            </span>
            <span>
              <a
                className={styles.root__link}
                href='https://www.parfumart.ru'
                target='_blank'
                rel='noopener noreferrer'
              >
                {" "}
                www.parfumart.ru
              </a>
            </span>
          </p>
        </div>
      </InfoPage>
    </>
  );
};

export default PresentCertificate;
