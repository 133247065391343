import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import cn from "classnames";
import styles from "./styles.module.scss";

const PaymentAndDeliveryInnerContent = (props) => {
  return (
    <div className={cn(styles.root, props.containerStyles)}>
      <NavLink onClick={props.closeModal} to="/info/oplata" className={styles["list__item"]}>
        Оплата
      </NavLink>
      <NavLink onClick={props.closeModal} to="/info/dostavka" className={styles["list__item"]}>
        Доставка
      </NavLink>
    </div>
  );
};

PaymentAndDeliveryInnerContent.propTypes = {
  containerStyles: PropTypes.string,
  closeModal: PropTypes.func,
};

export default PaymentAndDeliveryInnerContent;
