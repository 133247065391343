import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollRestoration = (
  isDataLoaded: boolean,
  isNotMobileDevice: boolean,
  disableScrollRestoration: boolean,
  disableScroll: boolean,
): void => {
  const location = useLocation();
  const lastScrollY = useRef<number>(0);
  const MAX_SCROLL_PER_PAGE = 1600;
  const previousPathname = useRef<string>(location.pathname + location.search);

  useEffect(() => {
    if (isDataLoaded && !isNotMobileDevice && !disableScrollRestoration) {
      const handleScroll = (): void => {
        const scrollY = window.scrollY;
        if (scrollY > 0) {
          sessionStorage.setItem("scrollPosition", scrollY.toString());
          lastScrollY.current = scrollY;
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isDataLoaded, isNotMobileDevice, disableScrollRestoration]);

  useEffect(() => {
    if (isDataLoaded && !isNotMobileDevice && !disableScrollRestoration && !disableScroll) {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition) {
        const parsedScrollPosition = parseInt(scrollPosition, 10);
        let newScrollPosition = parsedScrollPosition % MAX_SCROLL_PER_PAGE;
        if (newScrollPosition === 0 && parsedScrollPosition !== 0) {
          newScrollPosition = MAX_SCROLL_PER_PAGE;
        }
        window.scrollTo(0, newScrollPosition);
        lastScrollY.current = newScrollPosition;
      }
    }
  }, [location, isDataLoaded, isNotMobileDevice, disableScrollRestoration, disableScroll]);

  useEffect(() => {
    const currentPathname = location.pathname + location.search;

    if (previousPathname.current !== currentPathname && currentPathname.startsWith("/catalog") && !disableScroll) {
      if (!disableScrollRestoration) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }

    previousPathname.current = currentPathname;
  }, [location, disableScroll, disableScrollRestoration]);
};

export default useScrollRestoration;
