export const catalogPaths = [
  "/catalog",
  "/catalog/kosmetika",
  "/catalog/parfumeria",
];

export const personalAreaPaths = [
  "/personal-area/profile",
  "/personal-area/orders",
  "/personal-area/products",
  "/personal-area/settings",
];
