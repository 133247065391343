import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { HeadHelmet } from "../../utils";
import { productsApi } from "../../api/productsApi";
import { getCatalogPageBreadcrumbs } from "../../utils/breadcrumbsUtils";

import ProductHeaderLine from "./Components/ProductHeaderLine";
import ProductMainContent from "./Components/ProductMainContent";
import ProductAdditionalContent from "./Components/ProductAdditionalContent";
import MobileMainProductContent from "./Components/Mobile/MobileMainProductContent";
// import Breadcrumbs from "../../components/Breadcrumbs";
import ProductDescription from "./Components/ProductMainContent/components/ProductDescription";
import { SkeletonProductDescription } from "../../components/UI/Skeleton/SkeletonProductDescription/SkeletonProductDescription";
import ModalRedirect from "../../components/ModalRedirect/ModalRedirect";

import styles from "./styles.module.scss";
import MobileProductHeaderLine from "./Components/Mobile/MobileMainProductContent/components/MobileProductHeaderLine/MobileProductHeaderLine";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";

const SingleProductPage = () => {
  const { deviceType } = useSelector(state => state.user);
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenRedirectModalCart, setIsOpenRedirectModalCart] = useState(false);
  const modalRedirectCartRef = useRef(null);
  const reviewsRef = useRef(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const id = productId.split("-", 1)[0];
        const productNameFromQuery = productId.split(`${id}-`)[1];

        const data = await productsApi.getProductById(id);

        if (data.meta.error) {
          return location.replace("/");
        }

        if (
          productNameFromQuery !== data.response.product.product_rewrite_name
        ) {
          return location.replace(
            `/product/${data.response.product.id}-${data.response.product.product_rewrite_name}`
          );
        }

        setProductData(data.response);
      } catch (error) {
        // TODO
      }
      setIsLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbsValues = useMemo(() => {
    if (!productData?.product) {
      return [];
    }
    return getCatalogPageBreadcrumbs(productData.product);
  }, [productData]);

  const productMinDescription = useMemo(() => {
    const productItemWithVolume = productData?.offers?.find(item => {
      if (!item.offerVolumes) return "";
      return item.offerVolumes.find(elem => {
        return elem.value_type;
      });
    });

    if (!productItemWithVolume) return "";

    const productVolume = productItemWithVolume.offerVolumes[0].value_type;

    const description = productData.offers
      .sort((a, b) => a.name.length - b.name.length)[0]
      .name.split(`${productVolume} `)[1];

    if (!description) return "";

    return description[0].toUpperCase() + description.slice(1);
  }, [productData?.offers]);

  const scrollToReviews = () => {
    reviewsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const newParfumartUrlProduct = `${process.env.REACT_APP_REDIRECT_PRODUCT_URL}/${productData?.product.id}-${productData?.product.product_rewrite_name}`;

  const metaData = useMemo(() => {
    if (!productData || !productData.product) {
      return {
        title: "купить с доставкой по всей территории России | Интернет магазин Parfumart",
        description: "купить в Интернет магазине Parfumart ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России",
      };
    }

    const name = productData?.product?.name;
    const offerName = productData?.offers?.[0]?.name ?? "";
    const typeWithoutMl = offerName.replace(/\d+\sмл/, "").trim();
    const type = typeWithoutMl.split(",")[0];
    const baseTitle = "купить с доставкой по всей территории России | Интернет магазин Parfumart";
    const baseDescription = "купить в Интернет магазине Parfumart ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России";

    const isCosmetic = productData?.product?.category.name === "Косметика";
    const isPerfume = productData?.product?.category.name === "Парфюмерия";

    let title;
    let metaDescription;

    if (isCosmetic) {
      title = `${name} - ${baseTitle}`;
      metaDescription = `${name} - ${baseDescription}`;
    } else if (isPerfume) {
      title = `${name} ${type} - ${baseTitle}`;
      metaDescription = `${name} ${type} - ${baseDescription}`;
    } else {
      title = `${name} - ${baseTitle}`;
      metaDescription = `${name} - ${baseDescription}`;
    }

    return { title, description: metaDescription, name, type };
  }, [productData]);

  // yandex ecommerce 'detail'
  useEffect(() => {

    if (!productData) {
      return;
    }

    const product = productData.product;
    const detailData = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.detail]: {
          products: [
            {
              id: product.id,
              name: product.name,
              category: product.category.name,
              brand: (product.brands && !!product.brands.length) ? product.brands[0].brand.name : "",
            }
          ]
        }
      }
    };

    const handleEcommerceData = async () => {
      await handleYandexEcommerce(detailData);
    };

    handleEcommerceData();
  }, [productData]);

  return (
    <>
      <HeadHelmet title={metaData.title} description={metaData.description} />
      <main
        className={styles.root}
        itemScope
        itemType="https://schema.org/Product"
      >
        {!deviceType.isMobile && !deviceType.isTablet ? (
          <div>
            <ProductHeaderLine
              productId={productData?.product.id}
              breadcrumbsValues={breadcrumbsValues}
              image={productData?.product?.image?.link}
              productTitle={productData?.product.name}
              isLoading={isLoading}
            />
            <ProductMainContent
              product={productData?.product}
              offers={productData?.offers}
              properties={productData?.product.properties}
              scrollToReviews={scrollToReviews}
              article={productData?.product.vendorCode}
              isLoading={isLoading}
              setIsOpenRedirectModalCart={setIsOpenRedirectModalCart}
              type={metaData.type}
              name={metaData.name}
            />
            {process.env.REACT_APP_COMPONENT_ENABLE_QandA_FOR_OFFER !== "false" && (
              <ProductAdditionalContent
                title={productData?.product.name}
                description={productMinDescription}
                reviewsRef={reviewsRef}
              />
            )}
          </div>
        ) : (
          <>
            {/* <Breadcrumbs
              breadcrumbs={breadcrumbsValues}
              containerStyles={styles.breadcrumbs}
              isLoading={isLoading}
              showArrow
              isLastItemArrowRotated
            /> */}
            <MobileProductHeaderLine
              productId={productData?.product.id}
              breadcrumbsValues={breadcrumbsValues}
              image={productData?.product?.image?.link}
              productTitle={productData?.product.name}
              isLoading={isLoading}
            />
            <MobileMainProductContent
              product={productData?.product}
              offers={productData?.offers}
              isLoading={isLoading}
              setIsOpenRedirectModalCart={setIsOpenRedirectModalCart}
            />
            {isLoading ? (
              <SkeletonProductDescription />
            ) : (
              <ProductDescription
                brandTitle={productData?.product.brands[0].brand.name}
                brandRewriteName={
                  productData?.product.brands[0].brand.rewrite_name
                }
                productTitle={productData?.product.name}
                productRewrateName={productData?.product.product_rewrite_name}
                category={productData?.product.category}
                description={productData?.product.description}
                properties={productData?.product.properties}
                offers={productData?.offers}
                scrollToReviews={scrollToReviews}
              />
            )}
          </>
        )}

        <ModalRedirect
          onClose={() => setIsOpenRedirectModalCart(false)}
          url={newParfumartUrlProduct}
          isOpen={isOpenRedirectModalCart}
          modalRef={modalRedirectCartRef}
          textForRedirect={"оформления заказа"}
        />
      </main>
    </>
  );
};

export default SingleProductPage;

