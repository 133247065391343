import { useMemo } from "react";

import { usePagination, DOTS } from "../../utils/hooks/usePagination";

import cn from "classnames";
import styles from "./styles.module.scss";

interface IPaginationProps {
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (value: number | string) => void;
  containerStyles?: string;
}

const Pagination: React.FC<IPaginationProps> = (props) => {
  const {
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    onPageChange
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const isHidePagination = useMemo(() => {
    return currentPage === 0 || paginationRange.length < 2;
  }, [currentPage, paginationRange]);

  return (
    !isHidePagination && (
      <ul className={cn(styles["pagination-container"], props.containerStyles)}>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className={styles["pagination-item__dots"]}>
                ....
              </li>
            );
          }
          return (
            <li
              key={index}
              className={cn(styles["pagination-item"], {
                [styles["pagination-item--selected"]]:
                  pageNumber === currentPage
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      </ul>
    )
  );
};

export default Pagination;
