import { useMemo, useState } from "react";

import phoneNumberMask from "../../utils/inputMasks/phoneNumberMask";

import cn from "classnames";
import styles from "./styles.module.scss";

interface ICustomAuthInputProps {
  name: string;
  inputType: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorValue?: string;
  showErrorsOnSubmit?: boolean;
  containerClassName?: string;
  className?: string;
  showErrorIfErrorInResponse?: boolean;
  isSubmitting?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CustomAuthInput: React.FC<ICustomAuthInputProps> = (props) => {
  const [inputValue, setInputValue] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);

  const showError = useMemo(() => {
    if (props.isSubmitting === false) return false;
    return !props.value ? props.showErrorsOnSubmit : !!props.errorValue;
  }, [
    props.showErrorsOnSubmit,
    props.errorValue,
    props.value,
    props.isSubmitting
  ]);

  const maxInputLength = useMemo(() => {
    return props.inputType === "tel" ? 18 : undefined;
  }, [props.inputType]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.inputType === "tel") {
      phoneNumberMask(event);
    }

    props.onChange(event);
    setInputValue(event.target.value);
  };
  return (
    <div className={cn(styles.root, props.containerClassName)}>
      <input
        name={props.name}
        type={showPassword ? "text" : props.inputType}
        placeholder={props.placeholder}
        className={cn(
          styles.input,
          {
            [styles["input--error"]]:
              showError || props.showErrorIfErrorInResponse
          },
          props.className
        )}
        value={inputValue}
        onChange={onChangeHandler}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        maxLength={maxInputLength}
      />
      {props.inputType === "password" && inputValue.length > 0 && (
        <button
          className={styles["show-password"]}
          onClick={() => setShowPassword(!showPassword)}
          type="button"
        >
          {!showPassword ? "Показать" : "Скрыть"}
        </button>
      )}
      {showError && (
        <span className={styles["error-message"]}>{props.errorValue}</span>
      )}
    </div>
  );
};

export default CustomAuthInput;
