import PropTypes from "prop-types";

import ProductDescriptionInfo from "./ProductDescriptionInfo";

import styles from "./styles.module.scss";

const ProductDescription = (props) => {
  return (
    <div className={styles.root}>
      <ProductDescriptionInfo
        {...props}
      />
    </div>
  );
};

const propTypes = {
  brandDescription: PropTypes.string,
  brandTitle: PropTypes.string,
  brandRewriteName: PropTypes.string,
  productTitle: PropTypes.string,
  productRewrateName: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.object,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      akcia: PropTypes.number,
      bestseller: PropTypes.bool,
      "catalog_name": PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      "new_label": PropTypes.bool,
      offerVolumes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          type: PropTypes.string,
          value: PropTypes.number,
          "value_type": PropTypes.string,
        })
      ),
      percent: PropTypes.number,
      price: PropTypes.number,
      "price_sale": PropTypes.number,
    })
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      "property_id": PropTypes.number,
      "property_name": PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    })
  ),
  scrollToReviews: PropTypes.func.isRequired,
};

ProductDescription.propTypes = propTypes;

export default ProductDescription;
