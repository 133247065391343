import { useMemo } from "react";

import { PROVIDERS } from "../../../../../../utils/constants";
import OrderStringItem from "../../../../../../components/UI/OrderStringItem/OrderStringItem";

import cn from "classnames";
import styles from "./styles.module.scss";
import { OrderRecipient } from "types/IGetUserOrderData";

interface IOrderUserInfoProps {
  orderRecipient: OrderRecipient;
  trackNumber: string;
  date: string;
  deliveryProvider: string;
}

const OrderUserInfo: React.FC<IOrderUserInfoProps> = (props) => {

  const formatedPhoneNumber = useMemo(() => {
    const phoneIntoString = props.orderRecipient.phone.replace(/\D/g, "");
    const firstNumberPart = `+7 (${phoneIntoString.slice(1, 4)}) `;
    const secondNumberPart = `${phoneIntoString.slice(4, 7)}-`;
    const lastNumberPart = `${phoneIntoString.slice(
      7,
      9
    )}-${phoneIntoString.slice(9, 11)}`;
    return `${firstNumberPart}${secondNumberPart}${lastNumberPart}`;
  }, [props.orderRecipient.phone]);

  const currentDate = useMemo(() => {
    if (!props.date) {
      return "";
    }
    const date = new Date(props.date);
    return date.toLocaleString("ru-RU", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  }, [props.date]);

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <OrderStringItem
          title="Получатель:"
          subtitle={props.orderRecipient.name ?? "-"}
          contanerClassname={styles.row__title}
          subtitleClassname={styles["row__title--active"]}
        />
        <OrderStringItem
          title="Телефон:"
          subtitle={formatedPhoneNumber}
          contanerClassname={styles.row__title}
          subtitleClassname={styles["row__title--active"]}
        />
        <OrderStringItem
          title="Дата получения:"
          subtitle={!currentDate ? "-" : ` c ${currentDate}`}
          contanerClassname={styles.row__title}
          subtitleClassname={styles["row__title--active"]}
        />
      </div>
      <div className={styles.row}>
        <OrderStringItem
          title="Способ получения:"
          subtitle={PROVIDERS[props.deliveryProvider]?.title ?? ""}
          contanerClassname={styles.row__title}
          subtitleClassname={styles["row__title--active"]}
        />
        <OrderStringItem
          title="Адрес:"
          subtitle={props.orderRecipient.address ?? "-"}
          contanerClassname={styles.row__title}
          subtitleClassname={styles["row__title--active"]}
        />
        <OrderStringItem
          title="Трек номер для отслеживания:"
          subtitle={props.trackNumber ?? "-"}
          contanerClassname={styles.row__title}
          subtitleClassname={cn(
            styles["row__title--active"],
            styles["row__track-number"]
          )}
        />
      </div>
    </div>
  );
};

export default OrderUserInfo;