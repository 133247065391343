import { useMemo, useState } from "react";
import PropTypes from "prop-types";

import MainSlider from "../../../../components/MainSlider";
import ProductCard from "../../../../components/ProductCard";

import cn from "classnames";
import styles from "./styles.module.scss";

const GeneralProductsSlider = (props) => {
  const [isOffersModalOpened, setIsOffersModalOpened] = useState(false);

  const slidesToScroll = useMemo(() => {
    if (props.isMobile) {
      return 2;
    }
    return props.isDesktop || props.isLargeDesktop ? 4 : 3;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isMobile, props.isDesktop]);

  const onToggleIsOffersModalOpenedStatus = (value) => {
    setIsOffersModalOpened(value);
  };

  return (
    <div className={styles["slider-wrapper"]}>
      <MainSlider
        slidesSpacing={!props.isMobile ? 150 : undefined}
        sliderContainerStyles={styles.slider}
        contentStyles={styles.content}
        paginationStyles={styles.pagination}
        dotsStyles={styles.dots}
        shouldControlAutoSwitched
        shouldAutoSwitched={props.shouldAutoSwitched && !isOffersModalOpened}
        shouldBeLooped={props.shouldBeLooped}
      >
        {Array.from(
          { length: Math.ceil(props.products.length / slidesToScroll) },
          (_, i) => (
            <div
              className={cn(styles["grouped-cards"], "keen-slider__slide")}
              key={`row${i}`}
            >
              {props.products
                .slice(i * slidesToScroll, (i + 1) * slidesToScroll)
                .map((product) => {
                  return (
                    <div key={product.id} className={styles["card-wrapper"]}>
                      <ProductCard
                        product={product}
                        key={product.id}
                        onToggleIsOffersModalOpenedStatus={
                          onToggleIsOffersModalOpenedStatus
                        }
                      />
                    </div>
                  );
                })}
            </div>
          )
        )}
      </MainSlider>
    </div>
  );
};

const propTypes = {
  products: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isLargeDesktop: PropTypes.bool.isRequired,
  shouldAutoSwitched: PropTypes.bool,
  shouldBeLooped: PropTypes.bool,
};

GeneralProductsSlider.propTypes = propTypes;

export default GeneralProductsSlider;
