import { AdditionalSearchOptionsEnum, FilterOrder, FilterTypesEnum } from "../constants/catalogFilter";

export const getConvertedFilters = ({ filtersData, selectedParams }) => {
  if (!filtersData) {
    return {};
  }

  const {
    product_properties: productProperties,
    product_types: productTypes,
    goods_types: goodsTypes,
    application_methods: applicationMethods,
    ...rest
  } = filtersData;

  const {
    badges = "",
    brands = "",
    categories = "",
    product_types: productTypesParams = "",
    goods_types: goodsTypesParams = "",
    applicationMethods: applicationMethodsParams = "",
    ...paramsRest
  } = selectedParams;

  const {
    result: propertiesFilters,
    selected: selectedPropertiesFilters
  } = getFormattedProductProperties({
    productProperties,
    selectedParams: paramsRest,
  });

  const {
    result: typesFilters,
    selected: selectedTypesFilters
  } = getFormattedRewriteValueToRewriteName({
    items: productTypes,
    selectedParams: { "product_types": productTypesParams },
  });

  const {
    result: goodsTypesFilter,
    selected: selectedGoodsTypes
  } = getFormattedRewriteValueToRewriteName({
    items: goodsTypes,
    selectedParams: { "goods_types": goodsTypesParams },
  });

  const {
    result: applicationMethodsFilter,
    selected: selectedApplicationMethods
  } = getFormattedRewriteValueToRewriteName({
    items: applicationMethods,
    selectedParams: { "application_methods": applicationMethodsParams },
  });

  const {
    result: additionalFilters,
    selected: selectedAdditionalFilters
  } = getAdditionalFormattedFilters({
    filtersData: rest,
    selectedParams: { badges, brands, categories },
  });

  const mergedFilters = Object.assign(additionalFilters, propertiesFilters, typesFilters, goodsTypesFilter, applicationMethodsFilter);
  const sortedByOrder = getSortedFiltersByOrder(mergedFilters);
  return {
    result: Object.fromEntries(sortedByOrder),
    selected: Object.assign(selectedAdditionalFilters, selectedPropertiesFilters, selectedTypesFilters, selectedGoodsTypes, selectedApplicationMethods),
  };
};

export const getQueryStringForRequest = (options) => {
  const requestData = Object.keys(options)?.map((item) => {
    if (!options[item]?.length) {
      return "";
    }

    if (!Array.isArray(options[item])) {
      return `&${item}=${options[item]}`;
    }

    const rewriteNames = options[item]?.map((elem) => {
      return elem.rewrite_name;
    });

    if (item in FilterTypesEnum) {
      return `&${item}=${rewriteNames.join(",")}`;
    }

    return `&properties[${item}]=${rewriteNames.join(",")}`;
  });

  return `?${requestData.join("").slice(1)}`;
};

export const getSearchOptionsFromQueryString = ({ params, filtersFromRequest }) => {
  const availableCategoriesNames = Object.keys(params);

  const convertedFilters = Object.values(filtersFromRequest).reduce((acc, cur) => {
    const findedGroupName = cur.find((elem) => {
      return availableCategoriesNames.includes(elem.main_rewrite_name);
    });

    if (!findedGroupName) {
      return acc;
    }
    const currentGroupName = findedGroupName.main_rewrite_name;
    const currentGroup = params[currentGroupName];

    const activeFilters = cur.filter((elem) => {
      const findedElemIdx = currentGroup.split(",").findIndex((item) => {
        return item.toLowerCase() === elem.rewrite_name.toLowerCase();
      });
      return findedElemIdx !== -1;
    });

    return {
      ...acc,
      [currentGroupName]: activeFilters
    };
  }, {});

  const additionalOptions = Object.keys(AdditionalSearchOptionsEnum).reduce((acc, cur) => {
    if (!params[cur]) {
      return acc;
    }
    acc = {
      ...acc,
      [cur]: params[cur],
    };
    return acc;
  }, {});

  return {
    convertedFilters,
    additionalOptions,
  };
};

export const getTransformedValuesFromQuery = (options) => {
  const values = {};
  for (let key in options) {
    if (key in FilterTypesEnum || key in AdditionalSearchOptionsEnum) {
      values[key] = options[key];
    } else {
      values[`properties[${key}]`] = options[key];
    }
  }
  return values;
};

export const getFiltersGroupTitle = (filters) => {
  return Object.entries(filters).reduce((acc, cur) => {
    const values = cur[1];
    if (!values[0]?.main_rewrite_name) {
      return acc;
    }

    acc = { ...acc, [values[0].main_rewrite_name]: cur[0] };
    return acc;
  }, {});
};

function checkIsItemSelectedByQueryString ({ queryString, itemName }) {
  if (!queryString) {
    return false;
  }
  const selectedFiltersFromQuery = queryString.split(",");

  return selectedFiltersFromQuery.some((item) => {
    return itemName === item;
  });
}

function getFormattedProductProperties ({ productProperties, selectedParams }) {
  const selected = {};

  if (!productProperties) {
    return {
      result: {},
      selected,
    };
  }

  const formatted = productProperties.reduce((acc, cur) => {
    const groupRewriteName = cur.property.rewrite_name;
    const propertyName = cur.property.name;

    if (!Array.isArray(acc[groupRewriteName])) {
      acc[groupRewriteName] = [];
    }

    const isSelected = checkIsItemSelectedByQueryString({
      queryString: selectedParams[groupRewriteName],
      itemName: cur.rewrite_name,
    });

    const item = {
      name: cur.name,
      "rewrite_name": cur.rewrite_name,
      "main_rewrite_name": cur.property.rewrite_name,
      "main_name": propertyName,
      isSelected,
    };

    acc[groupRewriteName].push(item);

    if (isSelected) {
      if (!Array.isArray(selected[groupRewriteName])) {
        selected[groupRewriteName] = [];
      }

      selected[groupRewriteName].push(item);
    }

    return acc;
  }, {});

  const sorted = getSortedProductProperties(formatted);
  return {
    result: Object.fromEntries(sorted),
    selected,
  };
}

function getFormattedRewriteValueToRewriteName ({ items, selectedParams }) {
  const key = Object.keys(selectedParams)[0];
  if (!items) {
    return {
      result: {},
      selected: {},
    };
  }

  return items.reduce((acc, cur) => {
    if (!Array.isArray(acc.result[key])) {
      acc.result[key] = [];
    }

    const isSelected = checkIsItemSelectedByQueryString({
      queryString: selectedParams[key],
      itemName: cur.rewrite_value,
    });

    const item = {
      name: cur.value,
      "rewrite_name": cur.rewrite_value,
      "main_rewrite_name": key,
      "main_name": FilterTypesEnum[key],
      isSelected,
    };

    acc.result[key].push(item);

    if (isSelected) {
      if (!Array.isArray(acc.selected[key])) {
        acc.selected[key] = [];
      }

      acc.selected[key].push(item);
    }

    return acc;
  }, {
    result: {},
    selected: {},
  });
}

function getAdditionalFormattedFilters ({ filtersData, selectedParams }) {
  if (!filtersData) {
    return {};
  }

  const result = {};
  const selected = {};

  for (let [key, optionValue] of Object.entries(filtersData)) {
    if (!Array.isArray(result[key])) {
      result[key] = [];
    }
    if (!Array.isArray(optionValue)) {
      result[key].push(optionValue);
      continue;
    }
    optionValue.forEach((el) => {
      const isSelected = checkIsItemSelectedByQueryString({
        queryString: selectedParams[key],
        itemName: el.rewrite_name,
      });

      const item = {
        ...el,
        "main_rewrite_name": key,
        "main_name": FilterTypesEnum[key],
        isSelected,
      };

      result[key].push(item);

      if (isSelected) {
        if (!Array.isArray(selected[key])) {
          selected[key] = [];
        }

        selected[key].push(item);
      }
    });

  }

  return {
    result,
    selected,
  };
}

function getSortedFiltersByOrder (filters) {
  return [...Object.entries(filters)].sort((a, b) => {
    return a[0] in FilterOrder && b[0] in FilterOrder
      ? FilterOrder[a[0]] - FilterOrder[b[0]]
      : 1;
  });
}

function getSortedProductProperties (productProperties) {
  return [...Object.entries(productProperties)].map((item) => {
    item[1].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return item;
  });
}

export const getSearchParamsWithoutResetedFilter = ({ filters, groupKey, itemId }) => {
  const filtered = filters?.[groupKey]?.filter((item) => {
    return item.id !== itemId;
  });

  const remainingFilters = {
    ...filters,
    [groupKey]: filtered,
  };

  return Object.entries(remainingFilters).reduce((acc, cur) => {
    if (!cur || !cur[1]?.length) {
      return acc;
    }
    const rewriteNames = cur[1].map((el) => {
      return el.rewrite_name;
    });
    acc[cur[0]] = rewriteNames.join(",");
    return acc;
  }, {});
};

export const getFiltersOrderedByQuery = ({ query, filters }) => {
  const formattedQuery = Object.fromEntries(query);

  const ordered = Object.entries(formattedQuery).reduce((acc, cur) => {
    const key = cur[0];
    const values = cur[1].split(",");

    acc[key] = values.reduce((accum, el) => {
      const findedItem = filters[key]?.find((item) => {
        return item.rewrite_name === el;
      });
      if (findedItem) {
        accum.push(findedItem);
      }
      return accum;
    }, []);
    return acc;
  }, {});

  const { kategoria, ...rest } = ordered;

  if (!kategoria) {
    return Object.values(rest);
  }

  return [kategoria, ...Object.values(rest)];
};