import { nanoid } from "@reduxjs/toolkit";

// import yandexIcon from "../../assets/icons/png/yandex.png";
// import vkIcon from "../../assets/icons/png/vk-main.png";
import tinkoffIcon from "../../assets/icons/png/tinkoff.png";
// import googleIcon from "../../assets/icons/png/google.png";

// TODO: Turn on later

export const SOCIAL_MEDIA = [
  // {
  //   id: nanoid(),
  //   title: "Яндекс ID",
  //   img: yandexIcon,
  //   alt: "yandex-icon",
  //   vendorName: "",
  //   service: "",
  // },
  {
    id: nanoid(),
    title: "T-ID",
    img: tinkoffIcon,
    alt: "tinkoff-icon",
    vendorName: "tinkoff",
    service: "tinkoff_id",
  },
  // {
  //   id: nanoid(),
  //   title: "VK ID",
  //   img: vkIcon,
  //   alt: "vk-icon",
  //   vendorName: "",
  //   service: "",
  // },
  // {
  //   id: nanoid(),
  //   title: "Google ID",
  //   img: googleIcon,
  //   alt: "google-icon",
  //   vendorName: "",
  //   service: "",
  // },
];
