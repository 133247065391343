import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import useOutsideClick from "../../../../utils/hooks/useOutsideClick";
import PaymentAndDeliveryInnerContent from "../../../../components/PaymentAndDeliveryInnerContent/PaymentAndDeliveryInnerContent";

import styles from "./styles.module.scss";

const PaymentAndDeliveryBlock = () => {
  const [isOpen, setIsOpen] = useState(false);

  const blockRef = useRef(null);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick({
    ref: blockRef,
    cb: () => setIsOpen(false),
  });

  return (
    <li ref={blockRef} className={styles.root}>
      <div className={styles["list__item-element"]} onClick={onClickHandler}>
        Оплата и доставка
      </div>
      <CSSTransition
        in={isOpen}
        timeout={100}
        classNames={{
          enterDone: styles["contacts-enter-done"],
        }}
        unmountOnExit
      >
        <PaymentAndDeliveryInnerContent closeModal={onClickHandler} containerStyles={styles.contacts} />
      </CSSTransition>
    </li>
  );
};

export default PaymentAndDeliveryBlock;
