import { createSlice } from "@reduxjs/toolkit";
import { IBannerDataReal } from "types/IBannersOnMainPage";
import { fetchBanners } from "./bannerThunks/bannerThunks";

interface BannersState {
  banners: IBannerDataReal[];
  isLoading: boolean;
  errorMessage: string | null;
}

const initialState: BannersState = {
  banners: [],
  isLoading: false,
  errorMessage: null,
};

const banners = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = null;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.banners = action.payload.response?.items || [];
        state.isLoading = false;
        state.errorMessage = null;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message || "Ошибка при загрузке баннеров";
      });
  },
});

export default banners.reducer;