import { MEDIA } from "./constants";

const getDeviceType = () => {
  const screenWidth = window.innerWidth;
  let isMobile = false;
  let isTablet = false;
  let isDesktop = false;
  let isLargeDesktop = false;

  if (screenWidth <= MEDIA.mobile) {
    isMobile = true;
  } else if (screenWidth >= MEDIA.largeDesktop) {
    isLargeDesktop = true;
  } else if (screenWidth >= MEDIA.desktop) {
    isDesktop = true;
  } else {
    isTablet = true;
  }

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  };
};

export default getDeviceType;
