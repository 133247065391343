import { memo, useCallback, useState } from "react";
import { useAppSelector } from "store/reduxHooks";
import LoadingText from "components/UI/LoadingText/LoadingText";

import plusIcon from "../../../../../../assets/icons/svg/plus-icon.svg";
import minusIcon from "../../../../../../assets/icons/svg/minus-icon.svg";

import FiltersListV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/FiltersListV3/FiltersListV3";

import { IFilterWithKeyObj } from "store/catalogV3";

import styles from "./styles.module.scss";

interface IFilterButtonV3Props {
  mainTitle: string;
  filterWithKey: IFilterWithKeyObj;
  showMoreFiltersBS?: () => void;
  isMobileDevice?: boolean;
}

const FilterButtonV3: React.FC<IFilterButtonV3Props> = memo(
  ({ mainTitle, filterWithKey, showMoreFiltersBS, isMobileDevice }) => {
    const { isLoading } = useAppSelector((state) => state.catalogV3);
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpenStatus = useCallback(() => {
      setIsOpen((prev) => !prev);
    }, []);

    return (
      <li className={styles.root}>
        <button className={styles["main-title"]} onClick={toggleOpenStatus}>
          {isLoading ? (
            <LoadingText text={mainTitle} />
          ) : (
            <span>{mainTitle}</span>
          )}
          <img
            src={isOpen ? minusIcon : plusIcon}
            alt="plus-icon"
            className={styles.icon}
          />
        </button>
        <FiltersListV3
          isOpen={isOpen}
          filterWithKey={filterWithKey}
          showMoreFiltersBS={showMoreFiltersBS}
          isMobileDevice={isMobileDevice}
        />
      </li>
    );
  }
);

FilterButtonV3.displayName = "FilterButtonV3";

export default FilterButtonV3;
