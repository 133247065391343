/* eslint-disable react/prop-types */
import { useMemo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";
import { ICartItem } from "types/ICartItem";
import { IOrderOffer } from "types/UserApiTypes";

interface CheckoutOrderItemProps {
  item: ICartItem | IOrderOffer;
  rootStyles?: string;
  imageStyles?: string;
}

const CheckoutOrderItem: React.FC<CheckoutOrderItemProps> = ({
  item,
  rootStyles,
  imageStyles
}) => {
  const minPrice = useMemo(() => {
    if (!(item as ICartItem)?.calculated_price) {
      const salePrice = item.offer.price_sale || item.offer.akcia;
      return salePrice || item.offer.price;
    }
    return (item as ICartItem)?.calculated_price;
  }, [item]);

  const offerName = item?.offer?.name || "";

  const noImageLink = useMemo(() => {
    return item.offer.catalog.category?.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [item.offer.catalog.category?.rewrite_name]);

  return (
    <div
      className={cn(styles.root, rootStyles)}
      data-offer_id={item.offer.id}
      data-group_id={item.offer.catalog.id}
    >
      <img
        src={item.offer.catalog.image?.link || noImageLink}
        alt={item.offer.catalog.name}
        className={cn(styles.image, imageStyles)}
      />
      <div className={styles["product-data"]}>
        <p className={styles["product-data__name"]}>
          {item.offer.catalog.name}
          {offerName && `, ${offerName}`}
        </p>
        <p className={styles["product-data__code"]}>
          {`Артикул: ${item.offer.catalog.vendorCode}`}
        </p>
      </div>
      <div className={styles["price-wrapper"]}>
        <p className={styles.amount}>{`x ${item.count}`}</p>
        {item.offer.price === minPrice ? (
          <p className={styles["sale-price"]}>
            {`${formatPrice((item as ICartItem)?.calculated_amount || minPrice * item.count)} ₽`}
          </p>
        ) : (
          <>
            <p className={styles["main-price"]}>
              {`${formatPrice(item.offer.price * item.count)} ₽`}
            </p>
            <p className={styles["sale-price"]}>
              {`${formatPrice(minPrice * item.count)} ₽`}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutOrderItem;
