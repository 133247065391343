import { createAsyncThunk } from "@reduxjs/toolkit";

import { filtersApi } from "../../../api/filtersApi";
import { getTransformedValuesFromQuery } from "../../../utils/helpers/catalogPageHelper";

const AVAILABLE_FILTERS = [
  "brands",
  "badges",
  "product_types",
  "product_properties",
  "prices",
  "goods_types",
  "categories"
  // "application_methods",
];

export const getCatalogFilters = createAsyncThunk(
  "catalog/getCatalogFilters",
  async (options, { rejectWithValue, signal }) => {
    try {
      const { categories, searchParams } = options;
      const formattedParams = Object.fromEntries(searchParams);
      const additionalFilters = getTransformedValuesFromQuery(formattedParams);

      const filterOptions = () => {
        if (!categories) {
          return {
            signal,
            filters: AVAILABLE_FILTERS,
            ...additionalFilters,
          };
        }
        return {
          signal,
          categories,
          filters: AVAILABLE_FILTERS,
          ...additionalFilters,
        };
      };

      const data = await filtersApi.getAllFilters(filterOptions());

      if (data.meta.error) {
        throw new Error(data.meta.error);
      }

      return {
        response: data.response,
        formattedParams,
      };
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  }
);
