import { meta } from "pages/Catalog/metaDataCatalog";

export const getCanonicalUrl = (url: string): string => {
  try {
    const urlObj = new URL(url);
    const baseUrl = `${urlObj.protocol}//${urlObj.host}`;
    const searchParams = urlObj.searchParams;
    const pathname = urlObj.pathname.replace(/\/$/, "");
    const pathnameMeta = meta[pathname];

    if (!pathnameMeta) {
      return "";
    }

    let canonical = pathnameMeta.root?.canonical || "";

    const paramsObject: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      if (paramsObject[key]) {
        paramsObject[key] += `,${value}`;
      } else {
        paramsObject[key] = value;
      }
    });

    const hasSale = paramsObject.badges?.split(",").includes("sale");

    if (hasSale) {
      const pathSale = pathnameMeta["badges=sale"]?.canonical || "";
      if (pathSale) {
        return `${baseUrl}${pathSale}`;
      }
    }

    // eslint-disable-next-line prefer-const
    let dynamicParams: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      if (pathnameMeta) {
        if (pathnameMeta[key]) {
          dynamicParams[key] = value;
        }
        if (pathnameMeta[`${key}=${value}`]) {
          dynamicParams[key] = value;
        }
      }
    });

    const keys = Object.keys(dynamicParams);
    if (keys.length > 0) {
      const key = keys[0];
      const value = dynamicParams[key];

      if (pathnameMeta[`${key}=${value}`]) {
        canonical = pathnameMeta[`${key}=${value}`].canonical;
      } else if (pathnameMeta[key]) {
        canonical = pathnameMeta[key].canonical;
      }
      if (value) {
        const values = value.split(",");
        if (values.length > 0) {
          canonical = canonical.replace(`{${key}}`, values[0]);
        }
      }
    }

    if (canonical && !canonical.startsWith(baseUrl)) {
      canonical = `${baseUrl}${canonical}`;
    }

    return canonical;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Invalid URL:", error);
    return "";
  }
};

export const getCanonicalUrlForInfoPage = (url: string):string => {
  const urlObj = new URL(url);
  return `${urlObj.origin}${urlObj.pathname}`;
};