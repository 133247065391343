import { addProductToCart } from "../../../../../../../../store/user/cartThunks/cartThunks";

import styles from "./styles.module.scss";
import { useAppDispatch } from "store/reduxHooks";
import { Offer } from "types/UserApiTypes";
import { toast } from "react-toastify";
// import { getErrorData } from "utils/getErrorData";

interface ISelectProductsAmountButtonsProps {
  amount: number;
  offerId: number;
  isAvailable: boolean;
}

const SelectProductsAmountButtons: React.FC<
  ISelectProductsAmountButtonsProps
> = (props) => {
  const dispatch = useAppDispatch();

  const addProductAmount = () => {
    try {
      if (!props.isAvailable) {
        return;
      }
      const options: Offer = {
        id: props.offerId,
        count: props.amount + 1
      };
      dispatch(addProductToCart([options]));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара произошла ошибка", err);
      toast.error(
        `При добавлении товара c ID: ${props.offerId} произошла ошибка`
      );
    }
  };

  const removeProductAmount = () => {
    try {
      if (props.amount === 1 || !props.isAvailable) {
        return;
      }
      const options: Offer = {
        id: props.offerId,
        count: props.amount - 1
      };
      dispatch(addProductToCart([options]));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При удалении товара произошла ошибка", err);
      toast.error(
        `При удалении товара c ID: ${props.offerId} произошла ошибка`
      );
    }
  };



  return (
    <div className={styles["amount-wrapper"]}>
      <button className={styles.amount__button} onClick={removeProductAmount}>
        -
      </button>
      <span>{props.amount}</span>
      <button className={styles.amount__button} onClick={addProductAmount}>
        +
      </button>
    </div>
  );
};

export default SelectProductsAmountButtons;
