import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import useOutsideClick from "../../../../utils/hooks/useOutsideClick";

import phoneIcon from "../../../../assets/header_icons/phone-icon.svg";
import ContactsInnerContent from "../../../../components/ContactsInnerContent";

import styles from "./styles.module.scss";

const ContactsBlock = () => {
  const [isOpen, setIsOpen] = useState(false);

  const blockRef = useRef(null);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick({
    ref: blockRef,
    cb: () => setIsOpen(false),
  });

  return (
    <li ref={blockRef} className={styles.root}>
      <button className={styles.icon} onClick={onClickHandler}>
        <img src={phoneIcon} alt="phone-icon" />
      </button>
      <CSSTransition
        in={isOpen}
        timeout={100}
        classNames={{
          enterDone: styles["contacts-enter-done"],
        }}
        unmountOnExit
      >
        <ContactsInnerContent containerStyles={styles.contacts} />
      </CSSTransition>
    </li>
  );
};

export default ContactsBlock;
