import SelectCityInnerContent from "../SelectCityInnerContent/SelectCityInnerContent";
import { ReactComponent as CrossIcon } from "../../assets/icons/svg/cross-rounded.svg";
import useCitiesSearch from "../../utils/hooks/useCitiesSearch";
import { useAppDispatch } from "store/reduxHooks";
import { setUserDeliveryAddress } from "store/user/user";

import cn from "classnames";
import styles from "./styles.module.scss";

interface ISelectCityModalProps {
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  onCloseCb: () => void;
  setCity: (city: string) => Promise<void>;
  containerStyles?: string;
  citiesContainerStyles?: string;
}

const SelectCityModal: React.FC<ISelectCityModalProps> = (props) => {
  const {
    searchValue,
    searchedCities,
    isLoading,
    isNoResultSearch,
    onChangeSearchValue
  } = useCitiesSearch({ limit: 15 });
  const dispatch = useAppDispatch();

  const selectCity = (city: string) => {
    props.setCity(city);
    props.onCloseCb();
    dispatch(setUserDeliveryAddress(""));
  };

  return (
    <div
      ref={props.modalRef}
      className={cn(styles.root, props.containerStyles)}
    >
      <CrossIcon className={styles["cross-icon"]} onClick={props.onClose} />
      <SelectCityInnerContent
        searchValue={searchValue}
        searchedCities={searchedCities}
        isLoading={isLoading}
        isNoResultSearch={isNoResultSearch}
        citiesContainerStyles={styles["cities-container"]}
        onChange={onChangeSearchValue}
        onClose={props.onClose}
        selectCity={selectCity}
      />
    </div>
  );
};

export default SelectCityModal;
