import { memo, useMemo } from "react";
import cn from "classnames";
import { FilterKeysEnum } from "types/FilterKeysEnum";
import { useAppSelector } from "store/reduxHooks";

import {
  IFilterCommon,
  IFiltersV3Core,
  IFilterUncommon,
  IFilterV3Properties
} from "types/IFiltersV3";
import { objectEntries } from "utils/helpers/typeGuardObjEntries";
import { FiltersLocalization } from "utils/constants/filters";
import { defaultFilterOrder } from "utils/constants/filterOrder";
import { IFilterWithKeyObj } from "store/catalogV3";

import CustomButton from "components/CustomButton/CustomButton";
import FilterButtonV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/FilterButtonV3/FilterButtonV3";
import PriceFilterV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/PriceFilterV3/PriceFilterV3";
import SkeletonFiltersV3 from "pages/Catalog/components/SkeletonFiltersV3/SkeletonFiltersV3";

import styles from "./styles.module.scss";

interface IMainFiltersV3Props {
  filters: IFiltersV3Core;
  containerStyles?: string;
  onResetDesktopFilters?: () => void;
  showMoreFiltersBS?: () => void;
  isMobileDevice?: boolean;
}

const MainFiltersV3: React.FC<IMainFiltersV3Props> = memo(
  ({
    filters,
    containerStyles,
    onResetDesktopFilters,
    showMoreFiltersBS,
    isMobileDevice
  }) => {
    const { isFiltersEmpty, selectedFilters } = useAppSelector(
      (state) => state.catalogV3
    );
    const deviceType = useAppSelector((state) => state.user.deviceType);
    const isMobileDevices = deviceType.isMobile || deviceType.isTablet;

    const showPriceFilter = useMemo(() => {
      // показываем фильтр цены ЕСЛИ пришел фильтр цен либо есть выбранный фильтр с ценой
      const priceFilter = filters?.prices;
      const selectedPriceFilter = selectedFilters.find(
        (filter) => filter.mainFilterRewriteName === FilterKeysEnum.PRICES
      );

      if (!priceFilter && !selectedPriceFilter) {
        return false;
      }

      const isMinPrice = !!priceFilter?.min;
      const isMaxPrice = !!priceFilter?.max;

      if ((isMinPrice && isMaxPrice) || selectedPriceFilter) {
        return true;
      }

      return false;
    }, [filters?.prices, selectedFilters]);

    const sortedFiltersByOrder = useMemo(() => {
      // сортировка заменена более упрощённой версией, чтоб не затягивать время, возможно понадобиться более сложная сортировка
      const sortedEntries = objectEntries(filters).sort((a, b) => {
        const indexA = defaultFilterOrder.indexOf(a[0]);
        const indexB = defaultFilterOrder.indexOf(b[0]);

        if (indexA === -1 && indexB === -1) return 0;

        if (indexA === -1) return 1;

        if (indexB === -1) return -1;

        return indexA - indexB;
      });

      return sortedEntries;
    }, [filters]);

    return (
      <ul className={cn(styles.root, containerStyles)}>
        {!isMobileDevices && (
          <CustomButton
            title="Сбросить фильтры"
            className={styles["reset-filters"]}
            isWhite
            onClick={onResetDesktopFilters}
          />
        )}
        {sortedFiltersByOrder.map((item) => {
          if (item[0] === FilterKeysEnum.PRICES) {
            return null;
          }

          if (item[0] === FilterKeysEnum.PRODUCT_PROPERTIES) {
            const productPropertiesFilter = item[1] as IFilterV3Properties;

            return objectEntries(productPropertiesFilter).map(
              ([key, value]) => {
                const filterWithKey: IFilterWithKeyObj = {
                  mainFilterName: value.name,
                  mainFilterKey: value.rewrite_name,
                  subFilters: value.values,
                  isFilterProductProperties: true
                };

                if (!value.values.length) {
                  return null;
                }

                return (
                  <FilterButtonV3
                    mainTitle={value.name}
                    key={key}
                    filterWithKey={filterWithKey}
                    showMoreFiltersBS={showMoreFiltersBS}
                    isMobileDevice={isMobileDevice}
                  />
                );
              }
            );
          }

          const subFilters = item[1] as IFilterCommon[] | IFilterUncommon[];

          const filterWithKey: IFilterWithKeyObj = {
            mainFilterName: FiltersLocalization[item[0]],
            mainFilterKey: item[0],
            subFilters: subFilters
          };

          if (!subFilters.length) {
            return null;
          }

          return (
            <FilterButtonV3
              mainTitle={FiltersLocalization[item[0]]}
              key={item[0]}
              filterWithKey={filterWithKey}
              showMoreFiltersBS={showMoreFiltersBS}
              isMobileDevice={isMobileDevice}
            />
          );
        })}
        {showPriceFilter && <PriceFilterV3 isMobileDevice={isMobileDevice} />}
        {isFiltersEmpty && <SkeletonFiltersV3 />}
      </ul>
    );
  }
);

MainFiltersV3.displayName = "MainFiltersV3";

export default MainFiltersV3;
