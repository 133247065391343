import PropTypes from "prop-types";

import CustomButton from "../../../../components/CustomButton";
import Pagination from "../../../../components/Pagination";
import ProductCard from "../../../../components/ProductCard";

import cn from "classnames";
import { useMemo } from "react";
import { SkeletonCard } from "../../../../components/UI/Skeleton/SkeletonCard/SkeletonCard";
import styles from "./styles.module.scss";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";

const CatalogContent = (props) => {
  const shouldRenderShowMoreButton = useMemo(() => {
    const isProductsEnough = props.products?.length >= props.limit;
    const isLastPage =
      Math.ceil(props.productAmount / props.limit) === props.currentPage;
    return isProductsEnough && !isLastPage;
  }, [props.products, props.limit, props.productAmount, props.currentPage]);

  const skeletonArray = Array.from({ length: props.maxProductsPerPage });

  return (
    <div className={styles.root}>
      <div
        className={cn(styles["products-list"], {
          [styles["products-list--empty"]]:
            !props.products?.length && !props.isInit
        })}
      >
        {!props.products?.length ? (
          <>
            {props.isInit ? (
              skeletonArray.map((_,index) => {
                return (
                  <div key={index} className={styles.card_wrapper}>
                    <SkeletonCard />
                  </div>
                );
              })
            ) : (
              <p className={styles["products-list__title"]}>
                Товары не найдены
              </p>
            )}
          </>
        ) : (
          <>
            {props.products.map((product, idx) => {
              return (
                <div key={`${product.id}${idx}`} className={styles.card_wrapper}>
                  {!props.isInit ? (
                    <ProductCard product={product} />
                  ) : (
                    <SkeletonCard />
                  )}
                </div>
              );
            })}
            {props.isInit && skeletonArray.slice(props.products.length).map((_, index) => (
              <div key={`skeleton-${index}`} className={styles.card_wrapper}>
                <SkeletonCard />
              </div>
            ))}
          </>
        )}
        {props.isMoreProductLoading &&
          skeletonArray.map((_, index) => (
            <div key={index} className={styles.card_wrapper}>
              <SkeletonCard />
            </div>
          ))}
      </div>
      {props.isInit ? (
        <div className={styles["show-block"]}>
          <SkeletonLine
            className={styles["show-block__skeleton"]}
          />
        </div>
      ) : (
        !!props.products?.length && (
          <div className={styles["show-block"]}>
            {shouldRenderShowMoreButton && (
              <CustomButton
                title="Показать еще"
                onClick={props.onClickShowMoreProducts}
                className={styles["show-block__button"]}
                isDisabled={props.isMoreProductLoading}
              />
            )}
            <Pagination
              totalCount={props.productAmount}
              currentPage={props.currentPage}
              pageSize={props.limit}
              onPageChange={props.onClickChangePage}
              containerStyles={
                !shouldRenderShowMoreButton
                  ? styles["show-block__pagination"]
                  : ""
              }
            />
          </div>
        )
      )}
      <div id="diginetica_rec_sys"></div>
    </div>
  );
};

const propTypes = {
  productAmount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onClickChangePage: PropTypes.func.isRequired,
  onClickShowMoreProducts: PropTypes.func.isRequired,
  isInit: PropTypes.bool.isRequired,
  products: PropTypes.array,
  isMoreProductLoading: PropTypes.bool,
  maxProductsPerPage: PropTypes.number.isRequired
};

CatalogContent.propTypes = propTypes;

export default CatalogContent;
