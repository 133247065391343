import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const SortInnerContent = (props) => {
  return (
    <div className={cn(styles.root, props.containerStyles)}>
      {props.titles.map((item) => {
        return (
          <button
            key={item.id}
            className={cn(styles.button, {
              [styles["button--selected"]]: item.name === props.currentTitle,
            })}
            onClick={props.onSelectTitle(item.id)}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
};

const propTypes = {
  currentTitle: PropTypes.string.isRequired,
  titles: PropTypes.array.isRequired,
  onSelectTitle: PropTypes.func.isRequired,
  containerStyles: PropTypes.string,
};

SortInnerContent.propTypes = propTypes;

export default SortInnerContent;
