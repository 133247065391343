// Весь закоментированный код связан с логикой отображения товаров по подписке - который еще не внедрён в проект
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store/reduxHooks";

import { getUserFavoriteProducts } from "../../../../store/user/userThunks/userThunks";

import SelectedProducts from "./SelectedProducts/SelectedProducts";
// import AnchorsBlock from "../components/AnchorsBlock/AnchorsBlock";

import styles from "./styles.module.scss";
import { getErrorData } from "utils/getErrorData";
import LoadingItem from "components/LoadingItem/LoadingItem";
// import { IAnchorData } from "pages/PersonalArea/components/PersonalData/PersonalData";
import { IFavouriteProduct } from "types/IFavouriteProduct";

const UserSelectedProducts: React.FC = () => {
  // const { deviceType } = useAppSelector((state) => state.user);
  const [, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  const favouriteProductsRef = useRef<IFavouriteProduct[]>([]);
  // TODO - разработать блок с товарами на которые оформлена подписка ( пока что только задумка )
  // const subscriptionProductsRef = useRef([]);
  const favouritesAnchorRef = useRef<HTMLDivElement | null>(null);
  // TODO - разработать блок с товарами на которые оформлена подписка ( пока что только задумка )
  // const subscriptionAnchorRef = useRef(null);

  // TODO - разработать блок с товарами на которые оформлена подписка ( пока что только задумка ) не удалять
  // const anchors = useMemo(() => {
  //   const anchorsArray: IAnchorData[] = [
  //     {
  //       id: 0,
  //       title: "Товары",
  //       ref: favouritesAnchorRef,
  //       offsetTop: 200
  //     }
  //     {
  //       id: 1,
  //       title: "Подписка на товары",
  //       ref: subscriptionAnchorRef,
  //       offsetTop: 90
  //     }
  //   ];
  //   return anchorsArray;
  // }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getUserFavoriteProducts())
      .unwrap()
      .then((favouriteProducts) => {
        if (favouriteProducts && favouriteProducts.length > 0) {
          favouriteProductsRef.current = favouriteProducts.map(
            (item) => item.product
          );
          setUpdate((prev) => !prev);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const errorData = getErrorData(error);
        // eslint-disable-next-line no-console
        console.error(
          "Ошибка при получении избранных продуктов пользователя:",
          errorData.message
        );
        setIsLoading(false);
      });
  }, [dispatch]);

  return (
    <section className={styles.root}>
      <section className={styles["products-wrapper"]}>
        {isLoading ? (
          <LoadingItem
            containerStyles={styles.loading}
            titleStyles={styles.loading__title}
          />
        ) : (
          <>
            {!favouriteProductsRef.current.length && (
              <h3 className={styles.title}>Еще ничего не добавлено</h3>
            )}
            {!!favouriteProductsRef.current.length && (
              <SelectedProducts
                ref={favouritesAnchorRef}
                title="Товары"
                products={favouriteProductsRef.current.flat()}
              />
            )}
            {/* TODO - разработать блок с товарами на которые оформлена подписка ( пока что только задумка ) не удалять */}
            {/* {!!subscriptionProductsRef.current.length && (
              <SelectedProducts
                ref={subscriptionAnchorRef}
                title="Подписка на товары"
                products={subscriptionProductsRef.current.flat()}
              />
            )} */}
          </>
        )}
      </section>
      <div id="diginetica_rec_sys"></div>
      {/* TODO - разработать блок с товарами на которые оформлена подписка ( пока что только задумка ) не удалять */}
      {/* {!!favouriteProductsRef.current.length &&
        !!subscriptionProductsRef.current.length &&
        !deviceType.isMobile && <AnchorsBlock anchors={anchors} />} */}
    </section>
  );
};

export default UserSelectedProducts;
