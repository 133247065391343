import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { INIT_CITIES_OBJ } from "../../utils/constants";
import searchIcon from "../../assets/header_icons/search-icon.svg";

import LoadingItem from "../LoadingItem/LoadingItem";

import cn from "classnames";
import styles from "./styles.module.scss";
import { ICityData } from "types/IGetCitiesBySearchValue";

interface ISelectCityInnerContentProps {
  searchValue: string;
  searchedCities: ICityData[];
  isLoading: boolean;
  isNoResultSearch: boolean;
  onChange: (searchValue: string) => void;
  onClose: () => void;
  selectCity: (city: string) => void;
  citiesContainerStyles?: string;
}

const MIN_TEXTAREA_HEIGHT = 36;

const SelectCityInnerContent: React.FC<ISelectCityInnerContentProps> = (
  props
) => {
  const [showShadow, setShowShadow] = useState({
    top: false,
    bottom: false
  });

  const citiesListRef = useRef(null);

  const cities = useMemo(() => {
    if (!props.searchValue) {
      return INIT_CITIES_OBJ.slice(0, 15);
    }
    return props.searchedCities;
  }, [props.searchedCities, props.searchValue]);

  useEffect(() => {
    if (!citiesListRef.current) {
      return;
    }

    setShowShadow({
      top: citiesListRef.current.scrollTop > 0,
      bottom:
        citiesListRef.current.scrollHeight > citiesListRef.current.clientHeight
    });
  }, [props.isLoading, props.isNoResultSearch]);

  const onScrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;

    setShowShadow({
      top: target.scrollTop > 0,
      bottom: target.scrollTop < target.scrollHeight - target.offsetHeight
    });
  };

  const textareaRef = useRef(null);
  const [value, setValue] = useState("");
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    props.onChange(event.target.value);
  };
  useLayoutEffect(() => {
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <h3 className={styles.title}>Укажите свой населённый пункт</h3>
      <div className={styles["input-wrapper"]}>
        <img
          src={searchIcon}
          alt="search-icon"
          className={styles["search-icon"]}
        />
        <textarea
          rows={1}
          onChange={onChange}
          ref={textareaRef}
          style={{
            minHeight: MIN_TEXTAREA_HEIGHT,
            resize: "none",
            wordBreak: "break-word",
            overflow: "hidden"
          }}
          value={props.searchValue}
          placeholder="Выберите населённый пункт"
          className={styles.textarea}
        />
      </div>
      <p className={styles.description}>Например : Москва, Сочи, Краснодар</p>
      {props.isLoading && !props.isNoResultSearch && (
        <LoadingItem
          containerStyles={styles.loading}
          titleStyles={styles.loading__title}
        />
      )}
      {!props.isLoading && props.isNoResultSearch && (
        <p className={styles["search-error"]}>
          По такому запросу городов не найдено
        </p>
      )}
      {!props.isLoading && !props.isNoResultSearch && (
        <div
          className={cn(
            styles["cities-wrapper"],
            { [styles["cities-wrapper--top-shadow"]]: showShadow.top },
            { [styles["cities-wrapper--bottom-shadow"]]: showShadow.bottom }
          )}
        >
          <div
            className={cn(styles.cities, props.citiesContainerStyles)}
            ref={citiesListRef}
            onScroll={onScrollHandler}
          >
            {cities.map((item, index) => {
              return (
                <button
                  key={`${item.fias_id}_${index}`}
                  className={styles.cities__item}
                  onClick={() => props.selectCity(item.name)}
                >
                  <span className={styles["cities__item--name"]}>
                    {item.name}
                  </span>
                  <span className={styles["cities__item--region"]}>
                    {item.reqion_with_type}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectCityInnerContent;
