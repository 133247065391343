import { useMemo, useState } from "react";
import { useKeenSlider } from "keen-slider/react";

const useSlider = (options) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: options.initialSlide ?? 0,
      loop: options.shouldBeLooped ?? false,
      slides: {
        perView: options.slidesPerView ?? 1,
        spacing: options.slidesSpacing ?? 0,
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setIsLoaded(true);
      },
    },
    [
      (slider) => {
        if (!options.shouldAutoSwitched) {
          return;
        }

        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }

        const onMouseOver = () => {
          mouseOver = true;
          clearNextTimeout();
        };

        const onMouseOut = () => {
          mouseOver = false;
          nextTimeout();
        };

        const onStart = () => {
          slider.container.addEventListener("mouseover", onMouseOver);
          slider.container.addEventListener("mouseout", onMouseOut);
          nextTimeout();
        };

        const onStop = () => {
          slider.container.removeEventListener("mouseover", onMouseOver);
          slider.container.removeEventListener("mouseout", onMouseOut);
          clearNextTimeout();
        };

        const onStartAfterActions = () => {
          slider.container.addEventListener("mouseover", onMouseOver);
          slider.container.addEventListener("mouseout", onMouseOut);
          onMouseOut();
        };

        slider.on("created", onStart);
        slider.on("stopped", onStop);
        slider.on("resumed", onStartAfterActions);
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  const availableDots = useMemo(() => {
    if (!isLoaded && !instanceRef.current) {
      return [];
    }
    return [...Array(instanceRef.current.track.details?.slides?.length).keys()];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, window.innerWidth, window.innerWidth]);

  const onClickPrev = () => {
    instanceRef.current?.prev();
  };

  const onClickNext = () => {
    instanceRef.current?.next();
  };

  return {
    currentSlide,
    isLoaded,
    availableDots,
    sliderRef,
    instanceRef,
    onClickPrev,
    onClickNext,
  };
};

export default useSlider;
