import { PaymentProvider } from "types/IGetOrderPaymentLink";
import { IPaymentsOptions } from "types/IPaymentOptions";
import {
  CustomError,
  getErrorData,
  IErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";

export const getAvailablePaymentsMethods = async (
  options: IPaymentsOptions
): Promise<PaymentProvider[] | IErrorData> => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/payments/available`,
      {
        method: "POST",
        body: JSON.stringify(options)
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при получение доступных способов оплаты",
        500
      );
    }

    const response: IGenericResponseRoot<PaymentProvider[]> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }
    return response.response;
  } catch (error) {
    const errorData = getErrorData(error);
    // eslint-disable-next-line no-console
    console.error(
      "При получении доступных способов оплаты произошла ошибка:",
      errorData.message
    );
    return errorData;
  }
};
