import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";

import {
  setOrderCityWithCoordinates,
  setUserDeliveryAddress
} from "../../../../../store/user/user";
import useOutsideClick from "../../../../../utils/hooks/useOutsideClick";
import useYandexMap from "../../../../../utils/hooks/useYandexMap";
import { getCityCoordinates } from "../../../../../utils/ymapsUtils";

import SelectCityModal from "../../../../../components/SelectCityModal/SelectCityModal";
import SelectProviderModal from "../SelectProviderModal/SelectProviderModal";

import BottomSheet from "../../../../../components/UI/Bottomsheet/Bottomsheet";
import MobileModalAdress from "./Components/MobileModalAdress/MobileModalAdress";
import { IEvent } from "types/IYMapTypes";
import {
  IYandexBalloonData,
  IYandexFeature
} from "types/IBalloonYandexMapData";
import { SelectedProvider, SelectedProviderData } from "types/IProviderInfo";

import styles from "./styles.module.scss";

const INITIAL_PROVIDERS: SelectedProvider = {
  x5: true,
  boxberry: true,
  cdek: true,
  parfumart: true
};

const WayToGet: React.FC = () => {
  const { currentCity, checkoutData, deviceType } = useAppSelector(
    (state) => state.user
  );
  const [showSelectCities, setShowSelectCities] = useState(false);
  const [showSelectProviderModal, setSelectProviderModal] = useState(false);
  const [showProviderInfo, setShowProviderInfo] = useState(false);
  const [selectedProviderData, setSelectedProviderData] =
    useState<SelectedProviderData | null>(null);
  const [selectedProvider, setSelectedProvider] = useState(INITIAL_PROVIDERS);
  const [showCurrentMap, setShowCurrentMap] = useState(false);

  const selectCitiesModalRef = useRef<HTMLDivElement | null>(null);
  const ordersPointsAdressesRef = useRef<HTMLDivElement | null>(null);

  const {
    map,
    currentProviders,
    providersInfo,
    onSelectProvider,
    chosenProvider
  } = useYandexMap({
    showMap:
      !deviceType.isTablet &&
      !deviceType.isMobile &&
      showCurrentMap &&
      showSelectProviderModal,
    providersActive: selectedProvider
  });

  const dispatch = useAppDispatch();

  useOutsideClick({
    ref: selectCitiesModalRef,
    cb: () => setShowSelectCities(false)
  });

  useEffect(() => {
    if (!map || !selectedProviderData?.lat || !selectedProviderData?.lng)
      return;
    map.setCenter([selectedProviderData.lat, selectedProviderData.lng], 18);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProviderData]);

  useEffect(() => {
    if (!map) return;

    const onBalloonOpenHandler = (e: IEvent) => {
      const balloon = e.get("target");
      const data = balloon.getData();
      if (data.type === "Cluster") {
        const buttons = document.querySelectorAll(
          ".ymaps-2-1-79-b-cluster-tabs__menu-item"
        );
        const onTabClick = (e: Event) => {
          const clickedItem = e.target as HTMLElement;
          const secretSpan = clickedItem.querySelector(
            "span[id^='my-secret-custom-id']"
          );
          if (secretSpan) {
            const secretText = secretSpan.textContent;
            const foundFeature = (data as IYandexBalloonData).features.find(
              (obj) => obj.geometry.params.mySecretCustomId === secretText
            );
            const params = foundFeature?.geometry.params;
            changeSelectedProviderData({
              ...params,
              id: data.id
            });
            onToggleShowProviderInfo(true);
          }
        };
        buttons.forEach((button) => {
          button.addEventListener("click", onTabClick);
        });

        return () => {
          buttons.forEach((button) => {
            button.removeEventListener("click", onTabClick);
          });
        };
      } else {
        const params = (data as IYandexFeature).geometry.params;
        changeSelectedProviderData({
          ...params,
          id: data.id
        });
        onToggleShowProviderInfo(true);
      }
    };

    map.geoObjects.events.add("balloonopen", onBalloonOpenHandler);
    return () => {
      map.geoObjects.events.remove("balloonopen", onBalloonOpenHandler);
    };
  }, [map]);

  const setCity = async (city: string) => {
    if (city === checkoutData.orderCity) {
      return;
    }
    try {
      const cityCoordinates = await getCityCoordinates(city);
      if (!cityCoordinates.length) {
        throw new Error(
          `Не удалось получить координаты выбранного города ${city}`
        );
      }

      dispatch(
        setOrderCityWithCoordinates({
          city,
          coordinates: cityCoordinates
        })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("При выборе населённого пункта произошла ошибка", error);
    }
  };

  const changeSelectedProviderData = (value: SelectedProviderData | null) => {
    setSelectedProviderData(value);
  };

  const changeCityHandeler = () => {
    setShowSelectCities(!showSelectCities);
    dispatch(setUserDeliveryAddress(""));
    changeSelectedProviderData(null);
    onToggleShowProviderInfo(false);
  };

  const onToggleShowProviderInfo = (value: boolean) => {
    setShowProviderInfo(value);
  };

  const handleModalOpen = () => {
    setSelectProviderModal(true);
    const showMap = setTimeout(() => {
      setShowCurrentMap(true);
    }, 500);
    return () => clearTimeout(showMap);
  };

  return (
    <div className={styles.root}>
      <div className={styles["title-wrapper"]}>
        <span className={styles.step}>2/3</span>
        <h4 className={styles.title}>Способ получения</h4>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.city}>
          <p className={styles.city__title}>
            {`${checkoutData.orderCity || currentCity}`}
          </p>
          <button className={styles.city__button} onClick={changeCityHandeler}>
            Изменить
          </button>
          {showSelectCities && (
            <SelectCityModal
              modalRef={selectCitiesModalRef}
              onClose={() => setShowSelectCities(false)}
              onCloseCb={() => setShowSelectCities(false)}
              setCity={setCity}
              containerStyles={styles.city__modal}
              citiesContainerStyles={styles["city__cities-container"]}
            />
          )}
        </div>
        <p className={styles.address} onClick={handleModalOpen}>
          <span>Адрес:</span>
          <span>{checkoutData.recipient.address || "Выберите адрес"}</span>
        </p>
        <button className={styles["select-address"]} onClick={handleModalOpen}>
          {checkoutData.recipient.address
            ? "Изменить способ получения"
            : "Выбрать способ получения"}
        </button>
      </div>
      {showSelectProviderModal &&
        !deviceType.isMobile &&
        !deviceType.isTablet && (
        <SelectProviderModal
          setCity={setCity}
          modalRef={ordersPointsAdressesRef}
          showProviderInfo={showProviderInfo}
          selectedProviderData={selectedProviderData}
          currentProviders={currentProviders}
          providersInfo={providersInfo}
          onCloseModal={() => setSelectProviderModal(false)}
          onToggleShowProviderInfo={onToggleShowProviderInfo}
          onSelectProvider={onSelectProvider}
          changeSelectedProviderData={changeSelectedProviderData}
          setSelectedProvider={setSelectedProvider}
          selectedProvider={selectedProvider}
          chosenProvider={chosenProvider}
        />
      )}
      <BottomSheet
        isOpen={
          showSelectProviderModal &&
          (deviceType.isMobile || deviceType.isTablet)
        }
        onClose={() => setSelectProviderModal(false)}
      >
        <MobileModalAdress
          setCity={setCity}
          setSelectedProvider={setSelectedProvider}
          selectedProvider={selectedProvider}
          onClose={() => setSelectProviderModal(false)}
          changeSelectedProviderData={changeSelectedProviderData}
          selectedProviderData={selectedProviderData}
          showProviderInfo={showProviderInfo}
        />
      </BottomSheet>
    </div>
  );
};

export default WayToGet;
