import { PropTypes } from "prop-types";
import group from "../../assets/icons/svg/group.svg";
import close from "../../assets/icons/svg/cross-rounded.svg";
import styles from "./styles.module.scss";
import CustomExternalLink from "../CustomExternalLink/CustomExternalLink";


export const RedirectToOldContent = (props) => {

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_content}>
          <div className={styles["container_content_img-wrapper"]}>
            <img src={group} className={styles["container_content_img-wrapper-img"]} />
          </div>
          <h1 className={styles["container_content-heading"]}>Уважаемый клиент!</h1>
          <div className={styles["container_content-text-wrapper"]}>
            <p className={styles["container_content-text-wrapper-text"]}>
              Мы стремимся развивать и улучшать наш сервис и сейчас он находится в процессе обновления.
              Некоторые элементы находятся в процессе доработок,
              поэтому для {props.textForRedirect} мы просим Вас перейти на прежнюю версию сайта.
            </p>
          </div>

          <CustomExternalLink
            path={props.url}
            title="Перейти на старую версию сайта"
            className={styles["container_content-button"]} />

          <button className={styles["container_content-link"]} onClick={props.onClose}>
            Остаться здесь
          </button>
        </div>
        <div className={styles.container_close}>
          <img
            src={close}
            className={styles["container_close-img-close"]}
            onClick={props.onClose}
          />
        </div>
      </div>
    </>
  );
};

const propTypes = {
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
  textForRedirect: PropTypes.string,
};

RedirectToOldContent.propTypes = propTypes;
