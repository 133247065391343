/* eslint-disable camelcase */
import { ISelectedFilter } from "store/catalogV3";
import { FilterKeysEnum } from "types/FilterKeysEnum";
import {
  IFilterCommon,
  IFiltersV3Core,
  IFilterUncommon,
  IPricesObject
} from "types/IFiltersV3";
import { FiltersLocalization } from "utils/constants/filters";

export interface IGetInitialSelectedFiltersData {
  filters: IFiltersV3Core;
  currentSearchParams: URLSearchParams;
}
export const getInitialSelectedFilters = (
  data: IGetInitialSelectedFiltersData
): ISelectedFilter[] => {
  const { filters, currentSearchParams } = data;
  const selectedFilters: ISelectedFilter[] = [];
  const paramsObject = Object.fromEntries(currentSearchParams.entries());

  const priceObject: IPricesObject = {
    min_price: "0",
    max_price: "0"
  };

  for (const [key, value] of Object.entries(paramsObject)) {
    const isMinPriceKey = key === "min_price";
    const isMaxPriceKey = key === "max_price";

    if (isMinPriceKey) {
      priceObject.min_price = value;
    }

    if (isMaxPriceKey) {
      priceObject.max_price = value;
    }

    const isPriceChosen =
      priceObject.min_price !== "0" || priceObject.max_price !== "0";

    if (isPriceChosen) {
      const priceFilter = filters[FilterKeysEnum.PRICES];
      const chosenPriceObj: IPricesObject = {
        min_price: Number(priceObject.min_price)
          ? priceObject.min_price
          : priceFilter.min.toString(),
        max_price: Number(priceObject.max_price)
          ? priceObject.max_price
          : priceFilter.max.toString()
      };

      const selectedPriceFilterObj: ISelectedFilter = {
        mainFilterRewriteName: FilterKeysEnum.PRICES,
        mainFilterLocalizedName: FiltersLocalization[FilterKeysEnum.PRICES],
        filters: [
          {
            name: chosenPriceObj.min_price,
            rewrite_name: "min_price"
          },
          {
            name: chosenPriceObj.max_price,
            rewrite_name: "max_price"
          }
        ]
      };

      const existingPriceFilterIndex = selectedFilters.findIndex(
        (filter) => filter.mainFilterRewriteName === FilterKeysEnum.PRICES
      );
      if (existingPriceFilterIndex !== -1) {
        selectedFilters[existingPriceFilterIndex] = selectedPriceFilterObj;
      } else {
        selectedFilters.push(selectedPriceFilterObj);
      }
    }

    const valuesArray = value.split(","); // Разделяем значение подфильтров на массив из строк для иттерации и определения какие же выбраны

    if (key.startsWith("properties[")) {
      const mainKey = key.slice(11, -1); // Вырезаем ключ product_properties из url чтоб можно было найти по ключу значение из фильтров
      const propertyFilter = filters[FilterKeysEnum.PRODUCT_PROPERTIES];

      if (propertyFilter && propertyFilter[mainKey]) {
        // Совпадения добавляем в массив к выбранным фильтрам
        const matchedSubFiltersValues = propertyFilter[mainKey].values.filter(
          (v) => valuesArray.includes(v.rewrite_name)
        );
        if (matchedSubFiltersValues.length > 0) {
          selectedFilters.push({
            mainFilterRewriteName: key,
            mainFilterLocalizedName: propertyFilter[mainKey].name,
            filters: matchedSubFiltersValues,
            isFilterProductProperties: true
          });
        }
      }
    } else {
      // В случае с фильтрами что есть в FilterKeysEnum кроме цены и свойств что мы обработали выше мы проделываем те же манипуляции
      const filterKey = key as FilterKeysEnum;
      const filter = filters[filterKey] as IFilterCommon[] | IFilterUncommon[];

      if (filter) {
        const matchedSubFilters = filter.filter((f) =>
          valuesArray.includes(
            "rewrite_name" in f ? f.rewrite_name : f.rewrite_value
          )
        );
        if (matchedSubFilters.length > 0) {
          // Эта часть это побольшей части проверка типов - так как там могут быть два либо IFilterCommon[] либо IFilterUncommon[]
          if ("name" in filter[0]) {
            selectedFilters.push({
              mainFilterRewriteName: filterKey,
              mainFilterLocalizedName: FiltersLocalization[filterKey],
              filters: matchedSubFilters as IFilterCommon[]
            });
          } else {
            selectedFilters.push({
              mainFilterRewriteName: filterKey,
              mainFilterLocalizedName: FiltersLocalization[filterKey],
              filters: matchedSubFilters as IFilterUncommon[]
            });
          }
        }
      }
    }
  }

  return selectedFilters;
};
