import { useSelector } from "react-redux";
import CustomModal from "../CustomModal/CustomModal";
import BottomSheet from "../UI/Bottomsheet/Bottomsheet";
import { PropTypes } from "prop-types";

const ModalAndBottomsheet = (props) => {
  const { deviceType } = useSelector((state) => state.user);

  return (
    <>
      {!deviceType.isMobile && props.isOpen && (
        <CustomModal
          modalRef={props.modalRef}
          onClose={props.onClose}
        >
          {props.children}
        </CustomModal>
      )}

      {deviceType.isMobile &&
        <BottomSheet
          isOpen={props.isOpen}
          onClose={props.onClose}
        >
          {props.children}
        </BottomSheet>
      }
    </>
  );
};

const propTypes = {
  children: PropTypes.object,
  modalRef: PropTypes.object,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

ModalAndBottomsheet.propTypes = propTypes;

export default ModalAndBottomsheet;