import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import UserOrders from "./components/UserOrders/UserOrders";
import UserSelectedProducts from "./components/UserSelectedProducts/UserSelectedProducts";
import PersonalData from "./components/PersonalData/PersonalData";
import UserSettings from "./components/UserSettings/UserSettings";

import cn from "classnames";
import styles from "./styles.module.scss";

export interface IPersonalAreaTab {
  id: number;
  title: string;
  path: string;
}

const PERSONAL_AREA_TABS: IPersonalAreaTab[] = [
  {
    id: 0,
    title: "Профиль",
    path: "profile",
  },
  {
    id: 1,
    title: "Заказы",
    path: "orders",
  },
  {
    id: 2,
    title: "Избранное",
    path: "products",
  },
  {
    id: 3,
    title: "Настройки",
    path: "settings",
  },
];

const PersonalArea = () => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(
    location.pathname.split("/personal-area/").pop() || "profile"
  );

  const onClickHandler = (path: string) => {
    setCurrentTab(path);
  };

  useEffect(() => {
    const currentPath = location.pathname.split("/personal-area/").pop();

    if (currentPath === currentTab) {
      return;
    }
    setCurrentTab(currentPath);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Личный кабинет</h2>
      <div className={styles.tabs}>
        {PERSONAL_AREA_TABS.map((item) => {
          return (
            <Link
              key={item.id}
              to={`/personal-area/${item.path}`}
              onClick={() => onClickHandler(item.path)}
              className={cn(styles.tabs__tab, {
                [styles["tabs__tab--active"]]: currentTab === item.path,
              })}
            >
              {item.title}
            </Link>
          );
        })}
      </div>
      {currentTab === PERSONAL_AREA_TABS[0].path && <PersonalData />}
      {currentTab === PERSONAL_AREA_TABS[1].path && <UserOrders />}
      {currentTab === PERSONAL_AREA_TABS[2].path && <UserSelectedProducts />}
      {currentTab === PERSONAL_AREA_TABS[3].path && <UserSettings />}
    </main>
  );
};

export default PersonalArea;