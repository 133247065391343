export const getRelativeLink = (url: string): string => {
  try {
    if (!url) {
      return "/";
    }
    const absoluteUrl = new URL(url);
    return absoluteUrl.pathname + absoluteUrl.search;
  } catch (error) {
    if (url.startsWith("/")) {
      return url.substring(1);
    }
    return `/${url}`;
  }
};
