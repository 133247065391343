import PriceMultiRangeSliderV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/PriceFilterV3/PriceMultiRangeSliderV3/PriceMultiRangeSliderV3";
import LoadingText from "components/UI/LoadingText/LoadingText";
import { useAppSelector } from "store/reduxHooks";

import styles from "./styles.module.scss";

interface IPriceFilterV3Props {
  isMobileDevice?: boolean;
}

const PriceFilterV3: React.FC<IPriceFilterV3Props> = ({ isMobileDevice }) => {
  const { isLoading } = useAppSelector((state) => state.catalogV3);
  return (
    <div className={styles.root}>
      {isLoading ? (
        <LoadingText text={"Цена"} containerStyles={styles.title} />
      ) : (
        <span className={styles.title}>Цена</span>
      )}
      <PriceMultiRangeSliderV3 isMobileDevice={isMobileDevice} />
    </div>
  );
};

export default PriceFilterV3;
