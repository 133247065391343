import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { setUserCurrentCity } from "../../../../../../store/user/user";
import useCitiesSearch from "../../../../../../utils/hooks/useCitiesSearch";

import SelectCityInnerContent from "../../../../../../components/SelectCityInnerContent";
import CitiesSuggestionContent from "../../../../../../components/CitiesSuggestionContent";
import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import geoIcon from "../../../../../../assets/header_icons/icon_geo.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

const SelectCityTab = (props) => {
  const { currentCity } = useSelector((state) => state.user);

  const [isOpenMainBS, setIsOpenMainBS] = useState(false);
  const [isOpenSelectCityBS, setIsOpenSelectCityBS] = useState(false);

  const {
    searchValue,
    searchedCities,
    isLoading,
    isNoResultSearch,
    onChangeSearchValue,
    onResetCitiesSearch,
  } = useCitiesSearch({ limit: 15 });

  const dispatch = useDispatch();

  const onToggleIsOpenMainBS = () => {
    setIsOpenMainBS(!isOpenMainBS);
    props.cb?.();
  };

  const onCloseMainBSHandler = () => {
    setIsOpenMainBS(false);
  };

  const onCloseSelectCityBSHandler = () => {
    setIsOpenSelectCityBS(false);
    onResetCitiesSearch();
  };

  const setCity = (city) => {
    dispatch(setUserCurrentCity(city));
    onResetCitiesSearch();
    onCloseMainBSHandler();
    setIsOpenSelectCityBS(false);
  };

  return (
    <li className={styles.root}>
      <button
        onClick={onToggleIsOpenMainBS}
        className={cn(styles.tab__button, props.buttonStyles)}
      >
        <img
          src={geoIcon}
          alt="geo_icon"
          className={cn(styles["tab__geo-icon"], props.iconStyles)}
        />
        {props.children}
      </button>
      <BottomSheet
        isOpen={isOpenMainBS}
        onClose={onCloseMainBSHandler}
        disableClickOutside={isOpenSelectCityBS}
      >
        <CitiesSuggestionContent
          cityTitle={currentCity}
          onClose={onCloseMainBSHandler}
          onShowSelectCities={() => setIsOpenSelectCityBS(true)}
        />
      </BottomSheet>
      <BottomSheet
        isOpen={isOpenSelectCityBS}
        onClose={onCloseSelectCityBSHandler}
        shouldBeOnFullScreen
        hideBackground
      >
        <SelectCityInnerContent
          searchValue={searchValue}
          searchedCities={searchedCities}
          isLoading={isLoading}
          isNoResultSearch={isNoResultSearch}
          onChange={onChangeSearchValue}
          onClose={() => setIsOpenSelectCityBS(false)}
          selectCity={setCity}
        />
      </BottomSheet>
    </li>
  );
};

const propTypes = {
  buttonStyles: PropTypes.string,
  iconStyles: PropTypes.string,
  cb: PropTypes.func,
  children: PropTypes.node,
};

SelectCityTab.propTypes = propTypes;

export default SelectCityTab;
