import { YANDEX_METRICS_ID } from "./yandexMetricsCore";
// target - идентификатор цели (задается на странице редактирования счетчика при создании или изменении цели);
// params – можно передавать собственные параметры визита;
// callback - функция, вызываемая после отправки данных о просмотре;
// ctx - контекст, доступный в callback-функции по ключевому слову this.


const callYm = (method, target, params = {}, callback = null, ctx = null) => {
  if (typeof ym !== "undefined") {
    // eslint-disable-next-line no-undef
    ym(YANDEX_METRICS_ID, method, target, params, callback, ctx);
  } else {
    // eslint-disable-next-line no-console
    console.error("Yandex Metrika is not loaded");
  }
};

const addToBasketMetric = (orderPrice, currency = "RUB", callback = null, ctx = null) => {
  // eslint-disable-next-line camelcase
  const params = { order_price: orderPrice, currency: currency };
  callYm("reachGoal", "addToBasket", params, callback, ctx);
};

const newOrderCreateMetric = (orderPrice, currency = "RUB", callback = null, ctx = null) => {
  // eslint-disable-next-line camelcase
  const params = { order_price: orderPrice, currency: currency };
  callYm("reachGoal", "newOrderCreate", params, callback, ctx);
};

export const metric = {
  addToBasketMetric,
  newOrderCreateMetric,
};