import { createSlice } from "@reduxjs/toolkit";

import { getCatalogFilters } from "./catalogThunks";
import { getConvertedFilters } from "../../utils/helpers/catalogPageHelper";
import { SORT_TITLES } from "../../pages/Catalog/components/SortButton/SortButton";

const initialState ={
  filters: {},
  selectedFilters: {},
  additionalParams: {
    "sort_by": SORT_TITLES[0].rewrite_name,
    "order_by": SORT_TITLES[0].order_by,
    "min_price": "",
    "max_price": "",
  },
  searchTrigger: false,
  isFiltersLoading: false,
  isProductsLoading: false,
  category: "",
};

const catalog = createSlice({
  name: "catalog",
  initialState: initialState,
  reducers: {
    setCatalogState(state, action) {
      return {
        ...action.payload,
        searchTrigger: state.searchTrigger,
      };
    },
    toggleSelectedFilterStatus(state, action) {
      const key = action.payload.filter.main_rewrite_name;
      const findedItemIdx = state.filters[key]?.findIndex((item) => {
        return item.rewrite_name === action.payload.filter.rewrite_name;
      });

      const currentFilter = state.filters[key][findedItemIdx];

      if (!currentFilter) {
        return state;
      }

      const resultItem = {
        ...currentFilter,
        isSelected: !currentFilter.isSelected
      };

      state.filters[key][findedItemIdx] = resultItem;

      if (!action.payload.skipSearchTrigger) {
        state.searchTrigger = !state.searchTrigger;
      }

      if (Array.isArray(state.selectedFilters[key])) {
        if (resultItem.isSelected) {
          state.selectedFilters[key].push(resultItem);
          return;
        }
        state.selectedFilters[key] = state.selectedFilters[key].filter((item) => {
          return item.rewrite_name !== resultItem.rewrite_name;
        });
        return;
      }

      state.selectedFilters[key] = [resultItem];
    },
    setAdditionalParam(state, action) {
      state[action.payload.type] = action.payload.value;
      if (action.payload.shouldTriggerSearch) {
        state.searchTrigger = !state.searchTrigger;
      }
    },
    setAdditionalParams(state, action) {
      state.additionalParams = {
        ...state.additionalParams,
        ...action.payload.value
      };
      if (action.payload.shouldTriggerSearch) {
        state.searchTrigger = !state.searchTrigger;
      }
    },
    resetCatalogFilters(state, action) {
      const resetedFilters = action.payload.reduce((acc, cur) => {
        if (!state.filters[cur]) {
          return acc;
        }
        acc[cur] = state.filters[cur].map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        });
        return acc;
      }, {});

      state.filters = {
        ...state.filters,
        ...resetedFilters
      };
      state.additionalParams["min_price"] = initialState.additionalParams.min_price;
      state.additionalParams["max_price"] = initialState.additionalParams.max_price;
      state.selectedFilters = initialState.selectedFilters;
      state.searchTrigger = !state.searchTrigger;
    },
    resetAdditionalParams(state) {
      state.additionalParams = initialState.additionalParams;
      state.searchTrigger = !state.searchTrigger;
    },
    toggleSearchTrigger(state) {
      state.searchTrigger = !state.searchTrigger;
    },
    setCatalogCategory(state, action) {
      state.category = action.payload;
    },
    setIsFiltersLoading(state, action) {
      state.isFiltersLoading = action.payload;
    },
    setIsProductsLoading(state, action) {
      state.isProductsLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCatalogFilters.fulfilled, (state, action) => {
      const { result, selected } = getConvertedFilters({
        filtersData: action.payload.response,
        selectedParams: action.payload.formattedParams,
      });
      state.filters = result;
      state.selectedFilters = selected;
    });
  }
});

export const {
  setCatalogState,
  toggleSelectedFilterStatus,
  resetCatalogFilters,
  setAdditionalParam,
  setAdditionalParams,
  toggleSearchTrigger,
  setCatalogCategory,
  resetAdditionalParams,
  setIsFiltersLoading,
  setIsProductsLoading
} = catalog.actions;

export default catalog.reducer;