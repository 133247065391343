import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const CurrentBrandLetterBlock = (props) => {
  const changeFilterTab = (filter) => {
    if (!props.brands[filter]?.length) {
      return;
    }
    props.onSelectFilter(filter);
  };

  return (
    <div className={styles.root}>
      <div className={styles["content-wrapper", "content-wrapper__display-none"]}>
        <p className={styles.text}>Выберите бренд</p>
        <p className={styles.letter}>{props.selectedLetter}</p>
      </div>
      <div className={styles["content-wrapper"]}>
        <button
          className={cn(styles.text, {
            [styles["text--selected"]]: props.activeFilter === "all",
          })}
          onClick={() => changeFilterTab("all")}
        >
          Все бренды
        </button>
        <button
          className={cn(styles.text, {
            [styles["text--selected"]]: props.activeFilter === "parfumeria",
            [styles["text--disabled"]]: !props.brands["parfumeria"]?.length,
          })}
          onClick={() => changeFilterTab("parfumeria")}
        >
          Бренды парфюмерии
        </button>
        <button
          className={cn(styles.text, {
            [styles["text--selected"]]: props.activeFilter === "kosmetika",
            [styles["text--disabled"]]: !props.brands["kosmetika"]?.length,
          })}
          onClick={() => changeFilterTab("kosmetika")}
        >
          Бренды косметики
        </button>
      </div>
    </div>
  );
};

const propTypes = {
  selectedLetter: PropTypes.string.isRequired,
  activeFilter: PropTypes.string.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  brands: PropTypes.object.isRequired,
};

CurrentBrandLetterBlock.propTypes = propTypes;

export default CurrentBrandLetterBlock;
