import { createAsyncThunk } from "@reduxjs/toolkit";
import { isError } from "store/user/userThunks/userThunks";
import { IResponseBanners } from "types/IBannersOnMainPage";
import { CustomError, getErrorData, IErrorData, IGenericResponseRoot } from "utils/getErrorData";
import { getBanners } from "api/getBannersApi";

export const fetchBanners = createAsyncThunk<
  IGenericResponseRoot<IResponseBanners>,
  string,
  { rejectValue: IErrorData }
>(
  "banners/fetchBanners",
  async (folder, { rejectWithValue }) => {
    try {
      const response = await getBanners(folder);

      if (isError(response)) {
        throw new CustomError(response.message, response?.code);
      }

      return response;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при получении баннеров:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);