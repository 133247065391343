import { useMemo } from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import { CustomExpandingText } from "../../../../../../../../../components/CustomExpandingText/CustomExpandingText";

export const AboutProduct = (props) => {

  const updatedDescription = useMemo(() => {
    if (!Array.isArray(props.properties)) {
      return [];
    }
    const formattedDescription = props.properties.reduce((acc, cur) => {
      const groupName = cur.property_value.property.rewrite_name;

      if (!acc[groupName]) {
        acc[groupName] = {
          name: cur.property_value.property.name,
          "rewrite_name": groupName,
          values: [{
            name: cur.property_value.name,
            "rewrite_name": cur.property_value.rewrite_name,
          }],
        };
        return acc;
      }

      acc[groupName] = {
        ...acc[groupName],
        values: [
          ...acc[groupName].values,
          {
            name: cur.property_value.name,
            "rewrite_name": cur.property_value.rewrite_name,
          },
        ],
      };
      return acc;
    }, []);
    return Object.values(formattedDescription);
  }, [props.properties]);

  return (
    <>
      {props.description ?
        <div className={styles.description} itemProp="description" >
          <CustomExpandingText text={props.description} />
        </div>
        :
        <div className={styles["description-empty"]}>
          <h2>Нет описания</h2>
        </div>
      }

      <ul className={styles["properties-list"]}>
        {updatedDescription.map((property, idx) => {
          return (
            <li
              key={`${property.rewrite_name}-${idx}`}
              className={styles["properties-list__item"]}
            >
              <span>{property.name}</span>
              <span className={styles["properties-list__item-separator"]} />
              <span className={styles["properties-list__item-values"]}>
                {property.values.map((item, index) => {
                  const isLast = index === property.values.length - 1;
                  const formattedName = isLast ? item.name : `${item.name}, `;
                  return <span key={index}>{formattedName}</span>;
                })}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const propTypes = {
  description: PropTypes.string,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      "property_id": PropTypes.number,
      "property_name": PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    })
  ),
};

AboutProduct.propTypes = propTypes;