// import { NavLink } from "react-router-dom";

import HeaderScheduleBlock from "./components/HeaderScheduleBlock";
import ContactsBlock from "./components/ContactsBlock";
import PaymentAndDeliveryBlock from "./components/PaymentAndDeliveryBlock/PaymentAndDeliveryBlock";

import styles from "./styles.module.scss";

const StickyHeader = () => {
  return (
    <header className={styles.root}>
      <nav className={styles.navigation}>
        <ul className={styles.navigation__list}>
          <HeaderScheduleBlock />
          <ContactsBlock />
          <PaymentAndDeliveryBlock />
        </ul>
      </nav>
    </header>
  );
};

export default StickyHeader;