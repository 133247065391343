import { useEffect, useRef, useState } from "react";
import { IBannerDataReal } from "types/IBannersOnMainPage";

interface UseSliderProps {
  banners: IBannerDataReal[];
}

const useSlider = ({ banners }: UseSliderProps) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [wasDragging, setWasDragging] = useState(false);
  const dragThreshold = 30;
  const filteredBanners = banners.filter(banner => banner.image.original_webp_image.link || banner.image.reduced_webp_image_50.link);
  const slidesCount = filteredBanners.length;
  const currentBanner = filteredBanners[(currentSlide - 1 + slidesCount) % slidesCount];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        handleNextClick();
      }
    }, 3000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused]);

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentSlide === 0) {
        innerRef.current.style.transition = "none";
        setCurrentSlide(slidesCount);
        innerRef.current.style.marginLeft = `-${slidesCount * 100}%`;
      } else if (currentSlide === slidesCount + 1) {
        innerRef.current.style.transition = "none";
        setCurrentSlide(1);
        innerRef.current.style.marginLeft = "-100%";
      }
    };

    const innerElement = innerRef.current;
    innerElement.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      innerElement.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentSlide, slidesCount]);

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.style.width = `calc(100% * ${slidesCount + 2})`;
    }
  }, [slidesCount]);

  const handleNextClick = () => {
    setIsPaused(true);
    setCurrentSlide((prev) => {
      const newSlide = prev + 1;
      if (newSlide > slidesCount + 1) {
        innerRef.current.style.transition = "none";
        innerRef.current.style.marginLeft = `-${100}%`;
        setTimeout(() => {
          innerRef.current.style.transition = "margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000)";
          innerRef.current.style.marginLeft = `-${200}%`;
        }, 20);
        return 1;
      }

      innerRef.current.style.transition = "margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000)";
      innerRef.current.style.marginLeft = `-${newSlide * 100}%`;
      return newSlide;
    });
  };

  const handlePrevClick = () => {
    setIsPaused(true);
    setCurrentSlide((prev) => {
      const newSlide = prev - 1;
      if (newSlide < 0) {
        innerRef.current.style.transition = "none";
        innerRef.current.style.marginLeft = `-${slidesCount * 100}%`;
        setTimeout(() => {
          innerRef.current.style.transition = "margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000)";
          innerRef.current.style.marginLeft = `-${(slidesCount - 1) * 100}%`;
        }, 20);
        return slidesCount - 1;
      }

      innerRef.current.style.transition = "margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000)";
      innerRef.current.style.marginLeft = `-${newSlide * 100}%`;
      return newSlide;
    });
  };

  const handleDotClick = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setCurrentSlide(index + 1);
    innerRef.current.style.transition = "margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000)";
    innerRef.current.style.marginLeft = `-${(index + 1) * 100}%`;
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
    setStartX(event.clientX);
    setCurrentX(event.clientX);
    innerRef.current.style.transition = "none";
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    setCurrentX(event.clientX);
    const deltaX = event.clientX - startX;
    const percentage = (deltaX / innerRef.current.clientWidth) * 100;
    innerRef.current.style.marginLeft = `-${currentSlide * -100 + percentage}%`;
  };

  const handleMouseUp = () => {
    if (!isDragging) return;
    setIsDragging(false);
    innerRef.current.style.transition = "margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000)";
    const deltaX = currentX - startX;
    if (Math.abs(deltaX) > dragThreshold) {
      setWasDragging(true);
      if (deltaX < 0) {
        handleNextClick();
      } else {
        handlePrevClick();
      }
    } else {
      innerRef.current.style.marginLeft = `-${currentSlide * 100}%`;
      setWasDragging(false);
    }
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setIsPaused(true);
    setIsDragging(true);
    setStartX(event.touches[0].clientX);
    setCurrentX(event.touches[0].clientX);
    innerRef.current.style.transition = "none";
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    setCurrentX(event.touches[0].clientX);
    const deltaX = event.touches[0].clientX - startX;
    const percentage = (deltaX / innerRef.current.clientWidth) * 100;
    innerRef.current.style.marginLeft = `-${currentSlide * -100 + percentage}%`;
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    setIsDragging(false);
    innerRef.current.style.transition = "margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000)";
    const deltaX = currentX - startX;
    if (Math.abs(deltaX) > dragThreshold) {
      setWasDragging(true);
      if (deltaX < 0) {
        handleNextClick();
      } else {
        handlePrevClick();
      }
    } else {
      innerRef.current.style.marginLeft = `-${currentSlide * 100}%`;
      setWasDragging(false);
    }
    setTimeout(() => setIsPaused(false), 4000);
  };

  const handleMouseLeave = () => {
    handleMouseUp();
    setWasDragging(false);
  };

  const handleSlideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (wasDragging) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (wasDragging) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return {
    innerRef,
    currentSlide,
    filteredBanners,
    setCurrentSlide,
    currentBanner,
    handlePrevClick,
    handleNextClick,
    handleDotClick,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    setIsPaused,
    handleMouseLeave,
    handleSlideClick,
    handleLinkClick,
  };
};

export default useSlider;