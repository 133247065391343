export enum FilterKeysEnum {
    BADGES = "badges",
    PRICES = "prices",
    CATEGORIES = "categories",
    PRODUCT_TYPES = "product_types",
    PRODUCT_PROPERTIES = "product_properties",
    BRANDS = "brands",
    GOODS_TYPES = "goods_types",
    APPLICATION_METHODS = "application_methods",
    COLOURS = "colours"
}