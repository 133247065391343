import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const SectionTitle = (props) => {
  return (
    <h2 className={cn(styles.title, props.containerStyles)}>{props.title}</h2>
  );
};

const propTypes = {
  title: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
};

SectionTitle.propTypes = propTypes;

export default SectionTitle;