import { PropTypes } from "prop-types";

export const ProductComposition = () => {
  return (
    <>
      <div></div>
    </>
  );
};


const propTypes = {
  brandTitle: PropTypes.string.isRequired,
  brandRewriteName: PropTypes.string.isRequired,
  description: PropTypes.string,
  productRewrateName: PropTypes.string.isRequired,
  category: PropTypes.object,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      "property_id": PropTypes.number,
      "property_name": PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    })
  ),
};

ProductComposition.propTypes = propTypes;