import {
  ICityData,
  IGetCitiesBySearchValueRequest,
  IGetCitiesBySearchValueResponse
} from "types/IGetCitiesBySearchValue";
import {
  CustomError,
  getErrorData,
  IErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";

const getUserCity = async (): Promise<
  IGenericResponseRoot<ICityData> | IErrorData
> => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_DELIVERY_URL}/api/public/v2/address/city-by-ip`,
      {
        method: "GET"
      }
    );

    const data = await res.json();

    if (!res.ok) {
      const { message } = getErrorData(data);
      throw new Error(message);
    }

    return data as IGenericResponseRoot<ICityData>;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const getCitiesBySearchValue = async (
  data: IGetCitiesBySearchValueRequest
): Promise<
  IGenericResponseRoot<IGetCitiesBySearchValueResponse> | IErrorData
> => {
  const params = data;
  try {
    let limitText = "";

    if (params.limit) {
      limitText = "&limit=" + params.limit;
    }

    const res = await fetch(
      `${process.env.REACT_APP_DELIVERY_URL}/api/public/v2/address/cities?query=${params.query}${limitText}`,
      {
        method: "GET"
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при получении данных о населённом пункте",
        500
      );
    }

    const data = await res.json();

    if (!res.ok) {
      const { message } = getErrorData(data);
      throw new Error(message);
    }

    return data as IGenericResponseRoot<IGetCitiesBySearchValueResponse>;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

export const citiesApi = {
  getUserCity,
  getCitiesBySearchValue
};
