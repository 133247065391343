import { IFilterCommon, IFilterUncommon, IFilterV3Properties } from "types/IFiltersV3";

export const isFilterCommon = (filter: unknown): filter is IFilterCommon => {
  return (
    typeof filter === "object" &&
        filter !== null &&
        "name" in filter &&
        "rewrite_name" in filter
  );
};

// Type guard for IFilterUncommon
export const isFilterUncommon = (filter: unknown): filter is IFilterUncommon => {
  return (
    typeof filter === "object" &&
        filter !== null &&
        "value" in filter &&
        "rewrite_value" in filter
  );
};

export const isFilterV3Properties = (filter: unknown): filter is IFilterV3Properties => {

  if (typeof filter !== "object" || filter === null) {
    return false;
  }

  const keys = Object.keys(filter);

  const hasOtherKeys = keys.some(key =>
    key !== "name" && key !== "value" && key !== "rewrite_name" && key !== "rewrite_value"
  );

  const hasRestrictedKeys = keys.some(key =>
    key === "name" || key === "value" || key === "rewrite_name" || key === "rewrite_value"
  );

  return hasOtherKeys && !hasRestrictedKeys;
};