import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const ProtectedRoute = (props) => {
  const location = useLocation();
  const locationState = location.state;
  const from = locationState?.from?.pathname || "/";

  const { user } = useSelector((state) => state.user);

  if (props.isLogin) {
    if (!user?.phone) {
      return <Navigate to="/sign-in" state={{ from: location }} replace />;
    }
    return props.children;
  }

  if (user?.phone) {
    return <Navigate to={from} state={{ from: location }} replace />;
  }

  return props.children;
};

const propTypes = {
  isLogin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ProtectedRoute.propTypes = propTypes;

export default ProtectedRoute;