"use client";
import { useMemo } from "react";
import { KeenSliderHooks, KeenSliderInstance } from "keen-slider/react";
import cn from "classnames";

import styles from "./styles.module.scss";

interface IDynamicalBulletsProps {
  instanceRefSlider: React.RefObject<KeenSliderInstance<
    unknown,
    unknown,
    KeenSliderHooks
  > | null>;
  availableDots: number[];
  currentSlide: number;
  dotsStyles?: string;
}

const DynamicalBullets: React.FC<IDynamicalBulletsProps> = ({
  instanceRefSlider,
  availableDots,
  dotsStyles,
  currentSlide
}) => {
  const isDynamicCondition = availableDots.length > 4;
  const isSizeOfThreeDots =
    isDynamicCondition &&
    (currentSlide === 0 || currentSlide === availableDots.length - 1);
  const isSizeOfFourDots =
    isDynamicCondition &&
    (currentSlide === 1 || currentSlide === availableDots.length - 2);
  const availableDotsByConditions = useMemo(() => {
    if (isDynamicCondition) {
      if (currentSlide === 0) {
        return availableDots.slice(0, 3);
      } else if (currentSlide === availableDots.length - 1) {
        return availableDots.slice(-3);
      } else if (currentSlide === 1) {
        return availableDots.slice(0, 4);
      } else if (currentSlide === availableDots.length - 2) {
        return availableDots.slice(-4);
      }
      return availableDots.slice(currentSlide - 2, currentSlide + 3);
    }
    return availableDots;
  }, [availableDots, currentSlide, isDynamicCondition]);

  return (
    <div
      className={cn(styles.dots, dotsStyles, {
        [styles["dots__dynamical-wrapper"]]: isDynamicCondition,
        [styles["dots__dynamical-wrapper--three-dots"]]: isSizeOfThreeDots,
        [styles["dots__dynamical-wrapper--four-dots"]]: isSizeOfFourDots
      })}
    >
      {availableDotsByConditions.map((idx) => {
        const isRegularDot =
          isDynamicCondition &&
          (idx === currentSlide - 1 || idx === currentSlide + 1);
        const isSmallDot =
          isDynamicCondition &&
          (idx === currentSlide - 2 || idx === currentSlide + 2);
        const isActiveDot = idx === currentSlide;
        return (
          <button
            key={idx}
            onClick={() => {
              instanceRefSlider.current?.moveToIdx(idx);
            }}
            className={styles["dot-wrapper"]}
            name={`слайд-${idx}`}
          >
            <div
              className={cn(styles.dot, {
                [styles["dot--regular"]]: isRegularDot,
                [styles["dot--small"]]: isSmallDot,
                [styles["dot--active"]]: isActiveDot
              })}
            />
          </button>
        );
      })}
    </div>
  );
};

export default DynamicalBullets;
