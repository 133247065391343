import authenticityIcon from "../../assets/icons/shopping_advantage-icons/authenticity-icon.png";
import checkIcon from "../../assets/icons/shopping_advantage-icons/check-icon.png";
import deliveryIcon from "../../assets/icons/shopping_advantage-icons/delivery-icon.png";
import payIcon from "../../assets/icons/shopping_advantage-icons/pay-icon.png";
import returnIcon from "../../assets/icons/shopping_advantage-icons/return-icon.png";
import secureItem from "../../assets/icons/shopping_advantage-icons/secure-icon.png";

export default {
  authenticityIcon,
  checkIcon,
  deliveryIcon,
  payIcon,
  returnIcon,
  secureItem,
};