import { IFiltersV3Core } from "types/IFiltersV3";
import { getErrorData, IErrorData, IGenericResponseRoot } from "utils/getErrorData";

export interface IFiltersParams {
    signal?: AbortSignal;
    searchParams: URLSearchParams;
}

const getFilters = async (params: IFiltersParams): Promise<IGenericResponseRoot<IFiltersV3Core> | IErrorData> => {

  const {searchParams, signal} = params;

  if (!process.env.REACT_APP_BACKEND_URL) {
    const defaultError: IErrorData = {
      message: "URL для бэкенда не указан"
    };
    return defaultError;
  }

  const queryString = new URLSearchParams(searchParams).toString();

  const url = `${process.env.REACT_APP_BACKEND_URL}/api/public/filters/v3?${queryString}`;

  const requestOptions = {
    method: "GET",
    signal
  };
  try {
    const response = await fetch(url, requestOptions);

    const responseData = await response.json();

    if (!response.ok) {
      const errorData = getErrorData(responseData);
      return errorData;
    }

    const data: IGenericResponseRoot<IFiltersV3Core> = responseData;
    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

export const filtersV3Api = {
  getFilters,
};