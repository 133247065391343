import PropTypes from "prop-types";
import { useState } from "react";

import CustomButton from "../../CustomButton";

import cn from "classnames";
import styles from "./styles.module.scss";

const PromocodeInput = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const onClickHandler = () => {
    props.onSendPromocode();
  };

  const onChangeHandler = (e) => {
    props.onChange(e);
    props.setIsNotValidCode("");
  };

  const onFocusHandler = () => {
    setIsFocused(true);
  };

  const onBlurHandler = () => {
    setIsFocused(false);
  };

  return (
    <div className={styles.root}>
      <input
        type="text"
        placeholder={isFocused ? "" : "Введите промокод"}
        value={props.inputValue}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        autoComplete="off"
        disabled={props.isPromocodeSelected}
        className={cn(
          styles.input,
          props.inputClassname,
          {
            [styles["input--not-valid"]]: !!props.isNotValidCode,
            [styles["input--success"]]: props.isPromocodeSelected,
          }
        )}
      />
      {props.isPromocodeSelected ? (
        <CustomButton
          title="Удалить"
          onClick={props.onRemovePromocode}
          className={cn(styles.button, props.buttonClassname)}
        />
      ) : (
        <CustomButton
          title="Применить"
          onClick={onClickHandler}
          className={cn(styles.button, props.buttonClassname)}
        />
      )}
      {props.isPromocodeSelected ? (
        <span className={styles.success}>Промокод применен успешно</span>
      ) : (
        !!props.isNotValidCode.length && (
          <span className={styles.error}>
            {props.isNotValidCode}
          </span>
        )
      )}
      {/* {props.isNotValidCode === "promocode:not_exist" &&
        <span className={styles.error}>
          Промокод не существует/недействителен
        </span>
      }
      {props.isNotValidCode === "promocode:inactive" &&
        <span className={styles.error}>
          Промокод не активный
        </span>
      }
      {props.isNotValidCode === "promocode:already_used" &&
        <span className={styles.error}>
          Промокод уже использован
        </span>
      }
      {props.isNotValidCode === "promocode:min_amount" &&
        <span className={styles.error}>
          Промокод не соответствует условиям
        </span>
      } */}
    </div>
  );
};

const propTypes = {
  inputValue: PropTypes.string.isRequired,
  inputClassname: PropTypes.string,
  buttonClassname: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSendPromocode: PropTypes.func.isRequired,
  onRemovePromocode: PropTypes.func.isRequired,
  setIsNotValidCode: PropTypes.func,
  isNotValidCode: PropTypes.string,
  isPromocodeSelected: PropTypes.bool
};

PromocodeInput.propTypes = propTypes;

export default PromocodeInput;
