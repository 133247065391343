import { createAsyncThunk } from "@reduxjs/toolkit";
import { filtersV3Api, IFiltersParams } from "api/filtersV3Api";
import { IFiltersV3Core } from "types/IFiltersV3";
import {
  CustomError,
  getErrorData,
  IErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";

export const getFiltersV3 = createAsyncThunk<
  {
    filters: IGenericResponseRoot<IFiltersV3Core>;
    searchParams: Record<string, string>;
  },
  IFiltersParams,
  { rejectValue: IErrorData }
>("catalogV3/getFiltersV3", async (options, { rejectWithValue }) => {
  try {
    const filtersV3Response = await filtersV3Api.getFilters(options);
    if ("message" in filtersV3Response) {
      throw new CustomError(filtersV3Response.message, filtersV3Response?.code);
    }

    const searchParamsObject: Record<string, string> = {};
    options.searchParams.forEach((value, key) => {
      searchParamsObject[key] = value;
    });

    return { filters: filtersV3Response, searchParams: searchParamsObject };
  } catch (err) {
    const errorData = getErrorData(err);
    return rejectWithValue(errorData);
  }
});
