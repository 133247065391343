import styles from "./styles.module.scss";

export const getBalloon = (options) => {
  const {
    address,
    deliveryProvider,
    description,
    paymentCard,
    paymentCash,
    phone,
    timeTable,
    mySecretCustomId,
  } = options;

  const header = `<h3 class=${styles["main-title"]}>${deliveryProvider}<span class=${styles["my-secret-custom-id"]} id="my-secret-custom-id">${mySecretCustomId}</span><h3>`;
  const body = `
  <p>
    ${!address ? "" : `<p class=${styles.content__text}><b>Адрес: </b><span id='delivery-address'>${address}<span><p>`}
    ${!description && !paymentCard && !paymentCash ? "" : `<p class=${styles.content__text}><b>Дополнительная информация:</b><p>`}
    ${!description ? "" : `<p class=${styles.content__text}>${description}<p>`}
    ${!paymentCard ? "" : `<p class=${styles.content__text}>Возможна оплата картой<p>`}
    ${!paymentCash ? "" : `<p class=${styles.content__text}>Возможна оплата наличными<p>`}
  </p>
  `;
  const footer = `
    <p>
      ${!phone ? "" : `<p class=${styles.content__phone}>
        <b class=${styles.content__text}>Телефон: </b>${phone}
      <p>`}
      ${!timeTable ? "" : `<p class=${styles.content__text}><b>График работы: </b>${timeTable}<p>`}
    <p/>
  `;
  return {
    header,
    body,
    footer,
  };
};
