import { useState } from "react";

import sheduleIcon from "../../../../../../assets/header_icons/schedule-icon.svg";

import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import ScheduleInnerContent from "../../../../../../components/ScheduleInnerContent";

import styles from "./styles.module.scss";

const ScheduleTab = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className={styles.root}>
      <button onClick={() => setIsOpen(!isOpen)} className={styles.tab__button}>
        <img
          src={sheduleIcon}
          alt="schedule_icon"
          className={styles["tab__schedule-icon"]}
        />
      </button>
      <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ScheduleInnerContent />
      </BottomSheet>
    </li>
  );
};

export default ScheduleTab;
