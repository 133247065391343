import { IResponseBanners } from "types/IBannersOnMainPage";
import { CustomError, getErrorData, IErrorData, IGenericResponseRoot } from "utils/getErrorData";

export const getBanners = async (folder: string): Promise<IGenericResponseRoot<IResponseBanners> | IErrorData> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/banners?folder=${folder}`);

    if (response.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при получении баннеров.",
        500
      );
    }

    const data: IGenericResponseRoot<IResponseBanners> = await response.json();

    if (!response.ok) {
      const errorData = getErrorData(data);
      throw new Error(errorData.message);
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};