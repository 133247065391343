import { memo, useCallback, useState } from "react";
import { CSSTransition } from "react-transition-group";

import useDidUpdate from "utils/hooks/useDidUpdate";
import LoadingItem from "components/LoadingItem/LoadingItem";

import {
  isFilterCommon,
  isFilterUncommon
} from "utils/helpers/filtersTypeGuards";
import { useAppDispatch } from "store/reduxHooks";

import { IFilterWithKeyObj, setChosenFilterWithKey } from "store/catalogV3";
import SearchInputV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/FilterButtonV3/SearchInputV3/SearchInputV3";
import FilterButtonItemV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/FilterButtonV3/FilterButtonItemV3/FilterButtonItemV3";

import styles from "./styles.module.scss";

interface IFiltersListV3Props {
  isOpen: boolean;
  filterWithKey: IFilterWithKeyObj;
  showMoreFiltersBS?: () => void;
  isMobileDevice?: boolean;
}

const INIT_TRIM_VALUE = 4;
const MAX_FILTERS_TO_SHOW_INPUT = 10;

const FiltersListV3: React.FC<IFiltersListV3Props> = memo(
  ({ isOpen, filterWithKey, showMoreFiltersBS, isMobileDevice }) => {
    const [currentFilters, setCurrentFilters] =
      useState<IFilterWithKeyObj>(filterWithKey);
    const [trimNumber, setTrimNumber] = useState<number>(INIT_TRIM_VALUE);
    const [isError, setIsError] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const showShowMoreButton =
      filterWithKey.subFilters.length >= INIT_TRIM_VALUE &&
      filterWithKey.subFilters.length !== trimNumber;

    const showSearchInput =
      filterWithKey.subFilters.length > MAX_FILTERS_TO_SHOW_INPUT;

    useDidUpdate(() => {
      setCurrentFilters(filterWithKey);
    }, [filterWithKey]);

    const changeCurrentFilters = useCallback(
      ({
        filterWithKey,
        isError
      }: {
        filterWithKey: IFilterWithKeyObj;
        isError: boolean;
      }) => {
        setCurrentFilters(filterWithKey);
        setIsError(isError);
      },
      []
    );

    const handleShowMore = useCallback(() => {
      dispatch(setChosenFilterWithKey(filterWithKey));

      if (!showMoreFiltersBS) {
        setTrimNumber(filterWithKey.subFilters.length);
        return;
      }
      showMoreFiltersBS();
    }, [dispatch, filterWithKey, showMoreFiltersBS]);

    return (
      <>
        <CSSTransition
          in={isOpen}
          timeout={100}
          classNames={{
            enterActive: styles["filters-list-enter-active"],
            enterDone: styles["filters-list-enter-done"],
            exitActive: styles["filters-list-exit-active"],
            exitDone: styles["filters-list-exit-done"]
          }}
          mountOnEnter
        >
          {!filterWithKey.subFilters.length && !isError ? (
            <div className={styles["filters-list"]}>
              <LoadingItem
                figureStyles={styles.loading__figure}
                titleStyles={styles.loading__title}
              />
            </div>
          ) : (
            <div className={styles["filters-list"]}>
              {showSearchInput && (
                <SearchInputV3
                  filterWithKey={filterWithKey}
                  changeCurrentFilters={changeCurrentFilters}
                  containerStyles={styles.search}
                />
              )}
              <div className={styles["list-wrapper"]}>
                <ul className={styles.list}>
                  {currentFilters.subFilters.map((item, idx) => {
                    if (idx >= trimNumber) {
                      return null;
                    }

                    if (isFilterCommon(item)) {
                      return (
                        <FilterButtonItemV3
                          key={`${item.rewrite_name}_${idx}`}
                          item={item}
                          mainFilterRewriteName={currentFilters.mainFilterKey}
                          isFilterProductProperties={
                            currentFilters.isFilterProductProperties
                          }
                          mainFilterLocalizedName={
                            currentFilters.mainFilterName
                          }
                          isMobileDevice={isMobileDevice}
                        />
                      );
                    }

                    if (isFilterUncommon(item)) {
                      return (
                        <FilterButtonItemV3
                          key={`${item.rewrite_value}_${idx}`}
                          item={item}
                          mainFilterRewriteName={currentFilters.mainFilterKey}
                          isFilterProductProperties={
                            currentFilters.isFilterProductProperties
                          }
                          mainFilterLocalizedName={
                            currentFilters.mainFilterName
                          }
                          isMobileDevice={isMobileDevice}
                        />
                      );
                    }
                  })}
                  {showShowMoreButton && (
                    <button
                      className={styles["show-more__button"]}
                      onClick={handleShowMore}
                    >
                      Показать все
                    </button>
                  )}
                </ul>
              </div>
              {isError && <p className={styles.error}>Ничего не найдено</p>}
            </div>
          )}
        </CSSTransition>
      </>
    );
  }
);

FiltersListV3.displayName = "FiltersListV3";

export default FiltersListV3;
