import { useFormik } from "formik";

import phoneNumberMask from "../../../../../../../utils/inputMasks/phoneNumberMask";
import { USER_INFO_FIELDS } from "../../../../../../../utils/constants/formItems";
import { userContactsSchema } from "../../../../../../../schemas/userContactsSchema";

import styles from "./styles.module.scss";
import { setRecipient } from "../../../../../../../store/user/user";
import { IPersonFormData } from "types/IFormData";
import { useAppDispatch } from "store/reduxHooks";

interface IUserContantsFormProps {
  setAnotherPerson: (
    personData:
      | IPersonFormData
      | ((prevState: IPersonFormData) => IPersonFormData)
  ) => void;
  anotherPerson: IPersonFormData;
}

const UserContantsFrom: React.FC<IUserContantsFormProps> = ({
  setAnotherPerson,
  anotherPerson
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<IPersonFormData>({
    initialValues: {
      surname: anotherPerson.surname || "",
      name: anotherPerson.name,
      phone: anotherPerson.phone,
      email: anotherPerson.email
    },
    validationSchema: userContactsSchema,
    onSubmit: () => {
      // Пустое поле onSubmit так как теперь изменяем параметры на прямую
    }
  });

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputType: string
  ) => {
    const fieldName = e.target.name;
    formik.setTouched({
      ...formik.touched,
      [fieldName]: true
    });
    if (inputType !== "tel") {
      const fieldName = e.target.name;
      if (fieldName === "name" || fieldName === "surname") {
        // when user types her/his name with non-capital latter we make it capital
        e.target.value =
          e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      }
      formik.handleChange(e);
    } else {
      phoneNumberMask(e);
      formik.handleChange(e);
    }
    // Изменяем данные о пользователе на прямую
    dispatch(setRecipient({ ...formik.values, [fieldName]: e.target.value }));
    setAnotherPerson({ ...formik.values, [fieldName]: e.target.value });
  };

  return (
    <>
      <form className={styles.root}>
        <h4 className={styles.title}>Введите данные получателя</h4>
        {USER_INFO_FIELDS.map((item) => {
          return (
            <div key={item.id} className={styles.row}>
              <input
                name={item.name}
                type={item.inputType}
                value={formik.values[item.name]}
                onChange={(e) => onChangeHandler(e, item.inputType)}
                className={styles.row__input}
                placeholder={item.title}
                onBlur={formik.handleBlur}
              />
              {item.name !== "email" && item.name !== "surname" && (
                <span className={styles.row__required}>*</span>
              )}
              {!!formik.errors[`${item.name}`] &&
                formik.touched[`${item.name}`] && (
                <span className={styles["row__error-message"]}>
                  {formik.errors[item.name]}
                </span>
              )}
            </div>
          );
        })}
      </form>
    </>
  );
};

export default UserContantsFrom;
