import { useState } from "react";
import styles from "./styles.module.scss";

interface CustomSelectProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className={styles["custom-select"]}>
      <div className={styles["custom-select__box"]} onClick={handleToggle}>
        {value}
      </div>
      {isOpen && (
        <div className={styles["custom-select__options"]}>
          {options.map((option) => (
            <div
              key={option}
              className={styles["custom-select__option"]}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;