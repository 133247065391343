import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const LinkToDelivery = () => {
  return (
    <div className={styles.root}>
      <p className={styles.title}>
        {"> > >"}
        <Link
          to="/info/dostavka"
          rel="noopener noreferrer"
          className={styles.item_link}
        >
          {" "}
          Доставка
        </Link>
      </p>
    </div>
  );
};

export default LinkToDelivery;