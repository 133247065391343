import { IOffer } from "types/IOffer";

const getSortedOffersByPriceAndVolume = (offers: IOffer[]) => {
  const sortedByPrice = [...offers].sort((a, b) => {
    const aPrice = a.price_sale ?? a.akcia ?? a.price;
    const bPrice = b.price_sale ?? b.akcia ?? b.price;

    return aPrice - bPrice;
  });

  return [...sortedByPrice].sort((a, b) => {
    return a.offerVolumes?.[0]?.value - b.offerVolumes?.[0]?.value;
  });
};

export default getSortedOffersByPriceAndVolume;
