export const getUserCityStorage = localStorage.getItem("user_city");

export const setTokens = options => {
  localStorage.setItem("accessToken", options.token);
  localStorage.setItem("userKey", options.refreshToken.key);
  localStorage.setItem("refreshToken", options.refreshToken.token);
};

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userKey");
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("accessToken");
};

export const getUserToken = () => {
  return (
    localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken")
  );
};

export const setValueInStorage = ({ key, value }) => {
  localStorage.setItem(key, value);
};

export const getValueFromStorage = key => {
  const data = localStorage.getItem(key);
  return data;
};

export const clearValueFromStorage = key => {
  localStorage.removeItem(key);
};

export const setUserCityStorage = city => {
  localStorage.setItem("user_city", city);
};
