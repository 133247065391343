import { useMemo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./styles.module.scss";

const PaymentStatusNotification = (props) => {
  const { orderNumber, date, status } = props;

  const statusMessage = useMemo(() => {
    if (!status) {
      return "Cтатус платежа неизвестен";
    }
    if (status === "success") {
      return "Успешно оплачен";
    }
    if (status === "fail") {
      return "Заказ не оплачен";
    }
  }, [status]);

  return (
    <div className={styles["status-block"]}>
      <h3 className={styles["status-block__title"]}>Информация о заказе</h3>
      <p className={styles["status-block__message-block"]}>
        {orderNumber && date && (
          <span>
            Заказ № <span className={styles.primary}>{`${orderNumber}`}</span>{" "}
            от {`${date}`}
          </span>
        )}
        <span
          className={cn(styles["status-block__message-block__payment-status"], {
            [styles["status-block__message-block__payment-status--success"]]:
              status === "success",
            [styles["status-block__message-block__payment-status--fail"]]:
              status === "fail",
          })}
        >
          {statusMessage}
        </span>
      </p>
    </div>
  );
};

const propTypes = {
  orderNumber: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
};

PaymentStatusNotification.propTypes = propTypes;

export default PaymentStatusNotification;