import { FilterKeysEnum } from "types/FilterKeysEnum";
import {
  IFilterCommon,
  IFiltersV3Core,
  IFilterUncommon
} from "types/IFiltersV3";

export interface IFiltersDataToSort {
  filters: IFiltersV3Core;
  searchParams: Record<string, string>;
}

type FilterKeysForCommonSubFilters = Exclude<
  FilterKeysEnum,
  | FilterKeysEnum.PRICES
  | FilterKeysEnum.PRODUCT_PROPERTIES
  | FilterKeysEnum.PRODUCT_TYPES
  | FilterKeysEnum.GOODS_TYPES
  | FilterKeysEnum.APPLICATION_METHODS
  | FilterKeysEnum.COLOURS
>;

type FilterKeysForUncommonSubFilters = Exclude<
  FilterKeysEnum,
  | FilterKeysEnum.PRICES
  | FilterKeysEnum.PRODUCT_PROPERTIES
  | FilterKeysEnum.BADGES
  | FilterKeysEnum.CATEGORIES
  | FilterKeysEnum.BRANDS
>;

export const sortFiltersBySelected = (
  dataToSort: IFiltersDataToSort
): IFiltersV3Core => {
  const { filters, searchParams } = dataToSort;
  const sortedFiltersBySelected: IFiltersV3Core = { ...filters };
  if (!Object.keys(filters).length) {
    return sortedFiltersBySelected;
  }

  for (const [key, value] of Object.entries(searchParams)) {
    if (key in filters) {
      const selectedFilter = filters[key as FilterKeysEnum];
      if (Array.isArray(selectedFilter) && selectedFilter.length) {
        const paramArrayValue = value.split(",");

        const sortedArray = [...selectedFilter];
        paramArrayValue.forEach((paramValue) => {
          const index = sortedArray.findIndex((item) =>
            "rewrite_name" in item
              ? item.rewrite_name.includes(paramValue)
              : item.rewrite_value.includes(paramValue)
          );

          // нашли совпадение - двигаем фильтр в начало
          if (index !== -1) {
            const [matchedItem] = sortedArray.splice(index, 1); // Убираем фильтр из его положения
            sortedArray.unshift(matchedItem); // добавляем в начало
          }

          // мы заранее знаем что если код дошел до этой стадии то ключи исключаются и тип соответственно будет известен
          if ("rewrite_name" in sortedArray[0]) {
            sortedFiltersBySelected[key as FilterKeysForCommonSubFilters] =
              sortedArray as IFilterCommon[];
          } else {
            sortedFiltersBySelected[key as FilterKeysForUncommonSubFilters] =
              sortedArray as IFilterUncommon[];
          }
        });
      }
    }

    if (key.startsWith("properties[") && filters.product_properties) {
      const productPropertiesKey = key.slice(11, -1);

      const selectedPropertiesFilter = filters.product_properties;
      // при странных запросах может прийти пустой массив скорее всего бэкенд это поправит и проверка тут на массив будет не нужна
      const isSelectedPropertiesArray = Array.isArray(selectedPropertiesFilter);
      if (!isSelectedPropertiesArray && selectedPropertiesFilter) {
        const selectedPropertyFilter =
          selectedPropertiesFilter[productPropertiesKey];

        const propertiesParamArrayValue = value.split(",");

        const sortedPropertyArray = [...selectedPropertyFilter.values];

        propertiesParamArrayValue.forEach((propertyValue) => {
          const index = sortedPropertyArray.findIndex((item) =>
            item.rewrite_name.includes(propertyValue)
          );

          // нашли совпадение - двигаем фильтр в начало
          if (index !== -1) {
            const [matchedItem] = sortedPropertyArray.splice(index, 1); // Убираем фильтр из его положения
            sortedPropertyArray.unshift(matchedItem); // добавляем в начало
          }

          sortedFiltersBySelected[FilterKeysEnum.PRODUCT_PROPERTIES][
            selectedPropertyFilter.rewrite_name
          ].values = sortedPropertyArray;
        });
      }
    }
  }

  return sortedFiltersBySelected;
};
