import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { RedirectToOldContent } from "../RedirectToOldContent/RedirectToOldContent";
import BottomSheet from "../UI/Bottomsheet/Bottomsheet";
import RedirectToOldVersionModal from "../UI/RedirectToOldVersionModal/RedirectToOldVersionModal";


export const ModalRedirect = (props) => {
  const { deviceType } = useSelector((state) => state.user);

  return (
    <>
      {!deviceType.isMobile && props.isOpen && (
        <RedirectToOldVersionModal
          modalRef={props.modalRef}
          onClose={props.onClose}
        >
          <RedirectToOldContent
            url={props.url}
            onClose={props.onClose}
            textForRedirect={props.textForRedirect}
          />
        </RedirectToOldVersionModal>
      )}

      {deviceType.isMobile && <BottomSheet
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <RedirectToOldContent
          url={props.url}
          onClose={props.onClose}
          textForRedirect={props.textForRedirect}
        />
      </BottomSheet>}
    </>
  );
};

const propTypes = {
  modalRef: PropTypes.object,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  url: PropTypes.string,
  textForRedirect: PropTypes.string,
};

ModalRedirect.propTypes = propTypes;

export default ModalRedirect;