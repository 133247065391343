import SelectedFiltersContentV3 from "pages/Catalog/components/CatalogFiltersV3/SelectedFiltersContentV3/SelectedFiltersContentV3";
import styles from "./styles.module.scss";

interface ISelectedFiltersV3Props {
  isMobileDevice?: boolean;
}

const SelectedFiltersV3: React.FC<ISelectedFiltersV3Props> = ({
  isMobileDevice
}) => {
  return (
    <div className={styles.root}>
      <p className={styles.title}>Выбранные фильтры</p>
      <div className={styles["filters-list"]}>
        <SelectedFiltersContentV3 isMobileDevice={isMobileDevice} />
      </div>
    </div>
  );
};

export default SelectedFiltersV3;
