import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";

import Portal from "../../../HOC/Portal";
import ContentFoundCheaper from "../ContentFoundCheaper/ContentFoundCheaper";

const ModalFoundCheaper = (props) => {

  return (
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <div className={styles.modal}>
        <ContentFoundCheaper onClose={props.onClose} modalRef={props.modalRef} />
        <div className={styles.modal__background} />
      </div>
    </Portal>
  );
};

const propTypes = {
  onClose: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
};

ModalFoundCheaper.propTypes = propTypes;

export default ModalFoundCheaper;
