import { FilterKeysEnum } from "types/FilterKeysEnum";

// TODO - добавлять ключи по мере разростания фильтров
export const FiltersLocalization: Record<string, string> = {
  [FilterKeysEnum.BADGES]: "Наши рекомендации",
  [FilterKeysEnum.BRANDS]: "Бренд",
  [FilterKeysEnum.PRODUCT_TYPES]: "Тип продукта",
  [FilterKeysEnum.CATEGORIES]: "Раздел",
  [FilterKeysEnum.GOODS_TYPES]: "Вид товара",
  [FilterKeysEnum.APPLICATION_METHODS]: "Cпособ нанесения",
  [FilterKeysEnum.COLOURS]: "Цвета",
  [FilterKeysEnum.PRICES]: "Цена",
  [FilterKeysEnum.PRODUCT_PROPERTIES]: "Свойства"
};