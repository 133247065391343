import cn from "classnames";
import styles from "./styles.module.scss";

interface IOrderStringItemProps {
  title: string;
  subtitle: string;
  contanerClassname?: string;
  titleClassname?: string;
  subtitleClassname?: string;
}

const OrderStringItem: React.FC<IOrderStringItemProps> = (props) => {
  return (
    <p className={cn(styles.root, props.contanerClassname)}>
      <span className={cn(styles.title, props.titleClassname)}>
        {props.title}
      </span>
      <span className={cn(styles.subtitle, props.subtitleClassname)}>
        {props.subtitle}
      </span>
    </p>
  );
};

export default OrderStringItem;
