import { Link } from "react-router-dom";
import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";
import { getCanonicalUrlForInfoPage } from "utils/helpers/getCanonicalUrl";

const HowBuy = () => {

  const howBuyData = pageInfo.howToOrder;
  const canonicalUrl = getCanonicalUrlForInfoPage(window.location.href);

  return (
    <InfoPage metaData={howBuyData} canonicalUrl={canonicalUrl}>
      <h1 className={styles.seo}>Инфо страница Как заказать</h1>
      <SectionTitle title="Как заказать" containerStyles={styles["custom-title"]}/>
      <div className={styles.root}>
        <p className={styles.text}>
          <p>
            Для осуществления заказа Вам необходимо поместить выбранные товары в корзину и перейти к оформлению.
            Если Вы делаете заказ впервые, Вам будет предложено зарегистрироваться на
            <Link
              data-cke-saved-href="https://parfumart.ru/"
              to="/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item_link}
            > Parfumart.ru
            </Link> Обращаем Ваше
            внимание на то, что регистрация <span className={styles["item_pink"]}>НЕ</span> является обязательным условием для осуществления заказа, а выступает
            лишь в качестве вспомогательного сервиса, который хранит Вашу личную информацию. То есть, Вам не нужно
            каждый раз вводить контактные данные при осуществлении заказа, система автоматически запомнит их
            относительно Ваших логина и пароля. К тому же, в личном кабинете Вам всегда будет доступна информация
            о заказах, сделанных ранее, с отображением информации о виде, количестве и стоимости ранее приобретённых
            товаров.
          </p>
          <p>
            В личном кабинете Вы можете отслеживать возможность получения скидки в нашем
            интернет-магазине. Подробнее о скидках Вы можете узнать в подразделе
            <Link
              data-cke-saved-href="https://parfumart.ru/info/sistema_skidok/"
              to="/info/sistema-skidok"
              target="_blank"
              rel="noopener"
              className={styles.text_link}
            >
              &quot;Система скидок&quot;
            </Link>.
          </p>
        </p>
        <p className={styles.text}>
          <h3 className={styles.title}>Дополнительные возможности личного кабинета:</h3>
          <ul className={styles.text__list}>
            <li>Отслеживание статуса и даты выполнения своего заказа;</li>
            <li>Продления сроков резерва сделанного заказа;</li>
            <li>Смена способа оплаты, с возможностью оплаты из личного кабинета;</li>
            <li>Создание списка избранных товаров;</li>
          </ul>
          <p className={styles.text__line_break}>
            При оформлении заказа без регистрации, Вам необходимо лишь внести данные в обязательные для заполнения поля,
            которые отмечены красной звездочкой. Тем самым Вы подтверждаете свое согласие на использование Вашей личной
            информации для осуществления нами заказа.
          </p>
        </p>
        <p className={styles.text}>
          <p className={styles.text__line_break}>
            После введения всех необходимых данных, выбора условий доставки и оплаты, Вам нужно нажать кнопку <span className={styles["item_pink"]}>&quot;Оплатить&quot;. </span>
            При выборе оплаты наличными Ваш заказ будет окончательно сформирован, а на указанный адрес электронной почты будет выслано письмо,
            в котором будет продублирован Ваш заказ с присвоенным оригинальным номером.
            При выборе оплаты заказа онлайн, Вы будете перенаправлены на платежный терминал <span className={styles["item_pink"]}>ООО НКО «Юмани»</span>,
            где сможете ввести данные карты и произвести оплату. Подробное описание условий
            оплаты тем или иным способом доступно в подразделе
            <Link
              data-cke-saved-href="https://parfumart.ru/info/oplata/"
              to="/info/oplata"
              target="_blank"
              rel="noopener"
              className={styles.text_link}
            >
            &quot;Оплата&quot;
            </Link>.
          </p>
        </p>
        <p className={styles.text}>
          <p>
          В кратчайшие сроки после оформления заказа с Вами свяжется наш сотрудник.
          </p>
        </p>
        <p className={styles.text}>
          <p className={styles.text__line_break}>
          Также Вы можете сделать заказ по телефону. Вам нужно просто позвонить по указанным на
          сайте номерам телефона и сообщить менеджеру артикул товара, который Вы хотите заказать,
          а также его дополнительные характеристики (количество, объем и т.п.). Менеджер зарезервирует
          товар на нашем складе, после чего подтвердит Ваш заказ и согласует с Вами условия оплаты
          и срок получения заказа.
          </p>
        </p>
        <p className={styles.text}>
          Спасибо Вам, что стали нашим клиентом!
        </p>
      </div>
    </InfoPage>
  );
};

export default HowBuy;