import { useEffect, useRef, useState } from "react";

import CustomSlider from "../../../../components/CustomSlider";
import ProductCard from "../../../../components/ProductCard";
import SectionTitle from "../../../../components/SectionTitle";

import styles from "./styles.module.scss";

const sliderSettings = {
  infinite: true,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  lazyLoad: true,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  arrows: false,
};

const LastProductsViewBlock = () => {
  const [products, setProducts] = useState([]);

  const sliderRef = useRef(null);

  const onClickSlickPrev = () => {
    sliderRef?.current?.slickPrev();
  };

  const onClickSlickNext = () => {
    sliderRef?.current?.slickNext();
  };

  useEffect(() => {
    const productsFromStorage = localStorage.getItem("lastProductsView");
    if (!productsFromStorage) return;

    setProducts(productsFromStorage);
  }, []);

  return (
    !!products.length &&
      <div className={styles.root}>
        <SectionTitle title='НЕДАВНО ПРОСМОТРЕННЫЕ' />
        <CustomSlider
          products={products}
          sliderRef={sliderRef}
          onClickSlickPrev={onClickSlickPrev}
          onClickSlickNext={onClickSlickNext}
          settings={sliderSettings}
        >
          {
            products.map((product) => (
              <ProductCard product={product} key={product.id} />
            ))
          }
        </CustomSlider>
      </div>
  );
};

export default LastProductsViewBlock;