import { CustomExpandingText } from "../../../../../../../../../components/CustomExpandingText/CustomExpandingText";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";


export const AboutBrand = (props) => {
  return (
    <>
      {props.brandDescription ?
        <div className={styles.description}>
          <CustomExpandingText text={props.brandDescription} />
        </div>
        :
        <div className={styles["description-empty"]}>
          <h2>Нет описания</h2>
        </div>
      }
    </>
  );
};

const propTypes = {
  brandDescription: PropTypes.string,
};

AboutBrand.propTypes = propTypes;