
import { SkeletonLine } from "../SkeletonLine/SkeletonLine";
import styles from "./styles.module.scss";
import Cosmetics from "../../../../assets/icons/svg/cosmetics.svg";

export const SkeletonCard = () => {
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <div className={styles.card__labels}>
          <SkeletonLine style={{ width: "60px" }} />
        </div>
        <span className={styles["card_img-wrapper"]}>
          <img
            src={Cosmetics}
            alt={"Loading img"}
            className={styles["card_img-wrapper-img"]}
          />
        </span>
        <div className={styles.card__labels}>
          <SkeletonLine style={{ width: "100px" }} />
        </div>
        <SkeletonLine style={{ width: "80px" }} />
        <SkeletonLine style={{ width: "120px" }} />
        <SkeletonLine style={{ width: "110px" }} />

        <div>
          <SkeletonLine style={{ width: "40px" }} />
        </div>
      </div>
    </div>
  );
};
