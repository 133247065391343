/* eslint-disable no-unused-vars */
import {
  ProvidersInfo,
  ToDoorDetail,
  ToPointDetail
} from "types/IProviderInfo";
import { PROVIDERS } from "./constants";

export const getAvailableProviders = (providersInfo: ProvidersInfo | null) => {
  if (!providersInfo) {
    return [];
  }

  const toDoorProviders = providersInfo.toDoor?.map((item) => {
    return item.deliveryProvider;
  });

  const toPointProviders = providersInfo.toPoint?.map((item) => {
    return item.deliveryProvider;
  });

  const allProviders = [...toDoorProviders, ...toPointProviders];
  const availableProviders = Array.from(new Set(allProviders));
  return availableProviders;
};

export const getProviderWithResultTitle = ({
  providersInfo
}: {
  providersInfo: ToDoorDetail | ToPointDetail;
}): string => {
  const providerTitle = PROVIDERS[providersInfo.deliveryProvider].title;
  const price =
    providersInfo.deliveryCost > 0
      ? `от ${providersInfo.deliveryCost}₽`
      : "- бесплатно";
  const daysAmount = `(${getDaysAmountString({
    daysMin: providersInfo.daysMin,
    daysMax: providersInfo.daysMax
  })})`;

  return `${providerTitle} ${price} ${daysAmount}`;
};

export const getAvailableProvidersWithResultTitle = (
  data: ProvidersInfo | null
): ProvidersInfo => {
  if (!data) {
    return {
      toDoor: [],
      toPoint: []
    };
  }
  return {
    toDoor: data.toDoor?.map((item) => {
      return {
        ...item,
        resultTitle: getProviderWithResultTitle({
          providersInfo: item
        })
      };
    }),
    toPoint: data.toPoint?.map((item) => {
      return {
        ...item,
        resultTitle: getProviderWithResultTitle({
          providersInfo: item
        })
      };
    })
  };
};

const getDaysAmountString = ({
  daysMin,
  daysMax
}: {
  daysMin: number;
  daysMax: number;
}): string => {
  const correctDayForm = getCorrectDayForm(daysMax);
  if (daysMax === daysMin) {
    return `~ ${daysMax} ${correctDayForm}`;
  }
  return `${daysMin} - ${daysMax} ${correctDayForm}`;
};

const getCorrectDayForm = (number: number): string => {
  const titles = ["день", "дня", "дней"];
  const cases = [2, 0, 1, 1, 1, 2];
  const condition = number % 100 > 4 && number % 100 < 20;

  const currentIdx = condition ? 2 : cases[number % 10 < 5 ? number % 10 : 5];
  return `${titles[currentIdx]}`;
};
