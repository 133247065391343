import plusIcon from "../../../../assets/icons/svg/plus-icon.svg";

import styles from "./styles.module.scss";

const SkeletonFiltersV3: React.FC = () => {

  return (
    <>
      {Array.from({ length: 10 }, (_, index) => (
        <li className={styles["filters-skeleton"]} key={index}>
          <div className={styles.wrapper}>
            <div className={styles.skeleton}/>
            <img
              src={plusIcon}
              alt=""
              className={styles.icon}
            />
          </div>
        </li>
      ))}
    </>
  );
};

export default SkeletonFiltersV3;