import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { toast } from "react-toastify";

import { deliveryApi } from "../../api/deliveryApi";
import useDebounce from "./useDebounce";
import {
  setDeliveryCoordinates,
  setOrderCity,
  setUserDeliveryAddress
} from "../../store/user/user";
import { getCityBySearchAddress } from "../ymapsUtils";
import { CustomError, getErrorData } from "utils/getErrorData";
import { IGetDeliveryAddressRequest } from "types/IGetDeliveryAddress";

interface IUseDeliveryProps {
  searchValue: string;
  isFocusInput?: boolean;
  setSearchValue?: (value: string) => void;
  city?: string;
}

const useDelivery = (options: IUseDeliveryProps) => {
  const { currentCity, checkoutData } = useAppSelector((state) => state.user);
  const [deliveryAddresses, setDeliveryAddresses] = useState<string[]>([]);
  const { searchValue, isFocusInput, setSearchValue, city } = options;
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressesNotFound, setIsAddressesNotFound] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isFocusInput || searchValue.length < 3) return;
    (async () => {
      try {
        setIsLoading(true);
        const options: IGetDeliveryAddressRequest = {
          query: searchValue,
          city: city || checkoutData.orderCity || currentCity
        };
        const data = await deliveryApi.getDeliveryAddress(options);
        if ("message" in data) {
          const errorData = getErrorData(data);
          setDeliveryAddresses([]);
          setIsAddressesNotFound(true);
          throw new Error(errorData.message);
        }

        if (!data.response.items.length) {
          setDeliveryAddresses([]);
          setIsAddressesNotFound(true);
          throw new Error("Ни одного адреса по запросу не было найдено");
        }
        const addresses = data.response.items.map((item) => item.value);
        setIsAddressesNotFound(false);
        setDeliveryAddresses(addresses);
      } catch (err) {
        setDeliveryAddresses([]);
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(errorData.message);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocusInput, debouncedValue, city]);

  const setDeliveryAddressByPosition = async (): Promise<void> => {
    if (!navigator.geolocation) {
      toast("Не можем определить ваше местоположение");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position: GeolocationPosition) => {
        try {
          const options: IGetDeliveryAddressRequest = {
            query: "",
            city: "",
            lat: position.coords.latitude,
            lon: position.coords.longitude
          };
          dispatch(
            setDeliveryCoordinates([
              position.coords.latitude,
              position.coords.longitude
            ])
          );

          const data = await deliveryApi.getDeliveryAddress(options);

          if ("message" in data) {
            const errorData = getErrorData(data);
            setDeliveryAddresses([]);
            setIsAddressesNotFound(true);
            throw new Error(errorData.message);
          }


          if (!data.response.items.length) {
            setDeliveryAddresses([]);
            setIsAddressesNotFound(true);
            throw new Error("Ни одного адреса по координатам не было найдено");
          }

          const firstAddress = data.response.items[0].value;

          setDeliveryAddresses(data.response.items.map((item) => item.value));
          dispatch(setUserDeliveryAddress(firstAddress));
          setSearchValue(firstAddress);
          try {
            const [city] = await getCityBySearchAddress(firstAddress);
            if (city) {
              dispatch(setOrderCity(city));
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error("Произошла ошибка при поиске города:", err);
            throw new CustomError("Произошла ошибка при поиске города");
          }
        } catch (err) {
          const errorData = getErrorData(err);
          // eslint-disable-next-line no-console
          console.error("Произошла ошибка при поиске города:", err);
          toast.error(errorData.message);
        }
      },
      (error) => {
        switch (error.code) {
        case error.PERMISSION_DENIED:
          toast("Необходимо дать разрешение на определение местоположения");
          break;
        case error.POSITION_UNAVAILABLE:
          toast("Не можем определить ваше местоположение");
          break;
        case error.TIMEOUT:
          toast("Не можем определить ваше местоположение");
          break;
        default:
          toast("Не можем определить ваше местоположение");
          break;
        }
      }
    );
  };

  return {
    deliveryAddresses,
    setDeliveryAddressByPosition,
    isLoading,
    isAddressesNotFound
  };
};

export default useDelivery;
