import { useEffect, useState } from "react";
import BigModal from "../../../../../components/UI/BigModal/BigModal";
import ProvidersListWithAddresses from "../ProvidersListWithAddresses/ProvidersListWithAddresses";
import cn from "classnames";
import styles from "./styles.module.scss";
import SelectedAddressFromBallonBlock from "../SelectedAddressFromBallonBlock/SelectedAddressFromBallonBlock";
import SelectAddressForOrder from "../MainSelectProviderBlock/MainSelectProviderBlock";
import { useAppSelector } from "store/reduxHooks";
import {
  ProvidersInfo,
  SelectedProvider,
  SelectedProviderData
} from "types/IProviderInfo";
import { IChosenProvider } from "utils/hooks/useYandexMap";

interface ISelectProviderModalProps {
  modalRef: React.RefObject<HTMLDivElement>;
  selectedProviderData: SelectedProviderData | null;
  showProviderInfo: boolean;
  providersInfo: ProvidersInfo | null;
  selectedProvider: SelectedProvider;
  currentProviders: IChosenProvider[];
  onCloseModal: () => void;
  onToggleShowProviderInfo: (value: boolean) => void;
  onSelectProvider: (provider: IChosenProvider | null) => void;
  changeSelectedProviderData: (value: SelectedProviderData | null) => void;
  setCity: (city: string) => Promise<void>;
  setSelectedProvider: (
    data: SelectedProvider | ((prevState: SelectedProvider) => SelectedProvider)
  ) => void;
  chosenProvider: IChosenProvider | null;
}

const SelectProviderModal: React.FC<ISelectProviderModalProps> = (props) => {
  const { checkoutData } = useAppSelector((state) => state.user);
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [showModal, setShowModal] = useState("PVZList");

  const onCloseModalHandler = () => {
    props.onToggleShowProviderInfo(false);
    props.onCloseModal();
  };

  useEffect(() => {
    if (props.showProviderInfo) setShowModal("ProviderInfo");
  }, [props.showProviderInfo]);

  useEffect(() => {
    if (checkoutData.wayToGet === "Курьером") setShowModal("ToDoor");
    if (checkoutData.wayToGet === "Самовывоз") setShowModal("PVZList");
  }, [checkoutData.wayToGet]);

  const closeProviderInfo = () => {
    setShowModal("PVZList");
    props.onToggleShowProviderInfo(false);
  };

  const closeModal = () => {
    closeProviderInfo();
    props.onCloseModal();
    setIsMapVisible(false);
  };

  return (
    <BigModal modalRef={props.modalRef} onClose={onCloseModalHandler}>
      <div className={styles.root}>
        <div className={styles.content}>
          {showModal === "PVZList" && (
            <ProvidersListWithAddresses
              selectedProvider={props.selectedProvider}
              setSelectedProvider={props.setSelectedProvider}
              providersInfo={props.providersInfo}
              changeSelectedProviderData={props.changeSelectedProviderData}
              onToggleShowProviderInfo={props.onToggleShowProviderInfo}
              setCity={props.setCity}
            />
          )}
          {showModal === "ProviderInfo" && (
            <SelectedAddressFromBallonBlock
              providersInfo={props.providersInfo}
              selectedProviderData={props.selectedProviderData}
              onCloseModal={closeModal}
              returnToPvzSelect={closeProviderInfo}
            />
          )}
          {showModal === "ToDoor" && (
            <SelectAddressForOrder
              currentProviders={props.currentProviders}
              providersInfo={props.providersInfo}
              onSelectProvider={props.onSelectProvider}
              isMapVisible={isMapVisible}
              onCloseModal={closeModal}
              chosenProvider={props.chosenProvider}
              setCity={props.setCity}
            />
          )}
          <div
            id="map"
            className={cn(styles.map, {
              [styles["map--hidden"]]: !isMapVisible
            })}
          />
        </div>
      </div>
    </BigModal>
  );
};

export default SelectProviderModal;
