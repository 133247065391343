import { useSelector } from "react-redux";
import StoriesBlock from "./components/StoriesBlock";
import DiscountsBlock from "./components/DiscountsBlock";
import PopularBlock from "./components/PopularBlock";
import TrendsBlock from "./components/TrendsBlock";
import ProfitBlock from "./components/ProfitBlock";
import HitsBlock from "./components/HitsBlock";
import ShoppingAdvantageBlock from "./components/ShoppingAdvantageBlock";
import Reviews from "./components/Reviews";
import AboutUsBlock from "./components/AboutUsBlock";
import OurNews from "./components/OurNews";

import { HeadHelmet } from "../../utils";

import styles from "./styles.module.scss";
import SliderMainBanner from "components/SliderMainBanner/SliderMainBanner";
import { useAppSelector } from "store/reduxHooks";

const MainPage = () => {
  const { deviceType } = useSelector((state) => state.user);
  const mataTitle = "Оригинальная парфюмерия и косметика Chanel, Dior, Guerlain | Parfumart.ru";
  const banners = useAppSelector((state) => state.banners);
  const isSliderPresent = banners.banners.length > 0;

  return (
    <>
      <HeadHelmet title={mataTitle} />
      <h1 className={styles.seo}>
      Parfumart.ru: оригинальная парфюмерия и косметика с доставкой по России
      </h1>
      <main className={styles.root}>
        <SliderMainBanner />
        {!isSliderPresent && deviceType.isMobile && (
          <div className={styles.placeholder}></div>
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_STORIES !== "false" && (
          <StoriesBlock />
        )}
        <DiscountsBlock />
        <PopularBlock />
        {!deviceType.isMobile && <ProfitBlock />}
        <HitsBlock />
        <ShoppingAdvantageBlock />
        <TrendsBlock />
        {process.env.REACT_APP_COMPONENT_ENABLE_ABOUT_US !== "false" && (
          <AboutUsBlock />
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_OUR_NEWS !== "false" && (
          <OurNews />
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_CUSTOMER_FEEDBACK_MAIN_PAGE !==
          "false" && <Reviews />}
      </main>

    </>
  );
};

export default MainPage;
