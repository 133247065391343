import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const ShoppingAdvantageItem = (props) => {
  return (
    <li className={cn(styles.root, props.containerStyles)}>
      <div className={styles["logo-wrapper"]}>
        <img src={props.imgSrc} alt={props.imgAlt} className={styles.logo} />
      </div>
      <h3 className={styles.title}>{props.title}</h3>
      <p className={styles.description}>{props.description}</p>
    </li>
  );
};

const propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
};

ShoppingAdvantageItem.propTypes = propTypes;

export default ShoppingAdvantageItem;
