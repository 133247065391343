import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import cn from "classnames";

import CustomScrollbar from "../../../../../../../components/CustomScrollbar/CustomScrollbar";
import CheckoutOrderItem from "../../../UserOrderData/components/CheckoutOrderItem/CheckoutOrderItem";

import styles from "./styles.module.scss";
import usePayment from "../../../../../../../utils/hooks/usePayment";
import { ICreateNewOrderAuthorizedUsersResponse } from "types/ICreateNewOrderAuthorizedUsers";
import { ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";
import { useAppSelector } from "store/reduxHooks";

const MAX_DISPLAY_PRODUCTS = 3;

interface IPayOrderOrderedProductsBlock {
  orderData:
    | ICreateNewOrderAuthorizedUsersResponse
    | ICreateNewOrderNonAuthorizedUsersResponse
    | null;
  onOrderCreated: () => void;
}

const PayOrderOrderedProductsBlock: React.FC<IPayOrderOrderedProductsBlock> = (
  props
) => {
  const { checkoutData } = useAppSelector((state) => state.user);
  const { clearPaidItemsFromCart } = usePayment({
    orderData: props.orderData,
    onOrderCreated: props.onOrderCreated
  });

  const handleContinueShopping = () => {
    clearPaidItemsFromCart();
  };

  const [showShadow, setShowShadow] = useState({
    top: false,
    bottom: false
  });

  const onShowScrollBarShadows = ({
    showTopShadow,
    showBottomShadow
  }: {
    showTopShadow: boolean;
    showBottomShadow: boolean;
  }) => {
    setShowShadow({
      top: showTopShadow,
      bottom: showBottomShadow
    });
  };

  const isShowScrollbar = useMemo(() => {
    return checkoutData.items.length > MAX_DISPLAY_PRODUCTS;
  }, [checkoutData.items]);

  useEffect(() => {
    setShowShadow((prev) => {
      return {
        ...prev,
        bottom: checkoutData.items.length > MAX_DISPLAY_PRODUCTS
      };
    });
  }, [checkoutData.items.length]);

  return (
    <div className={styles["ordered-products"]}>
      <div className={styles["ordered-products__wrapper"]}>
        <div className={styles["ordered-products__title-wrapper"]}>
          <h1 className={styles["ordered-products__title"]}>Товары</h1>
        </div>
        <div
          className={cn(
            styles.wrapper,
            { [styles["products-wrapper--top-shadow"]]: showShadow.top },
            { [styles["products-wrapper--bottom-shadow"]]: showShadow.bottom }
          )}
        >
          <div className={cn(styles["products-wrapper"])}>
            <CustomScrollbar
              childrenClassName={styles["products"]}
              scrollBarTrackStyles={styles.track}
              isShowScrollbar={isShowScrollbar}
              onShowScrollBarShadows={onShowScrollBarShadows}
            >
              {!props.orderData.orderOffers.length ? (
                <h3 className={styles["products__title"]}>
                  Нет товаров для оформления
                </h3>
              ) : (
                props.orderData.orderOffers.map((item) => {
                  return (
                    <CheckoutOrderItem
                      key={item.offer.id}
                      item={item}
                      rootStyles={styles["order-item"]}
                      imageStyles={styles["order-item__image"]}
                    />
                  );
                })
              )}
            </CustomScrollbar>
          </div>
        </div>
        <div className={styles.line} />
        <Link
          onClick={handleContinueShopping}
          to="/catalog"
          className={styles.link}
        >
          Продолжить покупки
        </Link>
      </div>
    </div>
  );
};

export default PayOrderOrderedProductsBlock;
