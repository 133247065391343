import styles from "./styles.module.scss";
import CustomButton from "../CustomButton/CustomButton";
import { memo, useEffect, useState } from "react";
import { brandsApi } from "../../api/brands";
import {
  ARR_NUMBERS,
  CYRILLIC_ALPHABET
} from "../../utils/constants/arrAlphabets";
import LettersRow from "../BrandsByLetter/BrandsByLetterContent/components/LettersRow/LettersRow";
import { NavLink } from "react-router-dom";
import { PropTypes } from "prop-types";
import { toggleSearchTrigger } from "../../store/catalog";
import { useDispatch } from "react-redux";

const BrandsMobile = (props) => {
  const [brands, setBrands] = useState({});
  const [activeFilter, setActiveFilter] = useState("all");
  const [selectedLetter, setSelectedLetter] = useState("");
  const collator = new Intl.Collator("ru");
  const dispatch = useDispatch();

  const getBrands = (response) => {
    const items = [];

    if (typeof response === "object") {
      for (const key in response) {
        items.push(...response[key]);
      }
    }

    return items;
  };

  const brandsSort = (brands, letter) => {
    const brandsFilter = brands.reduce((previous, current) => {
      if (
        !previous.filter(
          (item) => item.brand["rewrite_name"] === current.brand["rewrite_name"]
        )[0]
      ) {
        previous.push(current);
      }
      return previous;
    }, []);

    if (letter === "А-Я")
      return brandsFilter.sort((a, b) =>
        collator.compare(a.brand.name, b.brand.name)
      );
    if (letter === "0-9")
      return brandsFilter.sort((a, b) =>
        a.brand.name > b.brand.name ? 1 : -1
      );
    return brandsFilter.sort((a, b) => {
      const aBrand = a.brand.name.toLowerCase().replace(/[^a-zA-Z]/gi, "");
      const bBrand = b.brand.name.toLowerCase().replace(/[^a-zA-Z]/gi, "");

      if (aBrand > bBrand) return 1;
      if (aBrand < bBrand) return -1;
      return 0;
    });
  };

  const brandsByLetters = async (letters, letter) => {
    try {
      const res = await brandsApi.getBrandsByLetters(letters);
      if (!res.response) return;
      const kosmetika = getBrands(res.response.kosmetika);
      const parfumeria = getBrands(res.response.parfumeria);
      const allBrands = brandsSort([...kosmetika, ...parfumeria], letter);

      setBrands({
        all: allBrands,
        kosmetika,
        parfumeria
      });
    } catch (err) {
      // TODO
      // eslint-disable-next-line no-console
      console.error("Произошла ошибка получение брендов по буквам (мобильный)", err);
    }
  };

  const onSelectLetter = async (letter) => {
    if (letter === selectedLetter) return;

    setSelectedLetter(letter);

    if (letter === "А-Я") return brandsByLetters(CYRILLIC_ALPHABET, letter);
    if (letter === "0-9") return brandsByLetters(ARR_NUMBERS, letter);

    brandsByLetters([letter], letter);
  };

  const handlerClickLink = () => {
    dispatch(toggleSearchTrigger());

    props.onClose();
  };

  useEffect(() => {
    onSelectLetter("A");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.root}>
        <div className={styles["button-wrapper"]}>
          <CustomButton
            className={styles["button-wrapper-button"]}
            title="Все"
            isBlack={activeFilter === "all"}
            isWhite={!(activeFilter === "all")}
            onClick={() => setActiveFilter("all")}
          />
          <CustomButton
            className={styles["button-wrapper-button"]}
            title="Парфюмерия"
            isBlack={activeFilter === "parfumeria"}
            isWhite={!(activeFilter === "parfumeria")}
            onClick={() => setActiveFilter("parfumeria")}
          />
          <CustomButton
            className={styles["button-wrapper-button"]}
            title="Косметика"
            isBlack={activeFilter === "kosmetika"}
            isWhite={!(activeFilter === "kosmetika")}
            onClick={() => setActiveFilter("kosmetika")}
          />
        </div>
        <div className={styles["search-wrapper"]}>
          <p className={styles["search-wrapper_letter"]}>{selectedLetter}</p>
        </div>
        <div className={styles["letter-wrapper"]}>
          <LettersRow
            selectedLetter={selectedLetter}
            onSelectLetter={onSelectLetter}
            classNameRoot={styles["letter-wrapper_root"]}
            classNameItem={styles["letter-wrapper_root-item"]}
          />
        </div>
        <div className={styles["brands-wrapper"]}>
          {brands[activeFilter]?.map((item, idx) => {
            const link =
              activeFilter === "all"
                ? `/catalog?brands=${item.brand.rewrite_name}`
                : `/catalog/${activeFilter}/?brands=${item.brand.rewrite_name}`;

            return (
              <NavLink
                key={`${item.brand.rewrite_name}_${idx}`}
                to={link}
                className={styles["brands-wrapper_link"]}
                onClick={handlerClickLink}
              >
                {item.brand.name}
              </NavLink>
            );
          })}
        </div>
      </div>
    </>
  );
};

BrandsMobile.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default memo(BrandsMobile);
