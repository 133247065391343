
import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const CustomExternalLink = (props) => {
  return (
    <a
      href={props.path}
      className={cn(
        props.isPrimary ? styles.primary : styles.secondary,
        { [styles.white]: props.isWhite },
        { [styles.black]: props.isBlack },
        props.className
      )}
    >
      {props.title}
    </a>
  );
};

const propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
  isWhite: PropTypes.bool,
  isBlack: PropTypes.bool,
  className: PropTypes.string,
};

CustomExternalLink.propTypes = propTypes;

export default CustomExternalLink;