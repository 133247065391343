import forChildsIcon from "../../assets/icons/popular_block-icons/for_childs-icon.png";
import forMensIcon from "../../assets/icons/popular_block-icons/for_mens-icon.png";
import makeupIcon from "../../assets/icons/popular_block-icons/makeup-icon.png";
import selectiveIcon from "../../assets/icons/popular_block-icons/selective_perfumery-icon.png";

export default {
  forChildsIcon,
  forMensIcon,
  makeupIcon,
  selectiveIcon
};