import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const ProductCardLabel = (props) => {
  const { deviceType } = useSelector((state) => state.user);

  return (
    <div className={cn(styles.root, props.className)}>
      {props.imgSrc && <img src={props.imgSrc} alt={props.imgAlt} className={props.iconStyle} />}
      {(!deviceType.isMobile || props.shouldShowTitle) && (
        <span className={styles.title}>{props.title}</span>
      )}
    </div>
  );
};

const propTypes = {
  iconStyle: PropTypes.string,
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string.isRequired,
  className: PropTypes.string,
  shouldShowTitle: PropTypes.bool,
};

ProductCardLabel.propTypes = propTypes;

export default ProductCardLabel;
