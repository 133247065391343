const TOKEN_KEY = "token=";
const REFRESH_TOKEN_KEY = "&refresh_token=";
const SECRET_KEY = "&secret_key=";

const getUserTokensFromRedirectQuery = (searchStr) => {
  const tokenIdx = searchStr.indexOf(TOKEN_KEY);
  const refreshTokenIdx = searchStr.indexOf(REFRESH_TOKEN_KEY);
  const secretKeyIdx = searchStr.indexOf(SECRET_KEY);

  const token = searchStr.slice(tokenIdx + TOKEN_KEY.length, refreshTokenIdx);
  const refresh = searchStr.slice(refreshTokenIdx + REFRESH_TOKEN_KEY.length, secretKeyIdx);
  const secretKey = searchStr.slice(secretKeyIdx + SECRET_KEY.length);

  return {
    token,
    refreshToken: {
      token: refresh,
      key: secretKey,
    }
  };
};

export default getUserTokensFromRedirectQuery;