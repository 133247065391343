import PropTypes from "prop-types";

import CustomButton from "../CustomButton";

import cn from "classnames";
import styles from "./styles.module.scss";
import { setUserCityStorage } from "../../utils/localStorage";

const CitiesSuggestionContent = props => {
  const saveCity = () => {
    setUserCityStorage(props.cityTitle);
    props.onClose();
  };

  return (
    <div
      className={cn(styles.root, props.containerStyles, {
        [styles["root--shadow-disabled"]]: props.disableShadow,
      })}
      ref={props.contentRef}
    >
      <p className={styles.header}>
        <span className={styles.header_title}>Ваш населённый пункт:</span>
        <span className={styles["header_city-title"]}>{props.cityTitle}?</span>
      </p>
      <div className={styles.buttons}>
        <CustomButton title='Да, верно' onClick={() => saveCity()} />
        <CustomButton
          title='Нет, другой'
          isWhite
          onClick={props.onShowSelectCities}
        />
      </div>
    </div>
  );
};

const propTypes = {
  cityTitle: PropTypes.string,
  containerStyles: PropTypes.string,
  contentRef: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onShowSelectCities: PropTypes.func.isRequired,
  disableShadow: PropTypes.bool,
};

CitiesSuggestionContent.propTypes = propTypes;

export default CitiesSuggestionContent;
