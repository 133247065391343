import PropTypes from "prop-types";

import arrow from "../../assets/icons/png/arrow.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const CustomArrowButton = (props) => {
  return (
    <button className={props.className}>
      <img
        src={arrow}
        alt="arrow-icon"
        className={cn(
          styles.arrow,
          styles[`arrow--${props.duration}`],
        )}
        onClick={props.onClick}
        draggable="false"
      />
    </button>
  );
};

const propTypes = {
  duration: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

CustomArrowButton.propTypes = propTypes;

export default CustomArrowButton;