import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import useOutsideClick from "../../../../utils/hooks/useOutsideClick";

import ScheduleInnerContent from "../../../../components/ScheduleInnerContent/ScheduleInnerContent";
import sheduleIcon from "../../../../assets/header_icons/schedule-icon.svg";

import styles from "./styles.module.scss";


const HeaderScheduleBlock = () => {
  const [openModal, setOpenModal] = useState(false);

  const blockRef = useRef(null);

  const onClickOpenModal = () => {
    setOpenModal(!openModal);
  };

  useOutsideClick({
    ref: blockRef,
    cb: () => setOpenModal(false),
  });

  return (
    <li ref={blockRef} className={styles.root}>
      <button className={styles.icon} onClick={onClickOpenModal}>
        <img src={sheduleIcon} alt="shedule-icon" />
      </button>
      <CSSTransition
        in={openModal}
        timeout={100}
        classNames={{
          enterDone: styles["schedule-enter-done"],
        }}
        unmountOnExit
      >
        <ScheduleInnerContent containerStyles={styles.schedule} />
      </CSSTransition>
    </li>
  );
};

export default HeaderScheduleBlock;