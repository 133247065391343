import React from "react";

const useDidUpdate = (cb, deps) => {
  const isInit = React.useRef(true);

  React.useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    cb();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export default useDidUpdate;