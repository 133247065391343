import { useEffect, useState } from "react";
import { productsApi } from "../../../../api/productsApi";
import ProfitProductCard from "./ProfitProductCard";

import SectionTitle from "../../../../components/SectionTitle";

import styles from "./styles.module.scss";

const ProfitBlock = () => {
  const [profitData, setProfitData] = useState([]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const data = await productsApi.getDataForProfitSection();

        if (data.meta.error) {
          setIsError(true);
          return;
        }

        const products = data.response?.items[0].saleBlockProducts.map((item) => {
          return item.product;
        });

        setProfitData({
          ...data.response?.items[0],
          saleBlockProducts: products,
        });
      } catch (err) {
        // TODO
      }
    })();
  }, []);

  if (isError) {
    return null;
  }

  return (
    <section className={styles.root}>
      <SectionTitle title={profitData.title ?? ""} />
      <div className={styles["profit-wrapper"]}>
        <div className={styles["profit-list"]}>
          {
            profitData?.saleBlockProducts?.map((product) => (
              <ProfitProductCard
                key={product.id}
                productId={product.id}
                offers={product.offers}
                name={product.name}
                productRewriteName={product.product_rewrite_name}
                reducedImage={product.reducedImage}
              />
            ))
          }
        </div>
        <div className={styles["profit-banner_wrapper"]}>
          <img
            src={profitData?.banner?.link}
            alt="banner-image"
            className={styles["profit-banner"]}
          />
        </div>
      </div>
    </section>
  );
};

export default ProfitBlock;