import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch } from "store/reduxHooks";
import { toast } from "react-toastify";

import { addDeliveryAdress } from "../../../../../store/user/userThunks/userThunks";
import { adressSchema } from "../../../../../schemas/adressSchema";
import { getIsEqual } from "../../../../../utils/getIsEqual";
import useCitiesSearch from "../../../../../utils/hooks/useCitiesSearch";
import useDelivery from "../../../../../utils/hooks/useDelivery";

import CustomNotification from "../../../../../components/CustomNotification/CustomNotification";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import DeleteAdressModal from "./DeleteAdressModal/DeleteAdressModal";

import cn from "classnames";
import styles from "./styles.module.scss";
import { formatStreetAddress } from "../../../../../utils/helpers/formatedAddresses";
import { IDeliveryAdress } from "types/IDeliveryAdressAction";
import {
  IEmptyAddress,
  isEmptyAddress
} from "pages/PersonalArea/components/PersonalData/PersonalData";
import { IRequestAddDeliveryAddressThunk } from "types/IAddDeliveryAddressThunk";
import { getErrorData } from "utils/getErrorData";

interface IAdressBlockProps {
  index: number;
  adressAmount: number;
  adressData: IDeliveryAdress | IEmptyAddress;
  onClickEmptyAdressAction: (type: string, idx: number) => void;
  setUserAdress: React.Dispatch<
    React.SetStateAction<(IDeliveryAdress | IEmptyAddress)[]>
  >;
}

interface IAddressValue {
  id: number;
  name: string;
  placeholder: string;
  inputType: string;
}

const ADRESS_VALUES: IAddressValue[] = [
  {
    id: 1,
    name: "house",
    placeholder: "Дом",
    inputType: "text"
  },
  {
    id: 2,
    name: "flat",
    placeholder: "Квартира",
    inputType: "text"
  },
  {
    id: 3,
    name: "floor",
    placeholder: "Этаж",
    inputType: "text"
  },
  {
    id: 4,
    name: "entrance",
    placeholder: "Подъезд",
    inputType: "text"
  }
];
// Опечатка adress -> address
const AdressBlock: React.FC<IAdressBlockProps> = (props) => {
  const {
    searchedCities,
    isLoading,
    // isNoResultSearch,
    onChangeSearchValue,
    onResetCitiesSearch
  } = useCitiesSearch({ limit: 5 });

  const [showNotification, setShowNotification] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isChangesAdress, setIsChangesAdress] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValue, setSearchValue] = useState("");
  const [isStreetInputFocused, setIsStreetInputFocused] = useState(false);
  const [deliveryAddresses, setDeliveryAddresses] = useState<string[]>([]);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [placeholderStreet, setPlaceholderStreet] = useState("Улица");
  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const cityInputRef = useRef<HTMLInputElement | null>(null);
  const citiesListRef = useRef<HTMLDivElement | null>(null);
  const streetInputRef = useRef<HTMLInputElement | null>(null);
  const suggestionsListRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const addressEmtpyItem = isEmptyAddress(props.adressData) && props.adressData;
  const addressItem = !isEmptyAddress(props.adressData) && props.adressData;

  const formik = useFormik<IRequestAddDeliveryAddressThunk>({
    initialValues: {
      name: addressItem.name || "",
      city: addressItem.city || "",
      street: addressItem.street || "",
      house: addressItem.house || "",
      flat: addressItem.flat?.toString() || "",
      floor: addressItem.floor?.toString() || "",
      entrance: addressItem.entrance?.toString() || ""
    },
    validationSchema: adressSchema,
    onSubmit: async () => {
      try {
        const adressData = await dispatch(
          addDeliveryAdress({
            name: formik.values.name,
            city: formik.values.city,
            street: formik.values.street,
            house: formik.values.house,
            flat: formik.values.flat,
            floor: formik.values.floor,
            entrance: formik.values.entrance
          })
        ).unwrap();

        if (!adressData) {
          return;
        }
        props.setUserAdress(adressData);
        setShowNotification(true);
        setShowSaveButton(false);

        setTimeout(() => {
          setShowNotification(false);
        }, 2000);
      } catch (err) {
        const errorData = getErrorData(err);
        toast.error(errorData.message);
      }
    }
  });

  useEffect(() => {
    if (addressEmtpyItem.emptyId) {
      setShowSaveButton(true);
    }
  }, [addressEmtpyItem]);

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    const initialValues: IRequestAddDeliveryAddressThunk = {
      name: addressItem.name || "",
      city: addressItem.city || "",
      street: addressItem.street || "",
      house: addressItem.house || "",
      flat: addressItem.flat?.toString() || "",
      floor: addressItem.floor?.toString() || "",
      entrance: addressItem.entrance?.toString() || ""
    };

    const checkFormChanges = () => {
      const currentValues: IRequestAddDeliveryAddressThunk = {
        name: formik.values.name,
        city: formik.values.city,
        street: formik.values.street,
        house: formik.values.house,
        flat: formik.values.flat,
        floor: formik.values.floor,
        entrance: formik.values.entrance
      };

      setIsChangesAdress(
        JSON.stringify(initialValues) !== JSON.stringify(currentValues)
      );
    };

    checkFormChanges();
  }, [
    addressItem.city,
    addressItem.entrance,
    addressItem.flat,
    addressItem.floor,
    addressItem.house,
    addressItem.name,
    addressItem.street,
    formik.values,
    props.adressData
  ]);

  const onSubmitHandler = () => {
    if (!isChangesAdress) {
      toast("Заполните поля для сохранения адреса");
      return;
    }

    if (!isAddressSelected) {
      toast("Пожалуйста, выберите существующую улицу из предложенных.");
      return;
    }

    if (formik.errors) {
      Object.values(formik.errors).forEach((error) => {
        return toast(error);
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = addressItem;
    const convertedRest = Object.entries(rest).map((item) => {
      if (!item[1]) {
        return [item[0], ""];
      }
      return item;
    });
    const isEqualData = getIsEqual(
      Object.fromEntries(convertedRest),
      formik.values
    );

    if (isEqualData) {
      return;
    }
    formik.handleSubmit();
  };

  const onDeleteAdress = () => {
    props.onClickEmptyAdressAction("dec", props.index);
    onCloseDeleteModal();
    formik.resetForm({
      values: {
        name: "",
        city: "",
        street: "",
        house: "",
        flat: "",
        floor: "",
        entrance: ""
      }
    });
  };

  const isReadOnly = !isEmptyAddress(props.adressData);

  const selectCity = (cityName: string) => {
    formik.setFieldValue("city", cityName);
    onResetCitiesSearch();
    setShowCities(false);
  };

  const handleClickOutsideCities = (event: MouseEvent) => {
    const target = event.target as Node;
    if (
      citiesListRef.current &&
      !citiesListRef.current.contains(target) &&
      cityInputRef.current &&
      !cityInputRef.current.contains(target)
    ) {
      setShowCities(false);
    }
  };

  const handleClickOutsideSuggestions = (event: MouseEvent) => {
    const target = event.target as Node;
    if (
      suggestionsListRef.current &&
      !suggestionsListRef.current.contains(target) &&
      streetInputRef.current &&
      !streetInputRef.current.contains(target)
    ) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideCities);
    document.addEventListener("mousedown", handleClickOutsideSuggestions);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCities);
      document.removeEventListener("mousedown", handleClickOutsideSuggestions);
    };
  }, []);

  const deliveryData = useDelivery({
    searchValue: formik.values.street,
    isFocusInput: isStreetInputFocused,
    city: formik.values.city
  });

  useEffect(() => {
    if (isStreetInputFocused) {
      setDeliveryAddresses(deliveryData.deliveryAddresses);
    }
  }, [
    isStreetInputFocused,
    formik.values.street,
    formik.values.city,
    deliveryData
  ]);

  const handleStreetFocus = () => {
    setPlaceholderStreet("");
    if (formik.values.street.length >= 3) {
      setShowSuggestions(true);
    }
  };

  const handleStreetBlur = () => {
    setIsStreetInputFocused(false);
    setPlaceholderStreet("Улица");
  };

  const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAddressSelected(false);
    setSearchValue(e.target.value);
    setIsSearchPerformed(true);
    setIsStreetInputFocused(true);
    if (e.target.value.length >= 3) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleFocus = (name: string) => {
    if (!isReadOnly) {
      setFocusedInput(name);
    }
  };

  const handleBlur = () => {
    if (!isReadOnly) {
      setFocusedInput(null);
    }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    onChangeSearchValue(e.target.value);
    setShowCities(true);
  };

  const handleStreetChoose = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    handleStreetChange(e);
  };

  const handleSuggestionsChoose = (address: string) => {
    formik.setFieldValue("street", formatStreetAddress(address));
    setShowSuggestions(false);
    setIsAddressSelected(true);
  };

  return (
    <>
      <div className={styles["header-container"]}>
        <h3 className={styles["main-title"]}>
          Aдрес доставки:{" "}
          <span className={styles["main-title__value"]}>
            {addressItem.name}
          </span>
        </h3>
        {isReadOnly && (
          <button
            type="button"
            onClick={() => setShowDeleteModal(true)}
            className={styles["form__delete-button"]}
          >
            Удалить
          </button>
        )}
      </div>
      <form className={styles.form}>
        <div className={styles.form__row}>
          <input
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Название адреса"
            readOnly={isReadOnly}
            className={cn(styles.form__input, styles["form__title-input"])}
          />
        </div>
        <div className={styles.form__row}>
          <span className={styles.form__title}>Город</span>
          <div className={styles.input__wrapper}>
            <input
              name="city"
              type="text"
              value={formik.values.city}
              onChange={handleCityChange}
              readOnly={isReadOnly}
              className={styles.form__input}
              autoComplete="off"
              ref={cityInputRef}
            />
            {!isLoading && showCities && (
              <div className={cn(styles["cities-wrapper"])}>
                <div className={cn(styles.cities)} ref={citiesListRef}>
                  {searchedCities.length > 0 ? (
                    // TODO можно будет убрать index как только пропадут дубли
                    // Ставим key с индексом так как сейчас с бэка идут дубликаты 21-10-2024
                    searchedCities.map((city, index) => (
                      <button
                        key={`${city?.fias_id}-${city.name}-${index}`}
                        className={styles.cities__item}
                        onClick={() => selectCity(city.name)}
                      >
                        <span className={styles["cities__item--name"]}>
                          {city.name}
                        </span>
                        <span className={styles["cities__item--region"]}>
                          {city.reqion_with_type}
                        </span>
                      </button>
                    ))
                  ) : (
                    <div className={styles["cities__item--result"]}>
                      Нет результатов
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.form__row}>
          <span className={styles.form__title}>Адрес доставки</span>
          <div className={styles["form__inputs-wrapper"]}>
            <div className={styles.input__wrapper}>
              <input
                name="street"
                type="text"
                value={formik.values.street}
                placeholder={placeholderStreet}
                onChange={handleStreetChoose}
                readOnly={isReadOnly}
                className={styles.form__input}
                autoComplete="off"
                onFocus={handleStreetFocus}
                onBlur={handleStreetBlur}
                ref={streetInputRef}
              />
              {!isReadOnly && showSuggestions && (
                <div className={cn(styles["suggestions-wrapper"])}>
                  <div
                    className={cn(styles.suggestions)}
                    ref={suggestionsListRef}
                  >
                    {deliveryAddresses.length > 0
                      ? deliveryAddresses.slice(0, 10).map((address, index) => (
                        <button
                          key={`${address}-${address}-${index}`}
                          className={styles.suggestions__item}
                          onClick={() => handleSuggestionsChoose(address)}
                        >
                          <span className={styles["suggestions__item--name"]}>
                            {formatStreetAddress(address)}
                          </span>
                        </button>
                      ))
                      : isSearchPerformed &&
                        isStreetInputFocused && (
                        <div className={styles["suggestions__item--result"]}>
                            Нет результатов
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
            {ADRESS_VALUES.map((item, index) => {
              return (
                <input
                  key={`${item.id}_${index}`}
                  name={item.name}
                  type={item.inputType}
                  value={
                    formik.values[
                      item.name as keyof IRequestAddDeliveryAddressThunk
                    ]
                  }
                  placeholder={
                    focusedInput === item.name ? "" : item.placeholder
                  }
                  onChange={formik.handleChange}
                  readOnly={isReadOnly}
                  onFocus={() => handleFocus(item.name)}
                  onBlur={handleBlur}
                  className={cn(styles.form__input, {
                    [styles["form__adress-additional"]]: item.id !== 0
                  })}
                />
              );
            })}
          </div>
        </div>
        {showDeleteModal && (
          <DeleteAdressModal
            onClose={onCloseDeleteModal}
            onDelete={onDeleteAdress}
          />
        )}
      </form>
      <div className={styles.buttons}>
        {props.adressAmount === props.index && !showSaveButton && (
          <button
            type="button"
            onClick={() => props.onClickEmptyAdressAction("inc", props.index)}
            className={styles["buttons__add-button"]}
          >
            <div className={styles["buttons__plus-wrapper"]}>
              <div className={styles["buttons__plus-line-vertical"]} />
              <div className={styles["buttons__plus-line-horisontal"]} />
            </div>
            <span className={styles["buttons__title-add-adress"]}>
              Добавить новый адрес
            </span>
          </button>
        )}
        {showSaveButton && (
          <CustomButton
            title="Сохранить адрес"
            type="button"
            onClick={onSubmitHandler}
            className={styles["buttons__save-button"]}
          />
        )}
      </div>
      <CustomNotification showNotification={showNotification} />
    </>
  );
};

export default AdressBlock;
