import { useCallback, useState } from "react";

export interface IShadowObj {
  top: boolean;
  bottom: boolean;
}

const useShowShadow = (isVertical?: boolean) => {
  const [showShadow, setShowShadow] = useState<IShadowObj>({
    top: false,
    bottom: false
  });

  const changeShowShadow = useCallback(
    (value: IShadowObj | ((prev: IShadowObj) => IShadowObj)) => {
      setShowShadow((prev) =>
        typeof value === "function" ? value(prev) : value
      );
    },
    []
  );

  const onScrollHandler = (e: React.UIEvent<HTMLDivElement | HTMLUListElement>) => {
    const target = e.currentTarget;

    if (isVertical) {
      setShowShadow({
        top: target.scrollLeft > 0,
        bottom: target.scrollLeft < target.scrollWidth - target.offsetWidth
      });
      return;
    }

    setShowShadow({
      top: target.scrollTop > 0,
      bottom: target.scrollTop < target.scrollHeight - target.offsetHeight
    });
  };

  return {
    showShadow,
    changeShowShadow,
    onScrollHandler
  };
};

export default useShowShadow;
