import { useEffect, useRef, useState } from "react";
import useShowShadow, {
  IShadowObj
} from "../../../../../../../utils/hooks/useShowShadow";

import CustomScrollbar from "../../../../../../../components/CustomScrollbar/CustomScrollbar";
import ProductOffersListItem from "../../ProductOffersListItem/ProductOffersListItem";

import cn from "classnames";
import styles from "./styles.module.scss";
import { IOffer } from "types/IOffer";
import { ICurrentOffer } from "types/ICurrentOffer";

interface IOffersListProps {
  isLoading?: boolean;
  sortedOffers: IOffer[];
  activeOfferId: number;
  productId?: number;
  onSelectOffer: (option: ICurrentOffer) => void;
  offersScrollbarStyles?: string;
  offersListStyles?: string;
}

const OffersList: React.FC<IOffersListProps> = (props) => {
  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();
  const [isShowScrollbar, setIsShowScrollbar] = useState(false);
  const scrollbarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!scrollbarRef.current) {
      return;
    }

    const scrollHeight = scrollbarRef.current.children[0].scrollHeight;
    const offsetHeight = scrollbarRef.current.offsetHeight;
    setIsShowScrollbar(scrollHeight > offsetHeight);
    changeShowShadow((prev: IShadowObj) => {
      const currentShadow: IShadowObj = {
        ...prev,
        bottom: scrollHeight > offsetHeight
      };
      return currentShadow;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomScrollbar
      scrollbarRef={scrollbarRef}
      scrollbarClassname={cn(
        styles["offer-picker"],
        props.offersScrollbarStyles,
        {
          [styles["offer-picker--top-shadow"]]: showShadow.top,
          [styles["offer-picker--bottom-shadow"]]: showShadow.bottom
        }
      )}
      childrenClassName={cn(
        styles["offer-picker__list"],
        props.offersListStyles
      )}
      isShowScrollbar={isShowScrollbar}
      onScrollHandler={onScrollHandler}
    >
      {props.sortedOffers.map((offer) => {
        return (
          <ProductOffersListItem
            productId={props.productId}
            key={offer.id}
            offer={offer}
            isActiveOffer={props.activeOfferId === offer.id}
            onSelectOffer={props.onSelectOffer}
          />
        );
      })}
      {props.isLoading && !props.sortedOffers.length && (
        <p className={styles["offer--not-available"]}>Товара нет в наличии</p>
      )}
    </CustomScrollbar>
  );
};

export default OffersList;
