import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import useOutsideClick from "../utils/hooks/useOutsideClick";

interface PortalProps {
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  children: React.ReactNode;
}

const Portal: React.FC<PortalProps> = ({children, modalRef, onClose}) => {
  const scrollPosition = useRef<number | null>(null);

  useOutsideClick({
    ref: modalRef,
    cb: onClose,
  });

  useEffect(() => {
    scrollPosition.current = window.scrollY;
    const body = document.querySelector("body") as HTMLBodyElement;

    body.style.overflowY = "scroll";
    body.style.position = "fixed";
    body.style.top = `-${scrollPosition.current}px`;
    body.style.width = "100%";

    return () => {
      body.style.overflow = "";
      body.style.position = "";
      body.style.top = "";
      body.style.width = "";

      window.scrollTo(0, scrollPosition.current || 0);
    };
  }, []);

  return createPortal(
    children,
    document.body
  );
};

export default Portal;
