import cn from "classnames";
import styles from "./styles.module.scss";

interface IActionButtonProps {
  title: string;
  isMobile: boolean;
  onClick: () => void;
  containerStyles?: string;
  children?: React.ReactNode;
}

const ActionButton: React.FC<IActionButtonProps> = (props) => {
  return (
    <button
      className={cn(styles.actions__button, props.containerStyles)}
      onClick={props.onClick}
    >
      {props.children}
      {!props.isMobile && (
        <span className={styles.actions__title}>{props.title}</span>
      )}
    </button>
  );
};

export default ActionButton;
