import styles from "./styles.module.scss";
import NotFoundPageSvg from "../../assets/icons/svg/not-found-page.svg";
import CustomLink from "../../components/CustomLink/CustomLink";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <div className={styles.root}>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>

      <img className={styles.root__img} src={NotFoundPageSvg} />
      <h1 className={styles.root__title}>Упс. Страница не найдена</h1>
      <CustomLink
        className={styles.root__link}
        title="Перейти на главную страницу"
        path={"/"}
        isPrimary />
    </div>
  );
};

export default NotFoundPage;