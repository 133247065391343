import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import cn from "classnames";

export const SkeletonLine = ({ style, className }) => {
  return (
    <>
      <div className={cn(styles.line, className)} style={style} />
    </>
  );
};

const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

SkeletonLine.propTypes = propTypes;
