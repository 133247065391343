import { useMemo, useRef, useState } from "react";
import { useAppSelector } from "store/reduxHooks";
import cn from "classnames";

import useOutsideClick from "../../../../utils/hooks/useOutsideClick";
import useSearchParamsHandler from "../../../../utils/hooks/useSearchParamsHandler";

import SortInnerContent from "../SortInnerContent/SortInnerContent";
import BottomSheet from "../../../../components/UI/Bottomsheet/Bottomsheet";
import arrowIcon from "../../../../assets/icons/png/small-arrow.png";

import styles from "./styles.module.scss";

export const SORT_TITLES = [
  {
    id: 0,
    name: "Популярное",
    // eslint-disable-next-line camelcase
    rewrite_name: "showed",
    // eslint-disable-next-line camelcase
    order_by: "desc"
  },
  {
    id: 1,
    name: "По алфавиту",
    // eslint-disable-next-line camelcase
    rewrite_name: "name",
    // eslint-disable-next-line camelcase
    order_by: "asc"
  },
  {
    id: 2,
    name: "По возрастанию цены",
    // eslint-disable-next-line camelcase
    rewrite_name: "price",
    // eslint-disable-next-line camelcase
    order_by: "asc"
  },
  {
    id: 3,
    name: "По убыванию цены",
    // eslint-disable-next-line camelcase
    rewrite_name: "price",
    // eslint-disable-next-line camelcase
    order_by: "desc"
  }
];

interface ISortButtonProps {
  onSetShouldGetFilters?: (value: boolean) => void;
  onChangeOffset?: (offset: number) => void;
}

const SortButton: React.FC<ISortButtonProps> = ({onSetShouldGetFilters, onChangeOffset}) => {
  const { deviceType } = useAppSelector((state) => state.user);
  const { searchParams, onChangeSearchParams } = useSearchParamsHandler();

  const initialSortOption = useMemo(() => {
    const sortParamsMatch = SORT_TITLES.find(
      (item) =>
        searchParams.get("sort_by") === item.rewrite_name &&
        searchParams.get("order_by") === item.order_by
    );

    if (sortParamsMatch) {
      return sortParamsMatch;
    }

    return SORT_TITLES[0];
  }, [searchParams]);

  const [isOpen, setIsOpen] = useState(false);

  const blockRef = useRef(null);

  useOutsideClick({
    ref: blockRef,
    cb: () => setIsOpen(false)
  });

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  const onSelectTitle = (id: number) => async () => {
    onSetShouldGetFilters(false);
    const findedItem = SORT_TITLES.find((item) => {
      return item.id === id;
    });
    const condition = initialSortOption.name === findedItem.name;
    if (condition) return;

    const searchOptions = {
      offset: "0",
      // eslint-disable-next-line camelcase
      sort_by: !condition ? findedItem.rewrite_name : "",
      // eslint-disable-next-line camelcase
      order_by: !condition ? findedItem.order_by : ""
    };

    setIsOpen(false);
    onChangeOffset(Number(searchOptions.offset));
    onChangeSearchParams(searchOptions);
  };

  return (
    <div className={styles.root} ref={blockRef}>
      <button className={styles.button} onClick={onClickHandler}>
        <span className={styles.title}>Сортировать:</span>
        <span className={styles["sort-title"]}>{initialSortOption.name}</span>
        {!deviceType.isMobile && (
          <img
            src={arrowIcon}
            alt="arrow"
            className={cn(styles.arrow, {
              [styles["arrow--rotated"]]: isOpen
            })}
          />
        )}
      </button>
      {isOpen && !deviceType.isMobile && (
        <SortInnerContent
          currentTitle={initialSortOption.name}
          titles={SORT_TITLES}
          onSelectTitle={onSelectTitle}
          containerStyles={styles["inner-content"]}
        />
      )}
      {!!deviceType.isMobile && (
        <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <SortInnerContent
            currentTitle={initialSortOption.name}
            titles={SORT_TITLES}
            onSelectTitle={onSelectTitle}
            containerStyles={styles.bottomsheet__content}
          />
        </BottomSheet>
      )}
    </div>
  );
};

export default SortButton;
