import { useState } from "react";
import { useFormik } from "formik";
import { nanoid } from "@reduxjs/toolkit";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "store/reduxHooks";
import { toast } from "react-toastify";

import { signUpUser } from "../../store/user/userThunks/userThunks";
import { setTokens } from "../../utils/localStorage";

import { signUpSchema } from "../../schemas/signUpSchema";
import CustomAuthInput from "../../components/CustomAuthInput/CustomAuthInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

import chechMarkIcon from "../../assets/icons/png/chech_mark-icon.png";

import styles from "./styles.module.scss";
import { removeCart } from "../../store/user/user";
import { getUserCart } from "../../store/user/cartThunks/cartThunks";
import PasswordHint from "components/PasswordHint/PasswordHint";
import { SING_UP_PASSWORD_HINT } from "utils/constants/messageHint";
import { ISignUpValue } from "types/ISignUpValue";
import { INavigateState } from "types/INavigateState";
import { getErrorData } from "utils/getErrorData";

interface ISignUpFormData {
  phoneNumber: string;
  name: string;
  password: string;
  email: string;
}

const SIGN_UP_VALUES: ISignUpValue[] = [
  {
    id: nanoid(),
    title: "Имя",
    name: "name",
    inputType: "text"
  },
  {
    id: nanoid(),
    title: "Номер телефона",
    name: "phoneNumber",
    inputType: "tel"
  },
  {
    id: nanoid(),
    title: "Email",
    name: "email",
    inputType: "email"
  },
  {
    id: nanoid(),
    title: "Пароль",
    name: "password",
    inputType: "password"
  }
];

const SignUp: React.FC = () => {
  const [showErrorsOnSubmit, setShowErrorsOnSubmit] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(true);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const locationState = (location.state as INavigateState)?.from?.pathname;
  const dispatch = useAppDispatch();

  const formik = useFormik<ISignUpFormData>({
    initialValues: {
      phoneNumber: "",
      name: "",
      password: "",
      email: ""
    },
    validationSchema: signUpSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const registeredUserData = await dispatch(
          signUpUser({
            phone: values.phoneNumber.replace(/[^\d]/g, ""),
            name: values.name.trim(),
            password: values.password,
            email: values.email
          })
        ).unwrap();

        if (!registeredUserData?.user) {
          return;
        }

        if (showCheckBox) {
          setTokens({
            token: registeredUserData.token,
            refreshToken: registeredUserData.refreshToken
          });
        } else {
          sessionStorage.setItem("accessToken", registeredUserData.token);
        }
        navigate(locationState || "/", { replace: true });

        dispatch(removeCart());
        dispatch(getUserCart({}));
      } catch (err) {
        const errorData = getErrorData(err);
        if (errorData?.code === 7) {
          setIsPasswordError(true);
        }

        if (errorData?.code === 3) {
          formik.setFieldError("phoneNumber", errorData.message);
        }
        const errorMessage = errorData.message;
        if (errorMessage === "Failed to fetch") {
          toast.error(
            "Не удалось подключиться к серверу. Пожалуйста, проверьте ваше интернет-соединение."
          );
        } else {
          toast.error(errorMessage);
        }
      } finally {
        setSubmitting(false);
      }
    }
  });

  const onSubmitHandler = () => {
    setIsPasswordFocused(false);
    setShowErrorsOnSubmit(true);
    formik.handleSubmit();
  };

  const onClickShowCheckBox = () => {
    setShowCheckBox(!showCheckBox);
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
    setIsPasswordError(false);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Регистрация</h2>
      <form method="post" onSubmit={onSubmitHandler} className={styles.form}>
        {SIGN_UP_VALUES.map((item) => {
          return (
            <CustomAuthInput
              key={item.id}
              name={item.name}
              inputType={item.inputType}
              placeholder={item.title}
              value={formik.values[`${item.name}`]}
              onChange={formik.handleChange}
              errorValue={formik.errors[`${item.name}`]}
              showErrorsOnSubmit={
                item.inputType === "email"
                  ? formik.values[`${item.name}`]
                    ? showErrorsOnSubmit
                    : false
                  : showErrorsOnSubmit
              }
              onFocus={
                item.inputType === "password" ? handlePasswordFocus : undefined
              }
              onBlur={
                item.inputType === "password" ? handlePasswordBlur : undefined
              }
              showErrorIfErrorInResponse={
                item.inputType === "password" && isPasswordError
              }
            />
          );
        })}
      </form>
      {isPasswordFocused ? (
        <PasswordHint message={SING_UP_PASSWORD_HINT} />
      ) : (
        <div className={styles["hint-placeholder"]} />
      )}
      <button
        className={styles["remember-button"]}
        onClick={onClickShowCheckBox}
      >
        <div className={styles["remember-button__checkbox"]}>
          {showCheckBox && <img src={chechMarkIcon} alt="chech_mark-icon" />}
        </div>
        <span className={styles["remember-button__title"]}>Запомнить меня</span>
      </button>
      <CustomButton
        title="Зарегистрироваться"
        onClick={onSubmitHandler}
        type="submit"
        className={styles["singup-button"]}
        isDisabled={formik.isSubmitting}
      />
      <div className={styles["auth-content"]}>
        <span className={styles["auth-content__title"]}>
          Есть учётная запись?
        </span>
        <Link to={"/sign-in"} className={styles["auth-content__subtitle"]}>
          Авторизуйтесь сейчас
        </Link>
      </div>
      <SocialMedia isLogin={false} />
    </main>
  );
};

export default SignUp;
