import React, { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import {
  IFilterItemToSelect,
  IFilterItemToSelectMobile,
  ISelectedFilterItem,
  setPriceFilter,
  setSelectedFilterItem,
  setSingleFilterSelect,
  setSingleMobileSearchParam,
  toggleSearchTrigger
} from "store/catalogV3";

import { ReactComponent as CrossIcon } from "../../../../../assets/icons/svg/cross-icon.svg";

import useSearchParamsHandler from "utils/hooks/useSearchParamsHandler";
import { FilterKeysEnum } from "types/FilterKeysEnum";
import { formatPrice } from "utils/helpers/formatedPrice";
import { IFilterCommon } from "types/IFiltersV3";

import styles from "./styles.module.scss";

interface ISelectedFiltersContentV3Props {
  isMobileDevice?: boolean;
}

const SelectedFiltersContentV3: React.FC<ISelectedFiltersContentV3Props> = ({
  isMobileDevice
}) => {
  const { selectedFilters } = useAppSelector((state) => state.catalogV3);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onDeleteParams } = useSearchParamsHandler();

  const currentCatalogSection = useMemo(() => {
    const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);
    if (!currentMatch) {
      return "";
    }
    return currentMatch[0];
  }, [location.pathname]);

  const isCatalogSection = !!currentCatalogSection.length;

  const removeSingleFilter = (selectedFilterItem: ISelectedFilterItem) => {
    const searchParamsObject: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      searchParamsObject[key] = value;
    });
    // если фильтр из product_properties данные поступаемые в редакс должны быть формата properties[ключ] - mainFilterRewriteName
    const filterItemToSelect: IFilterItemToSelect = {
      filter: selectedFilterItem.filter,
      mainFilterRewriteName: selectedFilterItem.mainFilterRewriteName,
      searchParamsObject
    };

    if (!isMobileDevice) {
      dispatch(setSingleFilterSelect(filterItemToSelect));
      // Перезаписываем URL из тех данных что обработались в dispatch setSingleFilterSelect
      // Для уточнения - мы мутируем объект searchParamsObj внутки setSingleFilterSelect
      setSearchParams(new URLSearchParams(searchParamsObject));
    } else {
      const singleMobileFitlerItem: IFilterItemToSelectMobile = {
        mainFilterRewriteName: filterItemToSelect.mainFilterRewriteName,
        filter: filterItemToSelect.filter
      };
      dispatch(setSingleMobileSearchParam(singleMobileFitlerItem));
      dispatch(toggleSearchTrigger());
    }

    dispatch(setSelectedFilterItem(selectedFilterItem));
  };

  const removePriceFilter = () => {
    dispatch(setPriceFilter("REMOVE_PRICE_FILTER"));
    if (!isMobileDevice) {
      onDeleteParams(["min_price", "max_price", "limit", "offset"]);
      return;
    }
    dispatch(toggleSearchTrigger());
  };

  return selectedFilters.map((item) => {
    const isFilterPrices = item.mainFilterRewriteName === FilterKeysEnum.PRICES;
    if (isFilterPrices) {
      const subPriceFilters = item.filters as IFilterCommon[];
      const pricesTag = `от ${formatPrice(subPriceFilters[0].name)}₽ - до ${formatPrice(subPriceFilters[1].name)}₽`;
      return (
        <div
          key={`${item.mainFilterRewriteName}`}
          className={styles["item__row-mobile"]}
        >
          <span className={styles.item__title}>
            {item.mainFilterLocalizedName}:
          </span>
          {item.filters.length === 2 && (
            <div className={styles["item__rows-wrapper"]}>
              <p className={styles.item__value}>
                <span className={styles.value__title}>{pricesTag}</span>
                <button
                  className={styles.value__button}
                  onClick={removePriceFilter}
                >
                  <CrossIcon />
                </button>
              </p>
            </div>
          )}
        </div>
      );
    }

    if (
      item.mainFilterRewriteName === FilterKeysEnum.CATEGORIES &&
      isCatalogSection
    ) {
      return null;
    }

    return (
      <div
        key={`${item.mainFilterRewriteName}`}
        className={styles["item__row-mobile"]}
      >
        <span className={styles.item__title}>
          {item.mainFilterLocalizedName}:
        </span>
        {!!item.filters.length && (
          <div className={styles["item__rows-wrapper"]}>
            {item.filters.map((el, index) => {
              const subFilterRewriteName =
                "rewrite_name" in el ? el.rewrite_name : el.rewrite_value;
              const subFilterName = "name" in el ? el.name : el.value;

              const selectedFilterItem: ISelectedFilterItem = {
                mainFilterRewriteName: item.mainFilterRewriteName,
                mainFilterLocalizedName: item.mainFilterLocalizedName,
                filter: el,
                isFilterProductProperties: item.isFilterProductProperties
              };
              return (
                <p
                  key={`${subFilterRewriteName}_${index}`}
                  className={styles.item__value}
                >
                  <span className={styles.value__title}>{subFilterName}</span>
                  <button
                    className={styles.value__button}
                    onClick={() => removeSingleFilter(selectedFilterItem)}
                  >
                    <CrossIcon />
                  </button>
                </p>
              );
            })}
          </div>
        )}
      </div>
    );
  });
};

export default SelectedFiltersContentV3;
