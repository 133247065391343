import { PropTypes } from "prop-types";
import Portal from "../../../HOC/Portal";
import ContentMarkDownInfo from "../ContentMarkDownInfo/ContentMarkDownInfo";
import styles from "./styles.module.scss";

const ModalMarkDownInfo = (props) => {
  return (
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <div className={styles.modal}>
        <ContentMarkDownInfo onClose={props.onClose} modalRef={props.modalRef} />
        <div className={styles.modal__background} />
      </div>
    </Portal>
  );
};

const propTypes =
{
  onClose: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
};

ModalMarkDownInfo.propTypes = propTypes;

export default ModalMarkDownInfo;