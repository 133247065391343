import PropTypes from "prop-types";

import Portal from "../../../HOC/Portal";
import BrandsByLetterContent from "../BrandsByLetterContent";

import { ReactComponent as CrossIcon } from "../../../assets/icons/cross-icon.svg";

import styles from "./styles.module.scss";

const BrandsByLetterModal = (props) => {
  return (
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <section ref={props.modalRef} className={styles.root}>
        <button className={styles["cross-icon"]} onClick={props.onClose}>
          <CrossIcon />
        </button>
        <BrandsByLetterContent {...props} />
      </section>
      <div className={styles.background} />
    </Portal>
  );
};

const propTypes = {
  modalRef: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

BrandsByLetterModal.propTypes = propTypes;

export default BrandsByLetterModal;
