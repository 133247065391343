import styles from "./styles.module.scss";
import dangerCircle from "../../assets/icons/dangerCircle.svg";

interface INotificationHintProps {
  message: string;
}

const NotificationHint: React.FC<INotificationHintProps> = ({ message }) => {
  return (
    <div className={styles["info-danger-block"]}>
      <img
        className={styles["info-danger-block__icon"]}
        src={dangerCircle}
        alt="danger-circle"
      />
      <p className={styles["info-danger-block__message"]}>{message}</p>
    </div>
  );
};

export default NotificationHint;
