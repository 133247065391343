import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import CustomDropDown from "../../../../components/CustomDropDown";

import styles from "./styles.module.scss";

const ListWithLinks = (props) => {
  const { deviceType } = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  const onScrollContentToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handlerClickLink = () => {
    onScrollContentToTop();
  };

  useEffect(() => {
    setIsOpen(!deviceType.isMobile);
  }, [deviceType.isMobile]);

  return (
    <CustomDropDown
      isOpen={isOpen}
      containerStyles={styles.dropdown}
      dropdownElementStyles={styles["dropdown-content"]}
    >
      {{
        buttonElement: (
          <>
            <button className={styles.button} onClick={onClickHandler}>
              <p className={styles["catalog-list__title"]}>{props.title}</p>
              <span className={styles["catalog-list__symbol"]}>{isOpen ? "-" : "+"}</span>
            </button>
            <div className={styles["button--disabled"]}>
              <p className={styles["catalog-list__title"]}>{props.title}</p>
            </div>
          </>
        ),
        dropdownElement: (
          <div className={styles["catalog-list"]}>
            {props.list.map((item, idx) => {
              return (
                <Link
                  key={idx}
                  to={item.link}
                  onClick={handlerClickLink}
                  className={styles["catalog-list__item"]}
                >
                  {item.title}
                </Link>
              );
            })}
          </div>
        ),
      }}
    </CustomDropDown>
  );
};

const propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

ListWithLinks.propTypes = propTypes;

export default ListWithLinks;