import { useRef } from "react";
import PropTypes from "prop-types";

import ExitFromPersonalAreaContent from "../../../components/ExitFromPersonalAreaContent";

import styles from "./styles.module.scss";

const ExitModal = (props) => {
  const modalRef = useRef(null);

  return (
    <ExitFromPersonalAreaContent
      containerStyles={styles.root}
      onClose={props.onClose}
      contentRef={modalRef}
    />
  );
};

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

ExitModal.propTypes = propTypes;

export default ExitModal;
