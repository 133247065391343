import { useCallback, useEffect, useMemo, useRef } from "react";

import styles from "./styles.module.scss";
import { ICreateNewOrderAuthorizedUsersResponse } from "types/ICreateNewOrderAuthorizedUsers";
import { ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";

interface IPayOrderCreatedOrderNotificationProps {
  orderData:
    | ICreateNewOrderAuthorizedUsersResponse
    | ICreateNewOrderNonAuthorizedUsersResponse
    | null;
}

const SCROLL_OFFSET_TOP = 300;

const PayOrderCreatedOrderNotification: React.FC<
  IPayOrderCreatedOrderNotificationProps
> = (props) => {
  const { orderData } = props;
  const contentRef = useRef<HTMLDivElement | null>(null);

  const currentDate = useMemo(() => {
    const date = new Date(orderData.created_at);
    return date.toLocaleString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  }, [orderData]);

  const onScrollContentToTop = useCallback(() => {
    const contentOffsetTop = contentRef?.current.offsetTop;
    window.scrollTo({
      top: contentOffsetTop - SCROLL_OFFSET_TOP,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    onScrollContentToTop();
  }, [onScrollContentToTop]);

  return (
    <div className={styles["notification"]} ref={contentRef}>
      <h3 className={styles["notification__title"]}>Информация о заказе</h3>
      <p className={styles["notification__message-block"]}>
        <span>
          Заказ № <span className={styles.primary}>{`${orderData.id}`}</span> от{" "}
          {`${currentDate}`}
        </span>
        <span>Успешно оформлен</span>
      </p>
    </div>
  );
};

export default PayOrderCreatedOrderNotification;
