import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { Link, useNavigate } from "react-router-dom";
import cn from "classnames";
import { toast } from "react-toastify";

import {
  selectItemsInCartByIds,
  setCheckoutItems,
  setPromocode
} from "../../../../../store/user/user";
import { userApi } from "../../../../../api/userApi";
import { WAY_TO_GET } from "../../../../../utils/constants";

import PromocodeInput from "../../../../../components/UI/PromocodeInput/PromocodeInput";
import OrderStringItem from "../../../../../components/UI/OrderStringItem/OrderStringItem";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import CheckoutOrderItem from "./components/CheckoutOrderItem/CheckoutOrderItem";
import CustomScrollbar from "../../../../../components/CustomScrollbar/CustomScrollbar";
import { metric } from "../../../../../utils/yandexMetrics/yandexMetrics";

import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YANDEX_CREATE_ORDER_GOAL_ID } from "utils/yandexMetrics/yandexMetricsCore";
import { ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";
import {
  ICreateNewOrderAuthorizedUsersRequest,
  ICreateNewOrderAuthorizedUsersResponse
} from "types/ICreateNewOrderAuthorizedUsers";
import { IGetUserCartRequest } from "types/IGetUserCart";
import { Offer } from "types/UserApiTypes";
import { CustomError, getErrorData } from "utils/getErrorData";
import { isError } from "store/user/userThunks/userThunks";
import { getUserCart } from "store/user/cartThunks/cartThunks";
import { isValidPhone } from "utils/helpers/isValidPhone";
import { isValidEmail } from "utils/helpers/isEmailValid";
import { userContactsSchema } from "schemas/userContactsSchema";
// import { ICartItem } from "types/ICartItem";
import { getValueFromStorage, setValueInStorage } from "utils/localStorage";

const isResponseAuthType = (
  response: unknown
): response is ICreateNewOrderAuthorizedUsersResponse => {
  return typeof response === "object" && "promocode" in response;
};

const MAX_DISPLAY_PRODUCTS = 3;

declare global {
  interface Date {
    addDays(days: number): Date;
  }
}

interface IUserOrderDataProps {
  setIsPay?: (value: boolean | ((prevState: boolean) => boolean)) => void;
  setOrderData?: (
    value:
      | ICreateNewOrderNonAuthorizedUsersResponse
      | ICreateNewOrderAuthorizedUsersResponse
      | ((
          prevState:
            | ICreateNewOrderNonAuthorizedUsersResponse
            | ICreateNewOrderAuthorizedUsersResponse
        ) =>
          | ICreateNewOrderNonAuthorizedUsersResponse
          | ICreateNewOrderAuthorizedUsersResponse)
  ) => void;
  isAuthorized: boolean;
}

const UserOrderData: React.FC<IUserOrderDataProps> = (props) => {
  const { user, currentCity, checkoutData } = useAppSelector(
    (state) => state.user
  );
  const [isPromocodeSelected, setIsPromocodeSelected] = useState(
    !!checkoutData.promoCode
  );
  const [inputValue, setInputValue] = useState(checkoutData.promoCode || "");
  const [notValidCodeMessage, setNotValidCodeMessage] = useState("");
  const [showShadow, setShowShadow] = useState({
    top: false,
    bottom: false
  });
  const [isSendingOrder, setIsSendingOrder] = useState(false);

  Date.prototype.addDays = function (days: number) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const productsMainPrice = useMemo(() => {
    return checkoutData.items.reduce((acc, cur) => {
      acc += cur.offer.price * cur.count;

      return acc;
    }, 0);
  }, [checkoutData.items]);

  const productsDiscount = useMemo(() => {
    return checkoutData.items.reduce((acc, cur) => {
      acc +=
        cur.offer.price * cur.count -
        (cur.offer.price_sale || cur.offer.akcia) * cur.count;
      return acc;
    }, 0);
  }, [checkoutData.items]);

  const resultPrice = useMemo(() => {
    const productsSum = checkoutData.items.reduce((acc, cur) => {
      acc += cur.calculated_amount;
      return acc;
    }, 0);

    if (checkoutData.providerInfo?.amount) {
      const totalSum =
        Number(productsSum) + Number(checkoutData.providerInfo?.amount);
      return totalSum;
    }
    return formatPrice(productsSum);
  }, [checkoutData.items, checkoutData.providerInfo]);

  const isShowScrollbar = useMemo(() => {
    return checkoutData.items.length > MAX_DISPLAY_PRODUCTS;
  }, [checkoutData.items]);

  useEffect(() => {
    setShowShadow((prev) => {
      return {
        ...prev,
        bottom: checkoutData.items.length > MAX_DISPLAY_PRODUCTS
      };
    });
  }, [checkoutData.items.length]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onSendPromocode = async () => {
    if (!inputValue || !checkoutData.items.length) {
      return;
    }

    try {
      const selectedProductIds = checkoutData.items.map(
        (item) => item.offer.id
      );
      const isPromocode = !!inputValue.trim().length;
      if (!isPromocode) {
        return;
      }

      const options: IGetUserCartRequest = {
        calculate_orders: selectedProductIds.join(","),
        use_promo_code: inputValue.trim()
      };

      const usePromocodeResponse = dispatch(getUserCart(options)).unwrap();
      usePromocodeResponse
        .then((response) => {
          if (isError(response)) {
            throw new CustomError(response.message, response?.code);
          }
          dispatch(setCheckoutItems(selectedProductIds));
          setIsPromocodeSelected(true);
          dispatch(setPromocode(inputValue));
        })
        .catch((error) => {
          const errorData = getErrorData(error);
          setNotValidCodeMessage(errorData.message);
        });
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(
        "Произошла ошибка при применении промокода",
        errorData.message
      );
      toast.error("Произошла ошибка при применении промокода");
      setIsPromocodeSelected(false);
      setNotValidCodeMessage("Произошла ошибка при применении промокода");
    }
  };

  const onRemovePromocode = async () => {
    try {
      const selectedProductIds = checkoutData.items.reduce((acc, cur) => {
        acc.push(cur.offer.id);
        return acc;
      }, []);

      const options: IGetUserCartRequest = {
        calculate_orders: selectedProductIds.join(",")
      };
      const deletePromocodeResponse = dispatch(getUserCart(options)).unwrap();
      deletePromocodeResponse
        .then((response) => {
          if (isError(response)) {
            throw new CustomError(response.message, response?.code);
          }
          dispatch(setCheckoutItems(selectedProductIds));
          setIsPromocodeSelected(false);
          setInputValue("");
          dispatch(setPromocode(""));
          setNotValidCodeMessage("");
        })
        .catch((error) => {
          const errorData = getErrorData(error);
          setNotValidCodeMessage(errorData.message);
        });
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(
        "Произошла ошибка при удалении промокода",
        errorData.message
      );
      toast.error("Произошла ошибка при удалении промокода");
      setIsPromocodeSelected(false);
      setNotValidCodeMessage("Произошла ошибка при удалении промокода");
    }
  };

  const productsPromoDiscount = useMemo(() => {
    return checkoutData.items.reduce((acc, cur) => {
      if (cur.used_promo_code) {
        acc += cur.discount_by_promo_code;
      }

      return acc;
    }, 0);
  }, [checkoutData.items]);

  const onNavigateHandler = () => {
    const ids = checkoutData.items.map((item) => {
      return item.offer.id;
    });

    dispatch(selectItemsInCartByIds(ids));
    navigate("/cart");
  };

  const onShowScrollBarShadows = ({
    showTopShadow,
    showBottomShadow
  }: {
    showTopShadow: boolean;
    showBottomShadow: boolean;
  }) => {
    setShowShadow({
      top: showTopShadow,
      bottom: showBottomShadow
    });
  };

  const addNewOrder = async () => {
    try {
      setIsSendingOrder(true);
      const offers = checkoutData.items.map((item) => {
        return {
          id: item.offer.id,
          count: item.count
        };
      });

      const deliveryType =
        checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

      const recipientFullName =
        `${checkoutData.recipient.surname ?? ""} ${checkoutData.recipient.name ?? ""}`.trim();

      // Different options to clarify what section wasn't selected or filled
      if (!offers.length) {
        toast("Не выбран товар. Пожалуйста, добавьте товар в корзину.");
        return;
      }

      if (!checkoutData.recipient.name) {
        toast("Пожалуйста, укажите имя получателя");
        return;
      }

      const isNameValid = await userContactsSchema.fields.name.isValid(
        checkoutData.recipient.name
      );
      if (!isNameValid) {
        toast("Имя не может состоять только из пробелов");
        return;
      }

      if (checkoutData.recipient.surname) {
        const isSurnameValid = await userContactsSchema.fields.surname.isValid(
          checkoutData.recipient.surname
        );
        if (!isSurnameValid) {
          toast("Фамилия не может состоять только из пробелов");
          return;
        }
      }

      if (!checkoutData.recipient.phone) {
        toast("Пожалуйста, укажите номер телефона получателя");
        return;
      }
      if (
        checkoutData.recipient.phone &&
        !isValidPhone(checkoutData.recipient.phone)
      ) {
        toast(
          <div>
            Пожалуйста, укажите правильный номер телефона.
            <br />
            Пример: +7(111)111-11-11
          </div>
        );
        return;
      }

      if (
        checkoutData.recipient.email &&
        !isValidEmail(checkoutData.recipient.email)
      ) {
        toast(
          <div>
            Пожалуйста, укажите правильный email.
            <br />
            Пример: example@mail.ru
          </div>
        );
        return;
      }
      if (!checkoutData.recipient.address) {
        toast("Пожалуйста, укажите адрес в разделе \"Способ получения\"");
        return;
      }

      if (!checkoutData.paymentMethod) {
        toast("Пожалуйста, выберите способ оплаты");
        return;
      }

      const options: ICreateNewOrderAuthorizedUsersRequest = {
        offers,
        recipient: {
          name: recipientFullName,
          phone: checkoutData.recipient.phone,
          email: checkoutData.recipient.email,
          address: checkoutData.recipient.address,
          pvz_id:
            deliveryType === "pickup" ? checkoutData.recipient.pvz_id : null
        },
        delivery: {
          ...checkoutData.providerInfo,
          type: deliveryType,
          pvz_id: checkoutData.providerInfo.pvz_id
        },
        basket: false
      };

      if (props.isAuthorized) {
        options.promo_code = checkoutData.promoCode;
        options.basket = true;
      }

      const data = await (props.isAuthorized
        ? userApi.addNewOrderForAuthorizedUsers(options)
        : userApi.addNewOrderForNonAuthorizedUsers(options));
      if (isError(data)) {
        toast.error(data.message);
        return;
      }

      if (!props.isAuthorized) {
        const cart: Offer[] = JSON.parse(getValueFromStorage("cart") || "[]");
        const updatedCart = cart.filter(
          (item) => !offers.some((offer: Offer) => offer.id === item.id)
        );
        setValueInStorage({ key: "cart", value: JSON.stringify(updatedCart) });

        dispatch(getUserCart({}));
      }

      props.setOrderData(data.response);
      props.setIsPay(true);

      metric.newOrderCreateMetric(resultPrice, "RUB");

      // yandex ecommerce 'purchase'
      const coupon = isResponseAuthType(data.response)
        ? data.response?.promocode?.name
        : "";
      const purchaseData = {
        ecommerce: {
          currencyCode: "RUB",
          [YandexActionTypeEnum.purchase]: {
            actionField: {
              id: data.response.uuid,
              coupon: coupon,
              // eslint-disable-next-line camelcase
              goal_id: YANDEX_CREATE_ORDER_GOAL_ID
            },
            products: data.response.orderOffers.map((orderItem) => {
              const isBrand =
                orderItem.offer.catalog.brands &&
                !!orderItem.offer.catalog.brands.length;
              return {
                id: orderItem.offer.catalog.id.toString(),
                name: orderItem.offer.catalog.name,
                category: orderItem.offer.catalog.category.name,
                brand: isBrand
                  ? orderItem.offer.catalog.brands[0].brand.name
                  : "",
                price: orderItem.sale_price || orderItem.site_price,
                quantity: orderItem.count,
                variant: `${orderItem.offer.name}, ID: ${orderItem.offer.id}`,
                discount: orderItem.site_price - orderItem.sale_price,
                coupon: coupon
              };
            })
          }
        }
      };

      await handleYandexEcommerce(purchaseData);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Произошла ошибка при создании заказа", err);
    } finally {
      setIsSendingOrder(false);
    }
  };

  const showDeliveryDate = useMemo(() => {
    const isDaysMin =
      checkoutData.providerInfo?.daysMin !== undefined &&
      typeof checkoutData.providerInfo.daysMin === "number";
    const isDaysMax =
      checkoutData.providerInfo?.daysMax !== undefined &&
      typeof checkoutData.providerInfo.daysMax === "number";
    return isDaysMin && isDaysMax;
  }, [checkoutData.providerInfo]);

  const isSameDeliveryDate = useMemo(() => {
    return (
      showDeliveryDate &&
      checkoutData.providerInfo?.daysMin === checkoutData.providerInfo?.daysMax
    );
  }, [
    checkoutData.providerInfo?.daysMax,
    checkoutData.providerInfo?.daysMin,
    showDeliveryDate
  ]);

  const subtitle = useMemo(() => {
    const name = checkoutData.recipient.name || "";
    const surname = checkoutData.recipient.surname || "";
    return name && surname ? `${name} ${surname}` : name || surname || "-";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkoutData.recipient.name,
    checkoutData.recipient.surname,
    user.name,
    user.surname
  ]);

  const address = checkoutData.recipient.address
    ? checkoutData.recipient.address
    : "";

  return (
    <div className={styles.root}>
      <div className={styles["title-wrapper"]}>
        <h4 className={styles.title}>Ваш заказ</h4>
        <button className={styles["change-order"]} onClick={onNavigateHandler}>
          Изменить заказ
        </button>
      </div>
      <div
        className={cn(
          styles.wrapper,
          { [styles["products-wrapper--top-shadow"]]: showShadow.top },
          { [styles["products-wrapper--bottom-shadow"]]: showShadow.bottom }
        )}
      >
        <div className={cn(styles["products-wrapper"])}>
          <CustomScrollbar
            childrenClassName={styles["products"]}
            scrollBarTrackStyles={styles.track}
            isShowScrollbar={isShowScrollbar}
            onShowScrollBarShadows={onShowScrollBarShadows}
          >
            {!checkoutData.items.length ? (
              <h3 className={styles["products__title"]}>
                Нет товаров для оформления
              </h3>
            ) : (
              checkoutData.items.map((item) => {
                return <CheckoutOrderItem key={item.offer.id} item={item} />;
              })
            )}
          </CustomScrollbar>
        </div>
      </div>
      <div className={styles.line} />
      {props.isAuthorized && (
        <PromocodeInput
          onRemovePromocode={onRemovePromocode}
          isPromocodeSelected={isPromocodeSelected}
          inputValue={inputValue}
          isNotValidCode={notValidCodeMessage}
          setIsNotValidCode={setNotValidCodeMessage}
          onChange={onChangeHandler}
          onSendPromocode={onSendPromocode}
          buttonClassname={styles.promocode__button}
        />
      )}
      <div className={styles["data-wrapper"]}>
        <div className={styles["user-data"]}>
          <OrderStringItem title="Получатель:" subtitle={subtitle} />
          <OrderStringItem
            title="Номер телефона:"
            subtitle={checkoutData.recipient.phone || "-"}
          />
          <OrderStringItem
            title="Населенный пункт:"
            subtitle={checkoutData.orderCity || currentCity || "Не выбрано"}
          />
          <OrderStringItem
            title="Способ получения:"
            subtitle={checkoutData.wayToGet || "Не выбрано"}
          />
          <OrderStringItem title="Адрес:" subtitle={address || "Не выбран"} />
        </div>
        <div className={styles["price-wrapper"]}>
          <OrderStringItem
            title="Стоимость товаров:"
            subtitle={`${formatPrice(productsMainPrice) || 0} ₽`}
            subtitleClassname={styles.price}
          />
          <OrderStringItem
            title="Скидка:"
            subtitle={`${formatPrice(productsDiscount) || 0} ₽`}
            subtitleClassname={styles.price}
          />
          {props.isAuthorized && (
            <OrderStringItem
              title="Скидка по промокоду:"
              subtitle={`${formatPrice(productsPromoDiscount) || 0} ₽`}
              subtitleClassname={styles.price}
            />
          )}
          <OrderStringItem
            title="Доставка:"
            subtitle={`${formatPrice(checkoutData.providerInfo?.amount) || 0} ₽`}
            subtitleClassname={styles.price}
          />
          <OrderStringItem
            title="Всего к оплате:"
            subtitle={`${resultPrice || 0} ₽`}
            subtitleClassname={styles.price}
          />
        </div>
      </div>
      <p className={styles.date}>
        Срок доставки:
        {showDeliveryDate ? (
          isSameDeliveryDate ? (
            <span>
              {" "}
              {"~"} {checkoutData.providerInfo.daysMin} д.
            </span>
          ) : (
            <span>
              {" "}
              {checkoutData.providerInfo.daysMin} -{" "}
              {checkoutData.providerInfo.daysMax} д.
            </span>
          )
        ) : (
          <span> доступно после выбора адреса доставки</span>
        )}
      </p>
      <span className={styles.warning}>*с момента готовности заказа</span>
      <div className={styles.offer}>
        <p className={styles.offer__description}>
          <span>
            Нажимая на кнопку &quot;оформить заказ&quot;, вы подтверждаете, что
            ознакомились и согласны с условиями
          </span>
          <Link to="/info/publichnaya-oferta" className={styles.offer__link}>
            &#8201;публичной оферты&#8201;
          </Link>
          <span>PARFUMART.RU</span>
        </p>
      </div>

      <CustomButton
        title="Оформить заказ"
        className={styles["checkout-button"]}
        onClick={addNewOrder}
        isDisabled={isSendingOrder}
      />
    </div>
  );
};

export default UserOrderData;
