import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const CustomButton = (props) => {
  return (
    <button
      className={cn(
        styles.root,
        props.className,
        { [styles.white]: props.isWhite },
        { [styles.black]: props.isBlack },
        { [styles.disable]: props.isDisabled },
      )}
      onClick={props.onClick}
      type={props.type}
      disabled={props.isDisabled}
    >
      {props.title}
    </button>
  );
};

const propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  isWhite: PropTypes.bool,
  isBlack: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

CustomButton.propTypes = propTypes;

export default CustomButton;