import { SkeletonImg } from "components/UI/Skeleton/SkeletonImg/SkeletonImg";
import styles from "./styles.module.scss";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";

export const PaymentMethodSkeleton: React.FC = () => {
  return (
    <div className={styles["skeleton-wrapper"]}>
      <SkeletonImg
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "38px",
          maxHeight: "23px",
          borderRadius: "6px"
        }}
      />
      <SkeletonLine />
    </div>
  );
};
