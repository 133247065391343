import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import SectionTitle from "../../components/SectionTitle/SectionTitle";
import PaymentStatusNotification from "./components/PaymentStatusNotification/PaymentStatusNotification";

import styles from "./styles.module.scss";
import { Helmet } from "react-helmet";

const PaymentStatusPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderNumber = queryParams.get("order_number");
  const paymentTime = queryParams.get("payment_time");
  const status = queryParams.get("status");

  const dateOfPaymentCreation = useMemo(() => {
    if (!paymentTime) {
      return "";
    }

    const date = new Date(paymentTime);
    return date.toLocaleString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }, [paymentTime]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.root}>
        <div className={styles["title-wrapper"]}>
          <Link to="/cart" className={styles.link}>
            {"< вернуться в корзину"}
          </Link>
          <SectionTitle title="Статус оплаты заказа" />
        </div>
        <div className={styles["status-wrapper"]}>
          <PaymentStatusNotification
            orderNumber={orderNumber || ""}
            date={dateOfPaymentCreation}
            status={status}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentStatusPage;