/**
 * Retrieves all brands from the backend API.
 *
 * @return {Promise} A promise that resolves to the JSON representation of the brands.
 */

const getAllBrands = async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/brands`, {
    method: "GET",
  });

  return res.json();
};

/**
 * Retrieves a list of brands starting with the specified letter.
 *
 * @param {array} letters
 * @return {Promise<object>} A Promise that resolves to the JSON response containing the list of brands.
 */

const getBrandsByLetters = async (letters) => {
  const lettersString = letters.join(",");
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/public/brands/v2/symbols?symbols=${lettersString}`,
    {
      method: "GET",
    },
  );

  return res.json();
};

export const brandsApi = {
  getAllBrands,
  getBrandsByLetters,
};
