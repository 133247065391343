import { useState } from "react";

import phoneIcon from "../../../../../../assets/header_icons/phone-icon.svg";

import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContactsInnerContent from "../../../../../../components/ContactsInnerContent";

import styles from "./styles.module.scss";

const ContactsTab = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className={styles.root}>
      <button onClick={() => setIsOpen(!isOpen)} className={styles.tab__button}>
        <img
          src={phoneIcon}
          alt="phone_icon"
          className={styles["tab__phone-icon"]}
        />
      </button>
      <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ContactsInnerContent />
      </BottomSheet>
    </li>
  );
};

export default ContactsTab;
