import { Link } from "react-router-dom";
import CustomLink from "../../../../components/CustomLink/CustomLink";

import styles from "./styles.module.scss";
import { IRestoreAction } from "types/IRestoreAction";

const RESTORE_ACTIONS: IRestoreAction[] = [
  {
    id: 0,
    title: "Звонок мне с помощью 4-х цифр входящего звонка",
    path: "/sign-in/restore/by-phone/call-me"
  }
  // {
  //   id: 1,
  //   title: "Позвоню сам на бесплатный номер",
  //   path: "/sign-in/restore/by-phone/call",
  // },
  // {
  //   id: 2,
  //   title: "СМС",
  //   path: "/sign-in/restore/by-phone/sms",
  // },
];

const RestoreByPhone: React.FC = () => {
  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Восстановление пароля</h2>
      {RESTORE_ACTIONS.map((item) => {
        return (
          <CustomLink
            key={item.id}
            path={item.path}
            title={item.title}
            className={styles["link-primary"]}
          />
        );
      })}
      <div className={styles["links-wrapper"]}>
        <Link to="/sign-in" replace className={styles["link-secondary"]}>
          {"< Шаг назад"}
        </Link>
        <Link
          to="/sign-in/restore/by-email"
          replace
          className={styles["link-secondary"]}
        >
          С помощью e-mail
        </Link>
      </div>
    </main>
  );
};

export default RestoreByPhone;
