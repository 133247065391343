
const getAllFilters = (options) => {

  return new Promise((resolve, reject) => {

    setTimeout(async () => {
      try {

        const { signal, ...rest } = options;
        const formattedOptions = Object.keys(rest)?.map((item, idx) => {
          if (typeof rest[item] === "undefined") {
            return "";
          }
          if (idx === 0) {
            return `?${item}=${rest[item]}`;
          }
          return `&${item}=${rest[item]}`;
        });

        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/filters/v2${formattedOptions.join("")}`, {
          method: "GET",
          signal,
        });

        resolve(res.json());
      } catch (error) {
        reject(error);
      }
    }, 1000);
  });
};

export const filtersApi = {
  getAllFilters
};