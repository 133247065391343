import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import checkIcon from "../../assets/icons/png/chech_mark-icon.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const CustomNotification = (props) => {
  return(
    <CSSTransition
      in={props.showNotification}
      timeout={100}
      classNames={{
        enterActive: styles["notification-enter-active"],
        enterDone: styles["notification-enter-done"],
        exitActive: styles["notification-exit-active"],
        exitDone: styles["notification-exit-done"]
      }}
      mountOnEnter
    >
      <div className={cn(styles.notification, props.className)}>
        <img
          src={props.imgSrc ?? checkIcon}
          alt="notification-icon"
          className={styles.icon}
        />
        <span className={styles.title}>
          {props.title ?? "Данные сохранены"}
        </span>
      </div>
    </CSSTransition>
  );
};

const propTypes = {
  showNotification: PropTypes.bool,
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  className: PropTypes.string,
};

CustomNotification.propTypes = propTypes;

export default CustomNotification;