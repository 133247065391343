import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const BurgerButton = (props) => {
  return (
    <button
      className={cn(styles.root, props.containerStyles)}
      onClick={props.onClick}
    >
      {Array(3)
        .fill(1)
        .map((_, idx) => {
          return <div key={idx} className={styles.line} />;
        })}
    </button>
  );
};

const propTypes = {
  onClick: PropTypes.func,
  containerStyles: PropTypes.string,
};

BurgerButton.propTypes = propTypes;

export default BurgerButton;
