import ProviderItem from "../ProviderTitleItem/ProviderItem";

import styles from "./styles.module.scss";
import { ProvidersInfo } from "types/IProviderInfo";
import { IChosenProvider } from "utils/hooks/useYandexMap";

interface IProviderTitleItemProps {
  list: IChosenProvider[];
  providersInfo: ProvidersInfo | null;
  onSelectProvider: (provider: IChosenProvider | null) => void;
  chosenProvider: IChosenProvider | null;
}

const ProviderTitleItem: React.FC<IProviderTitleItemProps> = (props) => {
  const uniqueList: IChosenProvider[] = (props.list || []).filter(
    (item: IChosenProvider, index: number, self) =>
      index === self.findIndex((t) => t.type === item.type)
  );

  return (
    <ul className={styles.root}>
      {uniqueList.map((item, i) => {
        return (
          <ProviderItem
            key={`${item.type}_${i}`}
            item={item}
            providersInfo={props.providersInfo}
            onSelectProvider={props.onSelectProvider}
            chosenProvider={props.chosenProvider}
          />
        );
      })}
    </ul>
  );
};

export default ProviderTitleItem;
