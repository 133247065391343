import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import PersonalAreaTab from "../../../pages/Main/components/MobileBottomNavigation/components/PersobalAreaTab";
import SelectCityTab from "../../../pages/Main/components/MobileBottomNavigation/components/SelectCityTab";
import boxIcon from "../../../assets/header_icons/box-icon.png";
import paymentIcon from "../../../assets/header_icons/payment-icon2.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

const MobileNavigationAdditionalBlock = (props) => {
  const { currentCity } = useSelector((state) => state.user);

  return (
    <ul className={styles.list}>
      <SelectCityTab
        buttonStyles={styles.list__item}
        iconStyles={cn(styles.item__icon, styles["item__icon-geo"])}
        cb={props.onToggleIsOpen}
      >
        <p className={styles.item__title}>{currentCity ?? "Город не выбран"}</p>
      </SelectCityTab>
      <PersonalAreaTab
        buttonStyles={styles.list__item}
        iconStyles={cn(styles.item__icon, styles["item__icon-personal-aria"])}
        cb={props.onToggleIsOpen}
      >
        <p className={styles.item__title}>Вход/Регистрация</p>
      </PersonalAreaTab>
      <li className={styles["list__item-wrapper"]}>
        <Link to="/info/oplata" className={styles.list__item}>
          <img src={paymentIcon} alt="payment_icon" className={styles.item__icon_pay} />
          <p className={styles.item__title}>Оплата</p>
        </Link>
      </li>
      <li className={styles["list__item-wrapper"]}>
        <Link to="/info/dostavka" className={styles.list__item}>
          <img src={boxIcon} alt="box_icon" className={styles.item__icon} />
          <p className={styles.item__title}>Доставка</p>
        </Link>
      </li>
    </ul>
  );
};

const propTypes = {
  onToggleIsOpen: PropTypes.func.isRequired,
};

MobileNavigationAdditionalBlock.propTypes = propTypes;

export default MobileNavigationAdditionalBlock;
