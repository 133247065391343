import { SkeletonLine } from "../SkeletonLine/SkeletonLine";
import styles from "./styles.module.scss";

export const SkeletonProductDescription = () => {
  return (
    <div className={styles.root}>
      <div className={styles["description-header"]}>
        <button className="description-header__button">
          <SkeletonLine style={{ width: "150px", height: "40px" }} />
        </button>
      </div>

      <div className={styles.description}>
        <SkeletonLine style={{ width: "80%", height: "20px" }} />
        <SkeletonLine style={{ width: "80%", height: "20px" }} />
        <SkeletonLine style={{ width: "80%", height: "20px" }} />
        <SkeletonLine style={{ width: "80%", height: "20px" }} />
        <SkeletonLine style={{ width: "80%", height: "20px" }} />
      </div>

      <ul className={styles["properties-list"]}>
        {[...Array(10)].map((_, index) => (
          <li key={index} className={styles["properties-list__item"]}>
            <SkeletonLine style={{ width: "100px", height: "20px" }} />
            <SkeletonLine style={{ width: "calc(100% - 120px)", height: "20px" }} />
          </li>
        ))}
      </ul>

      <div className={styles["buttons-wrapper"]}>
        <SkeletonLine style={{ width: "200px", height: "40px" }} />
        <SkeletonLine style={{ width: "200px", height: "40px" }} />
      </div>
    </div>
  );
};
