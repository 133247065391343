import { PropTypes } from "prop-types";
import { useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";


export const CustomExpandingText = ({ text }) => {
  const isShortMode = text.length >= 450;
  const [showMore, setShowMore] = useState(!isShortMode);

  return (
    <>
      <div className={styles.root}>
        <p className={cn(styles.text, showMore ? styles["text-showed"] : styles["text-short"])}>{text}</p>
        <div className={styles["show-more"]}>
          {isShortMode && (
            !showMore ?
              <button className={styles.button} onClick={() => setShowMore(true)}>Показать больше</button> :
              <button className={styles.button} onClick={() => setShowMore(false)}>Показать меньше</button>
          )}
        </div>
      </div >
    </>
  );
};

const propTypes = {
  text: PropTypes.string,
};

CustomExpandingText.propTypes = propTypes;
