import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { userApi } from "../../../../api/userApi";
import { ORDER_STATUSES } from "../../../../utils/constants";

import OrdersButtons from "./components/OrdersButtons/OrdersButtons";
import OrderStatus from "./components/OrderStatus/OrderStatus";
import OrderUserInfo from "./components/OrderUserInfo/OrderUserInfo";
import OrderItems from "./components/OrderItems/OrderItems";

import styles from "./styles.module.scss";
import {
  IGetUserOrderDataItem,
  IGetUserOrderDataRequest
} from "types/IGetUserOrderData";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "utils/getErrorData";
import { toast } from "react-toastify";
import LoadingItem from "components/LoadingItem/LoadingItem";
import CustomButton from "components/CustomButton/CustomButton";
import Pagination from "components/Pagination/Pagination";

const DEFAULT_LIMIT = 5;
const SCROLL_OFFSET_TOP = 100;

export interface IButtonsValue {
  id: number;
  title: string;
  statuses: string[];
}

const buttonsValues: IButtonsValue[] = [
  {
    id: 0,
    title: "Выполненные",
    statuses: [
      ORDER_STATUSES.received.value,
      ORDER_STATUSES["part_received"].value
    ]
  },
  {
    id: 1,
    title: "Отменённые",
    statuses: [ORDER_STATUSES.canceled.value]
  },
  {
    id: 2,
    title: "Текущие",
    statuses: [
      ORDER_STATUSES.created.value,
      ORDER_STATUSES.delivered.value,
      ORDER_STATUSES.delivering.value,
      ORDER_STATUSES["wait_received"].value,
      ORDER_STATUSES.packed.value,
      ORDER_STATUSES.packing.value,
      ORDER_STATUSES.confirmed.value,
      ORDER_STATUSES.accepted.value
    ]
  }
];

const UserOrders: React.FC = () => {
  const [userOrders, setUserOrders] = useState<IGetUserOrderDataItem[]>([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [statusId, setStatusId] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ordersLimit, setOrdersLimit] = useState(DEFAULT_LIMIT);
  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const [ordersAmount, setOrdersAmount] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ordersOffset, setOrdersOffset] = useState(0);

  const contentRef = useRef<HTMLDivElement | null>(null);
  const isShowMoreAction = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsOrdersLoading(true);
        const options: IGetUserOrderDataRequest = {
          limit: ordersLimit,
          offset: ordersOffset,
          status: buttonsValues[statusId].statuses.join()
        };

        const data = await userApi.getUserOrders(options);

        if (isError(data)) {
          throw new CustomError(data.message, data?.code);
        }

        if (data.response.items === null) {
          setUserOrders([]);
          return;
        }

        setOrdersAmount(data.response.meta.total);
        if (isShowMoreAction.current) {
          setUserOrders((prev) => [...prev, ...data.response.items]);
          return;
        }
        setUserOrders(data.response.items);
      } catch (err) {
        const errorData = getErrorData(err);
        toast.error(errorData.message);
        // eslint-disable-next-line no-console
        console.error(
          "При получении заказов пользователя произошла ошибка",
          err
        );
      } finally {
        setIsOrdersLoading(false);
        isShowMoreAction.current = false;
      }
    })();
  }, [ordersLimit, ordersOffset, statusId]);

  const onScrollContentToTop = useCallback(() => {
    const contentOffsetTop = contentRef?.current.offsetTop;
    window.scrollTo({
      top: contentOffsetTop - SCROLL_OFFSET_TOP,
      behavior: "smooth"
    });
  }, []);

  const shouldRenderShowMoreButton = useMemo(() => {
    const isProductsEnough = userOrders.length >= ordersLimit;
    const isLastPage =
      Math.ceil(ordersAmount / ordersLimit) === currentOrderPage;
    return isProductsEnough && !isLastPage;
  }, [userOrders.length, ordersLimit, ordersAmount, currentOrderPage]);

  const handlePageChange = (page: number | string) => {
    const offsetValue = Number(page) * ordersLimit - ordersLimit;
    setCurrentOrderPage(Number(page));
    setOrdersOffset(offsetValue);
    onScrollContentToTop();
  };

  const handleShowMoreOrders = () => {
    const newOffset = ordersOffset + ordersLimit;

    if (ordersAmount < newOffset) return;

    isShowMoreAction.current = true;
    setCurrentOrderPage(currentOrderPage + 1);
    setOrdersOffset(newOffset);
  };

  const handleSelectOrder = (id: number) => {
    if (id === statusId) {
      return;
    }

    setCurrentOrderPage(1);
    setOrdersOffset(0);
    setStatusId(id);
  };

  return (
    <section className={styles.root} ref={contentRef}>
      <OrdersButtons
        setIsOrdersLoading={setIsOrdersLoading}
        selectedOrderId={statusId}
        onSelectOrder={handleSelectOrder}
        buttonsValues={buttonsValues}
      />
      {!userOrders?.length ? (
        <h3 className={styles.title}>Список пуст</h3>
      ) : (
        userOrders.map((item) => {
          return (
            <div key={item.id} className={styles["order-item"]}>
              <OrderStatus
                orderId={item.id}
                date={item.created_at}
                status={item.status}
              />
              <OrderUserInfo
                orderRecipient={item.orderRecipient}
                deliveryProvider={item.delivery_provider}
                // TODO - отпределить дату доставки передавать строку даты
                date={""}
                trackNumber={item.delivery_track_number}
              />
              <OrderItems
                orderStatusId={statusId}
                offers={item.orderOffers}
                deliveryPrice={item.delivery_amount}
                paymentStatus={item.payment_status}
                // TODO - определить способ оплаты пользователя
                paymentMethod={""}
              />
            </div>
          );
        })
      )}
      {isOrdersLoading && (
        <LoadingItem
          containerStyles={styles.loading}
          titleStyles={styles.loading__title}
        />
      )}
      {!!userOrders.length && (
        <div className={styles["show-block"]}>
          {shouldRenderShowMoreButton && (
            <CustomButton
              title="Показать еще"
              onClick={handleShowMoreOrders}
              className={styles["show-block__button"]}
              isDisabled={isOrdersLoading}
            />
          )}
          <Pagination
            totalCount={ordersAmount}
            currentPage={currentOrderPage}
            pageSize={ordersLimit}
            onPageChange={handlePageChange}
            containerStyles={
              !shouldRenderShowMoreButton
                ? styles["show-block__pagination"]
                : ""
            }
          />
        </div>
      )}
    </section>
  );
};

export default UserOrders;
