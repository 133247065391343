import { useState, useRef} from "react";

import cn from "classnames";

import searchIcon from "../../../../../../../assets/header_icons/search-icon.svg";
import { ReactComponent as CrossIcon} from "../../../../../../../assets/icons/svg/cross-icon.svg";
import { IFilterWithKeyObj } from "store/catalogV3";


import { isFilterCommon, isFilterUncommon } from "utils/helpers/filtersTypeGuards";
import { IFilterCommon, IFilterUncommon } from "types/IFiltersV3";

import styles from "./styles.module.scss";

interface ISearchInputV3Props {
    containerStyles?: string;
    filterWithKey: IFilterWithKeyObj;
    changeCurrentFilters: ({ filterWithKey, isError }: {filterWithKey: IFilterWithKeyObj; isError: boolean}) => void;
}


const SearchInputV3: React.FC<ISearchInputV3Props> = ({
  containerStyles,
  filterWithKey,
  changeCurrentFilters
}) => {

  const inputRef = useRef<HTMLInputElement | null>(null);
  const setInputFocus = () => {
    inputRef?.current &&  inputRef?.current.focus();
  };

  const [searchValue, setSearchValue] = useState<string>("");


  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

    const regex = new RegExp(`${e.target.value}`, "gi");
    const currentFilters = filterWithKey.subFilters.filter((item) => {
      const matchesSearch = "name" in item ? item.name.match(regex) : item.value.match(regex);
      return matchesSearch;
    });

    setSearchValue(e.target.value);

    if (currentFilters.length > 0) {
      if (isFilterCommon(currentFilters[0])) {
        changeCurrentFilters({
          filterWithKey: {
            ...filterWithKey,
            subFilters: currentFilters as IFilterCommon[]
          },
          isError: !currentFilters.length && !!e.target.value,
        });

        return;
      }

      if (isFilterUncommon(currentFilters[0])) {
        changeCurrentFilters({
          filterWithKey: {
            ...filterWithKey,
            subFilters: currentFilters as IFilterUncommon[]
          },
          isError: !currentFilters.length && !!e.target.value,
        });
        return;
      }
    }
  };

  const onResetSearch = () => {
    changeCurrentFilters({ filterWithKey, isError: false });
    setSearchValue("");
  };

  return(
    <div className={cn(styles.root, containerStyles)}>
      <img
        src={searchIcon}
        alt="search-icon"
        className={styles["search-icon"]}
        onClick={(e: React.MouseEvent<HTMLImageElement>) => {
          e.preventDefault();
          setInputFocus();
        }}
      />
      <input
        ref={inputRef}
        type="text"
        value={searchValue}
        className={styles.input}
        onChange={onChangeHandler}
        onFocus={onChangeHandler}
      />
      {
        !!searchValue.length &&
          <div
            className={styles["cross-button_wrapper"]}
            onClick={onResetSearch}
          >
            <CrossIcon className={styles["cross-button"]} />
          </div>
      }
    </div>
  );
};

export default SearchInputV3;