import { FC, useState, useEffect } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

interface ProgressiveImageProps {
  lowQualitySrc?: string;
  highQualitySrc?: string;
  alt: string;
  width?: number;
  height?: number;
  errorImage?: string;
  className?: string;
}

const ProgressiveImage: FC<ProgressiveImageProps> = ({
  lowQualitySrc,
  highQualitySrc,
  alt,
  width,
  height,
  errorImage,
  className
}) => {
  const initialSrc = lowQualitySrc || highQualitySrc;
  const [src, setSrc] = useState(initialSrc);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!highQualitySrc && !lowQualitySrc) {
      setSrc("");
      return;
    }

    const img = new Image();
    img.src = highQualitySrc || "";
    img.onload = () => {
      setSrc(highQualitySrc);
      setIsLoaded(true);
    };
    img.onerror = () => {
      // eslint-disable-next-line no-console
      console.error("При загрузке бенера произошла ошибка URL:", src);
      if (errorImage) {
        setSrc(errorImage);
      }
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highQualitySrc, lowQualitySrc]);

  if (!src) {
    return null;
  }

  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={cn(styles.image, className, {
        [styles.loaded]: isLoaded,
        [styles.loading]: !isLoaded
      })}
    />
  );
};

export default ProgressiveImage;
