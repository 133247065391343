import { nanoid } from "@reduxjs/toolkit";

import SectionTitle from "../../../../components/SectionTitle";
import MainSlider from "../../../../components/MainSlider";
import AboutUsItem from "./AboutUsItem";

import styles from "./styles.module.scss";

const MOCK_ARRAY = [
  {
    id: nanoid(),
    username: "Олег",
    date: "09.02.2023",
    city: "г.Москва",
    description: "Отзыв был оставлен на сайте Otzovik.ru",
    comment:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam esse, quod autem officia et ipsam itaque voluptatem distinctio impedit deserunt ratione totam quaerat, atque, nisi consequuntur ipsa architecto earum quas. Eum, sit quis. Ut quaerat et laboriosam obcaecati. Ipsa non temporibus soluta laboriosam aspernatur ipsum. Minima molestias sit nulla dignissimos suscipit. Harum, illo aut! Expedita libero laudantium amet quam quos?",
  },
  {
    id: nanoid(),
    username: "Анна",
    date: "10.02.2023",
    city: "г.Краснодар",
    description: "Отзыв был оставлен на сайте Otzovik.ru",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore debitis veniam ducimus adipisci dicta eaque accusantium vitae iusto veritatis repellendus, sapiente facilis minus incidunt? Vitae dolorum maiores natus doloremque numquam!",
  },
  {
    id: nanoid(),
    username: "Петр",
    date: "11.09.2023",
    city: "г.Севастополь",
    description: "Отзыв был оставлен на сайте Otzovik.ru",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dolorem odit quibusdam cupiditate explicabo, temporibus tempora delectus magnam minima, exercitationem ex aperiam quo eius, esse nostrum reiciendis. Accusamus, suscipit asperiores? Exercitationem reprehenderit, accusamus aspernatur nisi ipsa adipisci veniam in sit possimus minima esse debitis odio labore praesentium placeat error perspiciatis, est ad tempore. Temporibus perspiciatis recusandae, obcaecati animi natus tempora! Officia ducimus corporis, illo quod iure, nesciunt at quisquam neque doloremque nisi quibusdam nostrum eligendi. Nobis sapiente delectus corrupti reiciendis veniam dignissimos distinctio aut inventore praesentium. Recusandae cum sapiente porro! Accusantium voluptatem ducimus, illum incidunt iure assumenda sunt veniam voluptatibus aspernatur est, eius architecto vero quidem deleniti quasi quaerat non ab culpa placeat maiores eveniet? Voluptate enim fugiat libero totam?",
  },
  {
    id: nanoid(),
    username: "Елена",
    date: "18.07.2023",
    city: "г.Ростов-на-Дону",
    description: "Отзыв был оставлен на сайте Otzovik.ru",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem et facere beatae alias tempore, odio hic nemo nam ratione eum. Quibusdam optio esse voluptates exercitationem, molestias quia perspiciatis consequuntur eveniet.",
  },
];

const AboutUsBlock = () => {
  return (
    <section className={styles.root}>
      <SectionTitle title="О нас в сети" />
      <MainSlider
        slidesSpacing={150}
        sliderContainerStyles={styles.slider}
        contentStyles={styles.content}
        paginationStyles={styles.pagination}
        shouldAutoSwitched
        shouldBeLooped
      >
        {MOCK_ARRAY.map((item) => {
          return (
            <AboutUsItem
              key={item.id}
              username={item.username}
              date={item.date}
              city={item.city}
              description={item.description}
              comment={item.comment}
              containerStyles="keen-slider__slide"
            />
          );
        })}
      </MainSlider>
    </section>
  );
};

export default AboutUsBlock;
