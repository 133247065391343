import { SetStateAction } from "react";
import CustomButton from "../../../../../../components/CustomButton/CustomButton";

import styles from "./styles.module.scss";
import { IButtonsValue } from "pages/PersonalArea/components/UserOrders/UserOrders";

interface IOrdersButtonsProps {
  selectedOrderId: number;
  setIsOrdersLoading: React.Dispatch<SetStateAction<boolean>>;
  buttonsValues: IButtonsValue[];
  onSelectOrder: (id: number) => void;
}

const OrdersButtons: React.FC<IOrdersButtonsProps> = (props) => {
  return (
    <div className={styles.root}>
      {props.buttonsValues.map((item) => {
        return (
          <CustomButton
            key={item.id}
            title={item.title}
            isBlack={props.selectedOrderId === item.id}
            isWhite={props.selectedOrderId !== item.id}
            className={styles.button}
            onClick={() => props.onSelectOrder(item.id)}
          />
        );
      })}
    </div>
  );
};

export default OrdersButtons;
