import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import SelectAddressUser from "../SelectAddressUser/SelectAddressUser";
import { deliveryApi } from "../../../../../../../api/deliveryApi";
import { PROVIDERS } from "../../../../../../../utils/constants";
import {
  getAvailableProviders,
  getAvailableProvidersWithResultTitle
} from "../../../../../../../utils/providerFormatter";
import { toast } from "react-toastify";
import ProviderInfo from "../ProviderInfo/ProviderInfo";
import ProvidersListWithAddresses from "../../../ProvidersListWithAddresses/ProvidersListWithAddresses";
import { useAppSelector } from "store/reduxHooks";

import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "utils/getErrorData";
import {
  ProvidersInfo,
  SelectedProvider,
  SelectedProviderData
} from "types/IProviderInfo";
import { IChosenProvider } from "utils/hooks/useYandexMap";

interface IMobileModalAdressProps {
  selectedProviderData: SelectedProviderData | null;
  selectedProvider: SelectedProvider;
  changeSelectedProviderData: (value: SelectedProviderData | null) => void;
  setCity: (city: string) => Promise<void>;
  setSelectedProvider: (
    data: SelectedProvider | ((prevState: SelectedProvider) => SelectedProvider)
  ) => void;
  onClose: () => void;
  showProviderInfo: boolean;
}

const MobileModalAdress: React.FC<IMobileModalAdressProps> = (props) => {
  const { checkoutData, currentCity } = useAppSelector((state) => state.user);
  const [temporaryProviders, setTemporaryProviders] = useState<
    IChosenProvider[]
  >([]);
  const [showModal, setShowModal] = useState("PVZList");
  const [providersInfo, setProvidersInfo] = useState<ProvidersInfo | null>(
    null
  );
  const [currentProviders, setCurrentProviders] = useState<IChosenProvider[]>(
    []
  );
  const [chosenProvider, setChosenProvider] = useState<IChosenProvider | null>(
    null
  );

  const onCloseModal = () => {
    props.onClose();
  };

  const onToggleShowProviderInfo = () => {
    setShowModal("ProviderInfo");
  };

  const returnToPvzSelect = () => {
    setShowModal("PVZList");
  };

  const onSelectProvider = (provider: IChosenProvider | null) => {
    setChosenProvider(provider);
  };

  useEffect(() => {
    if (checkoutData.wayToGet === "Курьером") setShowModal("ToDoor");
    if (checkoutData.wayToGet === "Самовывоз") setShowModal("PVZList");
  }, [checkoutData.wayToGet]);

  useEffect(() => {
    (async () => {
      setTemporaryProviders([]);
      const assessedCost = checkoutData.items.reduce((acc, cur) => {
        if (cur.isSelected) {
          acc += cur.calculated_amount;
        }
        return acc;
      }, 0);
      try {
        const data = await deliveryApi.getDeliveryCost({
          city: checkoutData.orderCity || currentCity,
          providers: Object.keys(PROVIDERS),
          assessedCost
        });

        if (isError(data)) {
          throw new CustomError(data.message, data?.code);
        }

        const formattedAvailableProviders =
          getAvailableProvidersWithResultTitle(data.response);

        setProvidersInfo(formattedAvailableProviders);

        const availableProviders = getAvailableProviders(data.response);
        availableProviders.forEach(async (provider) => {
          try {
            const data = await deliveryApi.getProvidersList({
              city: checkoutData.orderCity || currentCity,
              availableOperations: [2, 3],
              providers: [provider]
            });

            if (isError(data)) {
              throw new CustomError(data.message, data?.code);
            }

            const providerResponse = Object.entries(data.response);
            const providerInfo = {
              type: provider,
              addresses: providerResponse[0][1],
              selected: true
            };

            setTemporaryProviders((prev) => {
              return [
                ...prev,
                {
                  type: provider,
                  addresses: providerResponse[0][1],
                  selected: true
                }
              ];
            });

            setProvidersInfo((prev) => {
              return {
                ...prev,
                toPoint: prev.toPoint.map((item) => {
                  if (item.deliveryProvider !== providerInfo.type) {
                    return item;
                  }
                  return {
                    ...item,
                    addresses: providerInfo.addresses
                  };
                })
              };
            });
          } catch (error) {
            const errorData = getErrorData(error);
            // eslint-disable-next-line no-console
            console.error(
              "При получении списка провайдеров произошла ошибка:",
              errorData.message
            );
          }
        });
      } catch (error) {
        const errorData = getErrorData(error);
        toast.error(errorData.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutData.orderCity, currentCity]);

  useEffect(() => {
    const availableProviders = getAvailableProviders(providersInfo);
    if (temporaryProviders.length !== availableProviders.length) {
      return;
    }

    setCurrentProviders(temporaryProviders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryProviders]);

  useEffect(() => {
    if (props.showProviderInfo) setShowModal("ProviderInfo");
  }, [props.showProviderInfo]);

  return (
    <div className={styles.root}>
      {showModal === "PVZList" && (
        <ProvidersListWithAddresses
          selectedProvider={props.selectedProvider}
          setSelectedProvider={props.setSelectedProvider}
          providersInfo={providersInfo}
          changeSelectedProviderData={props.changeSelectedProviderData}
          onToggleShowProviderInfo={onToggleShowProviderInfo}
          setCity={props.setCity}
        />
      )}
      {showModal === "ToDoor" && (
        <SelectAddressUser
          {...props}
          onCloseModal={onCloseModal}
          providersInfo={providersInfo}
          currentProviders={currentProviders}
          onSelectProvider={onSelectProvider}
          chosenProvider={chosenProvider}
          setCity={props.setCity}
        />
      )}
      {showModal === "ProviderInfo" && (
        <ProviderInfo
          {...props}
          returnToPvzSelect={returnToPvzSelect}
          providersInfo={providersInfo}
          onCloseModal={props.onClose}
        />
      )}
    </div>
  );
};

export default MobileModalAdress;
