import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const CustomDropDown = (props) => {
  return (
    <div className={cn(styles.root, props.containerStyles)}>
      {props.children.buttonElement}
      <CSSTransition
        in={props.isOpen}
        timeout={100}
        classNames={{
          enter: styles["dropdown-enter"],
          enterDone: styles["dropdown-enter-done"],
          exit: styles["dropdown-exit"],
          exitDone: styles["dropdown-exit-done"],
        }}
        mountOnEnter
      >
        <div className={cn(styles.dropdown, props.dropdownElementStyles)}>
          <div className={styles["dropdown-wrapper"]}>
            {props.children.dropdownElement}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.shape({
    buttonElement: PropTypes.node.isRequired,
    dropdownElement: PropTypes.node.isRequired,
  }).isRequired,
  containerStyles: PropTypes.string,
  dropdownElementStyles: PropTypes.string,
};

CustomDropDown.propTypes = propTypes;

export default CustomDropDown;
