import noImgProductLogo from "../../../../../../assets/images/no-photo.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";
import { useMemo } from "react";
import { OrderOffer } from "types/IGetUserOrderData";
import { Link } from "react-router-dom";

interface IOrderItemProps {
  item: OrderOffer;
  amount: number;
  containerStyles?: string;
}

const OrderItem: React.FC<IOrderItemProps> = (props) => {
  const noImageLink = useMemo(() => {
    return props.item.offer?.catalog?.category?.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.item.offer?.catalog?.category?.rewrite_name]);

  const imageLink = props.item.offer?.catalog?.image?.link || noImageLink;
  const productUrl = `/product/${props.item.offer.catalog.id}-${props.item.offer.catalog.product_rewrite_name}?type=${props.item.offer.id}`;

  return (
    <Link to={productUrl} className={cn(styles.root, props.containerStyles)}>
      <img
        src={imageLink ?? noImgProductLogo}
        alt={props.item.offer?.name}
        className={styles.logo}
      />
      <div className={styles.content}>
        <span className={styles.content__title}>
          {props.item.offer?.catalog?.name}
        </span>
        <span className={styles.content__properties}>
          {props.item.offer?.name}
        </span>
        {props.item.offer?.catalog.vendorCode && (
          <span className={styles["content__vendor-code"]}>
            {`Артикул: ${props.item.offer?.catalog.vendorCode}`}
          </span>
        )}
        <span className={styles["content__price-wrapper"]}>
          <span className={styles["content__main-price"]}>
            {props.item.sale_price !== props.item.site_price
              ? `${formatPrice(props.item.site_price)} ₽`
              : `${formatPrice(props.item.offer.price)} ₽`}
          </span>
          <span className={styles["content__sale-price"]}>
            {`${formatPrice(props.item.sale_price)} ₽`}
          </span>
          <span className={styles["content__item-amount"]}>
            {`x ${props.amount}`}
          </span>
        </span>
      </div>
    </Link>
  );
};

export default OrderItem;
