import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import cn from "classnames";
import { SELECT_TYPE_TABS } from "../../../../pages/Main/components/DiscountsBlock/DiscountsBlock";

const SliderTab = (props) => {
  return(
    <div className={styles.tabs}>
      {SELECT_TYPE_TABS.map((item) => {
        return (
          <button
            key={item.id}
            className={cn(styles.tabs_button, {
              [styles["tabs_button--active"]]: props.activeTabId === item.id,
            })}
            onClick={() => {props.onClickSelectTab(item.id);
              props.handleStopped;
            }}
            onMouseUp={props.handleResumed}
            onMouseLeave={props.handleResumed}
          >
            {item.title}
          </button>
        );
      })}
    </div>
  );
};

const propTypes = {
  onClickSelectTab: PropTypes.func,
  activeTabId: PropTypes.string,
  handleResumed: PropTypes.func,
  handleStopped: PropTypes.func
};
SliderTab.propTypes = propTypes;

export default SliderTab;