import useSlider from "../../../../../../utils/hooks/useSlider";

import OrderItem from "../OrderItem/OrderItem";
import CustomArrowButton from "../../../../../../components/CustomArrowButton/CustomArrowButton";

import cn from "classnames";
import styles from "./styles.module.scss";
import { OrderOffer } from "types/IGetUserOrderData";
import DynamicalBullets from "components/UI/DynamicalBullets/DynamicalBullets";

interface IOrderItemsSliderProps {
  offers: OrderOffer[];
}

const OrderItemsSlider: React.FC<IOrderItemsSliderProps> = (props) => {
  const {
    currentSlide,
    isLoaded,
    availableDots,
    sliderRef,
    instanceRef,
    onClickPrev,
    onClickNext
  } = useSlider({
    shouldBeLooped: true,
    shouldAutoSwitched: false
  });
  return (
    <>
      <div ref={sliderRef} className={cn("keen-slider")}>
        {props.offers.map((item, idx) => {
          return (
            <OrderItem
              key={`${item.id}-${idx}`}
              item={item}
              amount={item.count}
              containerStyles="keen-slider__slide"
            />
          );
        })}
      </div>
      {/* Точки пагинации слайдера версии 1 */}
      {/* {availableDots?.length > 1 && (
        <div className={styles.pagination}>
          {isLoaded && instanceRef.current && (
            <CustomArrowButton
              duration="left"
              className={cn(styles.arrow, styles["arrow--left"])}
              onClick={onClickPrev}
            />
          )}
          <div className={styles.dots}>
            {availableDots.map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={cn(styles.dot, {
                    [styles["dot--active"]]: currentSlide === idx,
                    [styles["dot--small"]]: props.offers?.length > 6
                  })}
                ></button>
              );
            })}
          </div>
          {isLoaded && instanceRef.current && (
            <CustomArrowButton
              duration="right"
              className={cn(styles.arrow, styles["arrow--right"])}
              onClick={onClickNext}
            />
          )}
        </div>
      )} */}
      {/* Точки пагинации слайдера версии 2 */}
      {availableDots?.length > 1 && isLoaded && instanceRef.current && (
        <div className={styles.pagination}>
          <CustomArrowButton
            duration="left"
            className={cn(styles.arrow, styles["arrow--left"])}
            onClick={onClickPrev}
          />

          <DynamicalBullets
            availableDots={availableDots}
            currentSlide={currentSlide}
            instanceRefSlider={instanceRef}
            dotsStyles={styles["slider-dots"]}
          />

          <CustomArrowButton
            duration="right"
            className={cn(styles.arrow, styles["arrow--right"])}
            onClick={onClickNext}
          />
        </div>
      )}
    </>
  );
};

export default OrderItemsSlider;
