import { IFiltersParams } from "api/filtersV3Api";
import { getFiltersV3 } from "../../../store/catalogV3/catalogV3Thunks/index";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { FilterKeysEnum } from "types/FilterKeysEnum";
import {
  getInitialSelectedFilters,
  IGetInitialSelectedFiltersData
} from "utils/helpers/getInitialSelectedFilters";
import { REJECT_ERROR_CODE } from "utils/getErrorData";
import { setMobileSearchParams, setSelectedFilters } from "store/catalogV3";

interface IUseFiltersV3Return {
  handleSetShouldGetFiltersStatus: (value: boolean) => void;
}

export const useFiltersV3 = (): IUseFiltersV3Return => {
  const deviceType = useAppSelector((state) => state.user.deviceType);
  const { mobileSearchParams, searchTriggered } = useAppSelector(
    (state) => state.catalogV3
  );

  const isMobile = deviceType.isMobile || deviceType.isTablet;

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const initialStateRef = useRef<boolean>(true);

  const cancelTokenRef = useRef<AbortController>();
  const isFirstRender = useRef(true);
  const shouldGetFilters = useRef(true);
  const prevSearchParamState = useRef<URLSearchParams>(searchParams);

  // Есть два раздела в каталоге парфюмерия и косметика. Если мы на одном из этих разделов - отправлять в filters дополнительно categories не нужно
  // Мы просто отправляем categories c нужным значением раздела
  const currentCatalogSection = useMemo(() => {
    const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);
    if (!currentMatch) {
      return "";
    }
    return currentMatch[0];
  }, [location.pathname]);

  const isCatalogSection = !!currentCatalogSection.length;

  const currentSearchParams = useMemo(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    const filterKeysArray = Object.values(FilterKeysEnum);

    if (isCatalogSection) {
      // У бэка есть условие, что мы должны убирать categories из filters если мы находимся на одном из разделов.
      // Эта логика написана ниже
      const filteredKeys = filterKeysArray.filter(
        (key) => key !== FilterKeysEnum.CATEGORIES
      );
      const filterKeysString = filteredKeys.join(",");
      const allFilters: Record<"filters" | "categories", string> = {
        filters: filterKeysString,
        categories: currentCatalogSection
      };

      newSearchParams.set("filters", allFilters.filters);
      newSearchParams.set("categories", allFilters.categories);

      return newSearchParams;
    }

    // В противном случае просто добавляем фильтры из всех существующих и описанных в FilterKeysEnum
    // Эта логика написана ниже
    const filterKeysString = filterKeysArray.join(",");
    const allFilters: Record<"filters", string> = {
      filters: filterKeysString
    };

    newSearchParams.set("filters", allFilters.filters);

    return newSearchParams;
  }, [currentCatalogSection, isCatalogSection, searchParams]);

  useEffect(() => {
    cancelTokenRef.current = new AbortController();
    const isSearchParamChanged =
      searchParams.toString() !== prevSearchParamState.current.toString();

    if (isFirstRender.current || isSearchParamChanged) {
      dispatch(setMobileSearchParams(Object.fromEntries(currentSearchParams)));
    }
    // избежания лишнего запроса при изменении пагинации или параметров сортировки
    if (!shouldGetFilters.current) {
      handleSetShouldGetFiltersStatus(true);
      return;
    }

    const searchParamsBasedOnDevice = isMobile
      ? isFirstRender.current || isSearchParamChanged
        ? currentSearchParams
        : new URLSearchParams(mobileSearchParams)
      : currentSearchParams;

    const options: IFiltersParams = {
      searchParams: searchParamsBasedOnDevice,
      signal: cancelTokenRef.current.signal
    };

    const filtersResponse = dispatch(getFiltersV3(options)).unwrap();

    filtersResponse
      .then((response) => {
        if (initialStateRef.current || isSearchParamChanged) {
          if ("message" in response) {
            return;
          }
          const initialSearchParams = new URLSearchParams(
            searchParams.toString()
          );

          const initialFiltersData: IGetInitialSelectedFiltersData = {
            filters: response.filters.response,
            currentSearchParams: initialSearchParams
          };
          const initialSelectedFilters =
            getInitialSelectedFilters(initialFiltersData);

          dispatch(setSelectedFilters(initialSelectedFilters));
          initialStateRef.current = false;
          prevSearchParamState.current = searchParams;
        }
      })
      .catch((error) => {
        const errorCode = error?.code;
        if (errorCode === REJECT_ERROR_CODE) {
          // eslint-disable-next-line no-console
          console.error("Получение фильтров было отменено пользователем");
        }
      });

    isFirstRender.current = false;

    return () => {
      cancelTokenRef.current?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchParams, dispatch, isMobile, searchParams, searchTriggered]);

  const handleSetShouldGetFiltersStatus = useCallback((value: boolean) => {
    shouldGetFilters.current = value;
  }, []);

  return {
    handleSetShouldGetFiltersStatus
  };
};
