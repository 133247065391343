import { IEcommerceYandex } from "types/IEcommerceYandex";

declare global {
  interface Window {
    dataLayer: IEcommerceYandex[];
  }
}

export const handleYandexEcommerce = (ecommerceData: IEcommerceYandex) => {
  return new Promise<void>((resolve) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(ecommerceData);

    // Чтоб избежать проблем с переходом со страницы на страницу и дождаться ответа от яндекса ставим задержку
    setTimeout(resolve, 500);
  });
};