import { useMemo} from "react";

import cn from "classnames";
import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";

interface ICartItemPriceValueProps {
  mainPrice: number;
  productAmount: number;
  calculatedPrice?: number;
  discountPrice?: number;
  salePrice?: number;
}

const CartItemPriceValue: React.FC<ICartItemPriceValueProps> = (props) => {
  const minPrice = useMemo(() => {
    if (!props.calculatedPrice) {
      const priceWithDiscount = props.salePrice || props.discountPrice;
      return priceWithDiscount || props.mainPrice;
    }
    return props.calculatedPrice;
  }, [
    props.calculatedPrice,
    props.discountPrice,
    props.salePrice,
    props.mainPrice
  ]);

  const displayPrice = minPrice || props.mainPrice;
  const totalMainPrice = props.mainPrice * props.productAmount;
  const totalDisplayPrice = displayPrice * props.productAmount;

  return (
    <div
      className={cn(styles["price-wrapper"], {
        [styles["price-wrapper--single"]]: props.mainPrice === minPrice
      })}
    >
      {props.mainPrice === minPrice ? (
        <p
          className={styles["sale-price"]}
        >{`${formatPrice(totalDisplayPrice)} ₽`}</p>
      ) : (
        <>
          <p className={styles["main-price"]}>
            {`${formatPrice(totalMainPrice)} ₽`}
          </p>
          <p className={styles["sale-price"]}>
            {`${formatPrice(totalDisplayPrice)} ₽`}
          </p>
        </>
      )}
    </div>
  );
};

export default CartItemPriceValue;
