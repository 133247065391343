import Portal from "../../../../HOC/Portal";
import CustomLink from "../../../../components/CustomLink/CustomLink";

import styles from "./styles.module.scss";

interface IPromocodeModalProps {
  isOpen: boolean;
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

const PromocodeModal: React.FC<IPromocodeModalProps> = (props) => {
  return (
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <div className={styles.root}>
        <div className={styles.modal} ref={props.modalRef}>
          <h3 className={styles.title}>
            Промокоды могут использовать только авторизованные пользователи.
          </h3>
          <div className={styles["buttons-wrapper"]}>
            <CustomLink
              title="Авторизоваться"
              path="/sign-in"
              className={styles.button}
            />
            <CustomLink
              title="Зарегистрироваться"
              path="/sign-up"
              className={styles.button}
            />
          </div>
        </div>
        <div className={styles.background} />
      </div>
    </Portal>
  );
};

export default PromocodeModal;
