import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFiltersV3 } from "store/catalogV3/catalogV3Thunks";
import { FilterKeysEnum } from "types/FilterKeysEnum";
import {
  IFilterCommon,
  IFiltersV3Core,
  IFilterUncommon
} from "types/IFiltersV3";
import { REJECT_ERROR_CODE } from "utils/getErrorData";
import { getResultStringToSaveInQuery } from "utils/helpers/searchParamsHelper";
import { sortFiltersBySelected } from "utils/helpers/sortFiltersBySelected";
import { objectEntries } from "utils/helpers/typeGuardObjEntries";

export interface ICatalogV3State {
  filtersV3: IFiltersV3Core;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string | null;
  isFiltersEmpty: boolean;
  selectedFilters: ISelectedFilter[];
  searchTriggered: boolean;
  mobileSearchParams: Record<string, string>;
  chosenFilterWithKey: null | IFilterWithKeyObj;
}

export interface IFilterWithKeyObj {
  mainFilterName: string;
  mainFilterKey: string;
  subFilters: IFilterCommon[] | IFilterUncommon[];
  isFilterProductProperties?: boolean;
}

export interface IFilterItemToSelect {
  mainFilterRewriteName: string;
  filter: IFilterCommon | IFilterUncommon;
  searchParamsObject: Record<string, string>;
}

export interface IFilterItemToSelectMobile {
  mainFilterRewriteName: string;
  filter: IFilterCommon | IFilterUncommon;
}

export interface ISelectedFilterItem {
  mainFilterRewriteName: string;
  mainFilterLocalizedName: string;
  filter: IFilterCommon | IFilterUncommon;
  isFilterProductProperties?: boolean;
}

export interface ISelectedFilter {
  mainFilterRewriteName: string;
  mainFilterLocalizedName: string;
  filters: IFilterCommon[] | IFilterUncommon[];
  isFilterProductProperties?: boolean;
}

const initialState: ICatalogV3State = {
  filtersV3: {},
  isError: false,
  isLoading: false,
  errorMessage: null,
  isFiltersEmpty: true,
  selectedFilters: [],
  searchTriggered: false,
  mobileSearchParams: {},
  chosenFilterWithKey: null
};

const catalogV3 = createSlice({
  name: "catalogV3",
  initialState: initialState,
  reducers: {
    setFiltersData(state, action: PayloadAction<IFiltersV3Core>) {
      state.filtersV3 = action.payload;
    },
    setSingleFilterSelect(state, action: PayloadAction<IFilterItemToSelect>) {
      const mainKey = action.payload.mainFilterRewriteName;

      const newFilterName =
        "rewrite_name" in action.payload.filter
          ? action.payload.filter.rewrite_name
          : action.payload.filter.rewrite_value;

      const resultString: string = getResultStringToSaveInQuery({
        newItem: newFilterName,
        currentQuery: action.payload.searchParamsObject[mainKey]
      });

      const newSearchParams = {
        [mainKey]: resultString,
        limit: "",
        offset: ""
      };

      objectEntries(newSearchParams).forEach(([key, value]) => {
        if (!value?.length) {
          delete action.payload.searchParamsObject[key];
        } else {
          action.payload.searchParamsObject[key] = value;
        }
      });
    },
    setSelectedFilters(state, action: PayloadAction<ISelectedFilter[]>) {
      state.selectedFilters = action.payload;
    },
    setSelectedFilterItem(state, action: PayloadAction<ISelectedFilterItem>) {
      const {
        mainFilterRewriteName,
        mainFilterLocalizedName,
        filter,
        isFilterProductProperties
      } = action.payload;

      const mainFilterIndex = state.selectedFilters.findIndex(
        (selectedFilter) =>
          selectedFilter.mainFilterRewriteName === mainFilterRewriteName
      );

      // если мы нашли соответствие с основным фильтром из выбранных
      if (mainFilterIndex !== -1) {
        const mainFilter = state.selectedFilters[mainFilterIndex];

        // делаем защиту типов
        const isFilterCommonType =
          mainFilter.filters.length > 0 &&
          "rewrite_name" in mainFilter.filters[0];
        // ищем допольнительный под фильтер среди основного фильтра
        const subfilterIndex = mainFilter.filters.findIndex((f) =>
          isFilterCommonType
            ? (f as IFilterCommon).rewrite_name ===
              (filter as IFilterCommon).rewrite_name
            : (f as IFilterUncommon).rewrite_value ===
              (filter as IFilterUncommon).rewrite_value
        );

        // если нашли - убирает вырезав
        if (subfilterIndex !== -1) {
          mainFilter.filters.splice(subfilterIndex, 1);

          // если основной фильтр больше не имеет подфильтров - вырезаем его
          if (mainFilter.filters.length === 0) {
            state.selectedFilters.splice(mainFilterIndex, 1);
          }
        } else {
          // в противном случае добавляем сабфильтр соблюдая типы
          if (isFilterCommonType && "rewrite_name" in filter) {
            (mainFilter.filters as IFilterCommon[]).push(filter);
          } else if (!isFilterCommonType && "rewrite_value" in filter) {
            (mainFilter.filters as IFilterUncommon[]).push(filter);
          }
        }
      } else {
        // если основного фильтра совсем не было в выбраных - то добавляем его
        const newFilter: ISelectedFilter = {
          mainFilterRewriteName: mainFilterRewriteName,
          mainFilterLocalizedName,
          filters:
            "rewrite_name" in filter
              ? [filter as IFilterCommon]
              : [filter as IFilterUncommon],
          isFilterProductProperties
        };

        state.selectedFilters.push(newFilter);
      }
    },
    setPriceFilter(
      state,
      action: PayloadAction<"REMOVE_PRICE_FILTER" | ISelectedFilter>
    ) {
      const arrayForClearParamsKeys = [
        "min_price",
        "max_price",
        "limit",
        "offset"
      ];
      if (action.payload === "REMOVE_PRICE_FILTER") {
        state.selectedFilters = state.selectedFilters.filter(
          (filter) => filter.mainFilterRewriteName !== FilterKeysEnum.PRICES
        );

        // очищаем mobileSearchParams от ключей цены и дополнительно лимита и оффсета
        arrayForClearParamsKeys.forEach((key) => {
          if (state.mobileSearchParams[key]) {
            delete state.mobileSearchParams[key];
          }
        });
        return;
      }

      const mainPriceFilterIndex = state.selectedFilters.findIndex(
        (selectedFilter) =>
          selectedFilter.mainFilterRewriteName === FilterKeysEnum.PRICES
      );

      if (mainPriceFilterIndex !== -1) {
        state.selectedFilters.splice(mainPriceFilterIndex, 1);
        state.selectedFilters.push(action.payload);
      } else {
        state.selectedFilters.push(action.payload);
      }

      // заменяем значения цены в mobileSearchParams
      const minPriceFilter = action.payload.filters.find(
        (priceFilter) =>
          (priceFilter as IFilterCommon).rewrite_name === "min_price"
      ) as IFilterCommon;
      const maxPriceFilter = action.payload.filters.find(
        (priceFilter) =>
          (priceFilter as IFilterCommon).rewrite_name === "max_price"
      ) as IFilterCommon;

      // так как хардкодим типы на такой случай проверку на undefined делаем
      state.mobileSearchParams["min_price"] = minPriceFilter?.name ?? "0";
      state.mobileSearchParams["max_price"] = maxPriceFilter?.name ?? "0";
    },
    toggleSearchTrigger(state) {
      state.searchTriggered = !state.searchTriggered;
    },
    setMobileSearchParams(
      state,
      action: PayloadAction<Record<string, string>>
    ) {
      state.mobileSearchParams = action.payload;
    },
    setSingleMobileSearchParam(
      state,
      action: PayloadAction<IFilterItemToSelectMobile>
    ) {
      const mainKey = action.payload.mainFilterRewriteName;

      const newFilterName =
        "rewrite_name" in action.payload.filter
          ? action.payload.filter.rewrite_name
          : action.payload.filter.rewrite_value;

      const resultString: string = getResultStringToSaveInQuery({
        newItem: newFilterName,
        currentQuery: state.mobileSearchParams[mainKey]
      });

      const newSearchParams = {
        [mainKey]: resultString,
        limit: "",
        offset: ""
      };

      objectEntries(newSearchParams).forEach(([key, value]) => {
        if (!value?.length) {
          delete state.mobileSearchParams[key];
        } else {
          state.mobileSearchParams[key] = value;
        }
      });
    },
    setChosenFilterWithKey(state, action: PayloadAction<IFilterWithKeyObj>) {
      state.chosenFilterWithKey = action.payload;
    },
    // action в данном случае обозначает делаем ли мы зануление фильтров на странице с "Парфюмерией" или "Косметикой", если true то на разделе каком-то мы
    resetMobileSearchFilters(
      state,
      action: PayloadAction<{ isSection: boolean }>
    ) {

      const filterKeysToRemoveArray: string[] = [];
      state.selectedFilters.forEach((filter) => {
        if (
          filter.mainFilterRewriteName === FilterKeysEnum.CATEGORIES &&
          action.payload.isSection
        ) {
          return;
        }
        if (filter.mainFilterRewriteName === FilterKeysEnum.PRICES) {
          return filter.filters.forEach((f) =>
            filterKeysToRemoveArray.push((f as IFilterCommon).rewrite_name)
          );
        }

        return filterKeysToRemoveArray.push(filter.mainFilterRewriteName);
      });

      filterKeysToRemoveArray.forEach(keyToRemove => {
        if (state.mobileSearchParams[keyToRemove]) {
          delete state.mobileSearchParams[keyToRemove];
        }
      });
      state.selectedFilters = initialState.selectedFilters;
      state.searchTriggered = !state.searchTriggered;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFiltersV3.fulfilled, (state, action) => {
      let filters = action.payload.filters.response;
      const searchParams = action.payload.searchParams;

      try {
        filters = sortFiltersBySelected({
          filters,
          searchParams
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Ошибка при сортировки фильтров", error);
      }
      state.filtersV3 = filters;
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = null;

      // Считаем filtersV3 пустыми если там нет никаких ключей со значениями или там есть фильтр цены.
      state.isFiltersEmpty =
        Object.keys(filters).length === 0 ||
        (Object.keys(filters).length === 1 && FilterKeysEnum.PRICES in filters);
    });

    builder.addCase(getFiltersV3.pending, (state) => {
      state.isError = false;
      state.isLoading = true;
      state.errorMessage = null;
    });

    builder.addCase(getFiltersV3.rejected, (state, action) => {
      state.isError = true;
      if (action.payload.code === REJECT_ERROR_CODE) {
        state.errorMessage = null;
        return;
      }
      state.isLoading = false;
      state.errorMessage = action.payload.message;
    });
  }
});

export const {
  setFiltersData,
  setSingleFilterSelect,
  setSelectedFilters,
  setSelectedFilterItem,
  setPriceFilter,
  toggleSearchTrigger,
  setMobileSearchParams,
  setSingleMobileSearchParam,
  setChosenFilterWithKey,
  resetMobileSearchFilters
} = catalogV3.actions;

export default catalogV3.reducer;
