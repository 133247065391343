import styles from "./styles.module.scss";

const BankCardPayment = () => {
  return (
    <>
      <h3 className={styles.title}>Оплата заказа банковской картой Visa, MasterCard, МИР</h3>
      <ol className={styles.list}>
        <li className={styles.list__item}>
          <p className={styles.list__item__title}>
            <span>
              {"Оплата в пункте выдачи заказов "}
            </span>
            <span>
              (комиссия 0%).
            </span>
          </p>
          <p className={styles.list__item__text}>
            Оплата производится посредством POS-терминала, при получении товара, в пункте самовывоза.
          </p>
          <span className={styles.list__item__text_postscript}>
              * Недоступно при получении заказов в пунктах выдачи Boxberry и 5 Post.
          </span>
        </li>
        <li className={styles.list__item}>
          <p className={styles.list__item__title}>
            <span>
              {"Оплата онлайн "}
            </span>
            <span>
              (комиссия 0%).
            </span>
          </p>
          <p className={styles.list__item__text}>
            Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный
            шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача информации
            осуществляется в защищенном режиме с использованием протокола шифрования SSL.
          </p>
          <span className={styles.list__item__text_postscript}>
            * В соответствии с ФЗ «О защите прав потребителей» в случае, если вам была не оказана
            услуга или реализован товар ненадлежащего качества, платеж будет возвращен на банковскую
            карту, с которой производилась оплата. Порядок возврата средств уточняйте
            у администрации интернет-магазина.
          </span>
        </li>
      </ol>
    </>
  );
};

export default BankCardPayment;