import { forwardRef } from "react";
import SelectedProductItem from "../SelectedProductItem/SelectedProductItem";
import styles from "./styles.module.scss";
import { IFavouriteProduct } from "types/IFavouriteProduct";

interface ISelectedProductsProps {
  title: string;
  products: IFavouriteProduct[];
}

const SelectedProducts = forwardRef<HTMLDivElement, ISelectedProductsProps>(
  function SelectedProducts(props, ref) {
    return (
      <div ref={ref} className={styles.root}>
        <h3 className={styles.title}>{props.title}</h3>
        {props.products.map((item, idx) => (
          <SelectedProductItem
            key={`${item.id}-${idx}`}
            item={item}
            isFavorite={props.title === "Избранное"}
          />
        ))}
      </div>
    );
  }
);

export default SelectedProducts;
