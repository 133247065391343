const getinputValueNumbersValue = (value) => {
  const PATTERN = /\D/g;
  return value.replace(PATTERN, "");
};

const phoneNumberMask = (event) => {
  const inputValue = event.target;
  let numbersValue = getinputValueNumbersValue(inputValue.value);
  let formatedValue = "";

  if (!numbersValue) {
    inputValue.value = "";
    return;
  }

  if (numbersValue[0] === "9") {
    numbersValue = "7" + numbersValue;
  }

  const firstSymbols = "+7";
  formatedValue = firstSymbols;

  if (numbersValue.length > 1) {
    formatedValue += "(" + numbersValue.substring(1, 4);
  }
  if (numbersValue.length >= 5) {
    formatedValue += ")" + numbersValue.substring(4, 7);
  }
  if (numbersValue.length >= 8) {
    formatedValue += "-" + numbersValue.substring(7, 9);
  }
  if (numbersValue.length >= 10) {
    formatedValue += "-" + numbersValue.substring(9, 11);
  }

  inputValue.value = formatedValue;
  return inputValue.value;
};

export default phoneNumberMask;