export {
  default as HeadHelmet,
} from "./HeadPageData";

/**
 * Return right site title
 * @param {?string} title
 * @return {string}
 */
export const getSiteTitle = (title) => {
  return `${title ? `${title}` : "Интернет магазин парфюмерии и косметики | Parfumart"}`;
};
