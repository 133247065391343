export const maskDate = (e: React.FormEvent<HTMLInputElement>) => {
  const target = e.target as HTMLInputElement;
  if (!target || !target.value) return;

  let value = target.value.replace(/\D/g, "");
  let cursorPosition = target.selectionStart ?? 0;

  if (value.length >= 2) {
    value = value.slice(0, 2) + (value.length > 2 ? "-" + value.slice(2, 4) : "") + (value.length > 4 ? "-" + value.slice(4, 8) : "");
  } else if (value.length > 4) {
    value = value.slice(0, 2) + (value.length > 2 ? "-" + value.slice(2, 4) : "") + (value.length > 4 ? "-" + value.slice(4) : "");
  }

  value = value.slice(0, 10);

  target.value = value;

  if (cursorPosition > 2) {
    cursorPosition += 1;
  }
  if (cursorPosition > 5) {
    cursorPosition += 1;
  }

  target.setSelectionRange(cursorPosition, cursorPosition);
};