import PropTypes from "prop-types";

export const ProductImageType = {
  id: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  link: PropTypes.string,
  mime: PropTypes.string,
  size: PropTypes.number,
  newFileName: PropTypes.string,
};

export const CategoryType = {
  id: PropTypes.number,
  name: PropTypes.string,
};

export const BrandItemType = {
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  id: PropTypes.number,
  name: PropTypes.string,
  popular: PropTypes.bool,
};

export const ProductType = {
  akcia: PropTypes.number,
  newProduct: PropTypes.bool,
  popular: PropTypes.bool,
  brands: PropTypes.shape({
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    popular: PropTypes.bool,
  }),
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  description: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.shape({
    id: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    link: PropTypes.string,
    mime: PropTypes.string,
    size: PropTypes.number,
    newFileName: PropTypes.string,
  }),
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  minVolume: PropTypes.number,
  maxVolume: PropTypes.number,
  minWeight: PropTypes.number,
  maxWeight: PropTypes.number,
  minQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  name: PropTypes.string,
  productRewriteName: PropTypes.string,
  reducedImage: PropTypes.shape({
    id: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    link: PropTypes.string,
    mime: PropTypes.string,
    size: PropTypes.number,
    newFileName: PropTypes.string,
  }),
  vendorCode: PropTypes.string,
};

export const OfferType = {
  akcia: PropTypes.number,
  catalog: PropTypes.shape({
    akcia: PropTypes.number,
    newProduct: PropTypes.bool,
    popular: PropTypes.bool,
    brands: PropTypes.shape({
      category: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      id: PropTypes.number,
      name: PropTypes.string,
      popular: PropTypes.bool,
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({
      id: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number,
      link: PropTypes.string,
      mime: PropTypes.string,
      size: PropTypes.number,
      newFileName: PropTypes.string,
    }),
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    minVolume: PropTypes.number,
    maxVolume: PropTypes.number,
    minWeight: PropTypes.number,
    maxWeight: PropTypes.number,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    name: PropTypes.string,
    productRewriteName: PropTypes.string,
    reducedImage: PropTypes.shape({
      id: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number,
      link: PropTypes.string,
      mime: PropTypes.string,
      size: PropTypes.number,
      newFileName: PropTypes.string,
    }),
    vendorCode: PropTypes.string,
  }),
  catalogName: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  percent: PropTypes.number,
  price: PropTypes.number,
};

