export const defaultFilterOrder = [
  "badges",
  "categories",
  "brands",
  "goods_types",
  "product_types",
  "product_properties",
  "colours",
  "application_methods",
  "prices"
];
