import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import CustomButton from "../../components/CustomButton/CustomButton";
import userAvatar from "../../assets/icons/png/user_avatar.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const ExitFromPersonalAreaContent = (props) => {
  const { user } = useSelector((state) => state.user);

  return (
    <div
      ref={props.contentRef}
      className={cn(styles.root, props.containerStyles)}
    >
      <img src={userAvatar} alt="user_avatar" className={styles.avatar} />
      <span className={styles["user-data"]}>
        <span>{!user.surname ? "" : `${user.surname} `}</span>
        <span>{!user.name ? "" : user.name}</span>
        <span>{!user.patronymic ? "" : ` ${user.patronymic}`}</span>
      </span>
      <span className={styles.title}>Выйти из аккаунта?</span>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title="Выйти"
          isWhite
          onClick={() => props.onClose({ isExit: true })}
          className={styles.button}
        />
        <CustomButton
          title="Остаться"
          onClick={() => props.onClose({ isExit: false })}
          className={styles.button}
        />
      </div>
    </div>
  );
};

const propTypes = {
  containerStyles: PropTypes.string,
  contentRef: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

ExitFromPersonalAreaContent.propTypes = propTypes;

export default ExitFromPersonalAreaContent;
