import SelectCityTab from "./components/SelectCityTab";
import ScheduleTab from "./components/ScheduleTab";
import PersonalAreaTab from "./components/PersobalAreaTab/PersonalAreaTab";
import ContactsTab from "./components/ContactsTab";

import styles from "./styles.module.scss";

const MobileBottomNavigation = () => {
  return (
    <nav className={styles.root}>
      <ul className={styles["navigation-list"]}>
        <SelectCityTab />
        <PersonalAreaTab />
        <ScheduleTab />
        <ContactsTab />
      </ul>
    </nav>
  );
};

export default MobileBottomNavigation;