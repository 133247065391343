import cn from "classnames";
import { SkeletonImg } from "components/UI/Skeleton/SkeletonImg/SkeletonImg";
import { useAppSelector } from "../../store/reduxHooks";
import SliderOnCss from "./SliderOnCss/SliderOnCss";

import styles from "./styles.module.scss";

const SliderMainBanner = () => {
  const {
    banners,
    isLoading,
    errorMessage: error
  } = useAppSelector((state) => state.banners);

  if (error) return null;

  return (
    <section className={cn(styles.root, { [styles.root__loading]: isLoading })}>
      {!!banners.length && !isLoading ? (
        <SliderOnCss />
      ) : (
        <SkeletonImg
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "1920px",
            maxHeight: "894px",
            margin: 0,
            padding: 0
          }}
        />
      )}
    </section>
  );
};

export default SliderMainBanner;
