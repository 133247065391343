import PropTypes from "prop-types";
import useShowShadow from "../../../../../../../utils/hooks/useShowShadow";

import CustomScrollbar from "../../../../../../../components/CustomScrollbar";

import cn from "classnames";
import styles from "./styles.module.scss";
import ProductOffersListItem from "../../../../ProductMainContent/components/ProductOffersListItem/ProductOffersListItem";

const MobileOffersList = (props) => {
  const { showShadow, onScrollHandler } = useShowShadow();

  return (
    <CustomScrollbar
      scrollbarClassname={styles.scrollbar}
      childrenClassName={cn(
        styles.list,
        { [styles["list--single"]]: props.offers?.length === 1 },
        { [styles["list--top-shadow"]]: showShadow.top },
        { [styles["list--bottom-shadow"]]: showShadow.bottom }
      )}
      isShowScrollbar={props.offers.length > 6}
      onScrollHandler={onScrollHandler}
    >
      <div itemProp="offers"
        itemScope
        itemType="https://schema.org/AggregateOffer">
        {props.offers.map((offer) => {
          return (
            <ProductOffersListItem
              productId={props.productId}
              key={offer.id}
              offer={offer}
              isActiveOffer={props.activeOfferId === offer.id}
              onSelectOffer={props.onSelectOffer}
            />
          );
        })}
        {!props.offers.length && (
          <p className={styles["offer--not-available"]}>Товара нет в наличии</p>
        )}
        <meta itemProp="offerCount" content={props.offers.length} />
      </div>
    </CustomScrollbar>
  );
};

const propTypes = {
  activeOfferId: PropTypes.number.isRequired,
  productId: PropTypes.number,
  offers: PropTypes.array.isRequired,
  onSelectOffer: PropTypes.func.isRequired,
};

MobileOffersList.propTypes = propTypes;

export default MobileOffersList;
